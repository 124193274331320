import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import Dropzone from "react-dropzone";

import img0 from "./../assets/img/0.png";
import img1 from "./../assets/img/1.png";

import Loading from "./../components/Loading.js";
import Switch from "./../components/Switch";

import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";

import it from "date-fns/locale/it";

registerLocale("it", it);

const defaultUserSelectValue = { value: "", label: "Seleziona un utente" };

export default class AdminReports extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			accessToken: props.accessToken,
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
			usersList: [],
			reportsList: [],
			reportsListMessage: "",

			showFormModal: false,
			formModalTitle: "",
			formModalAction: "",

			showFilesModal: false,
			filesModalTitle: "Gestione file",
			filesModalReport: {},
			filesToUpload: [],

			formModalFieldId: null,
			formModalFieldActive: false,
			formModalFieldUser: defaultUserSelectValue,
			formModalFieldDate: new Date(),
			formModalFieldTitle: "",
			formModalFieldDescription: "",

			showFormError: false,
			formErrorMessage: "",
		};

		this.handleReportActiveChange = this.handleReportActiveChange.bind(this);
		this.handleUserChange = this.handleUserChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleOnDrop = this.handleOnDrop.bind(this);
		this.removeReportFile = this.removeReportFile.bind(this);
	}

	componentWillUnmount() {
		document.body.classList.remove("no-background");
		document.body.classList.remove("page-with-filter-bar");
	}

	componentDidMount() {
		document.body.classList.add("no-background");
		document.body.classList.add("page-with-filter-bar");

		document.title = Globals.titles.adminReports[this.state.language];

		window.scrollTo(0, 0);

		this.getReportsList();
	}

	componentWillReceiveProps(props) {
		this.setState({
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
		});
		document.title = Globals.titles.adminReports[props.language];
	}

	getReportsList() {
		// Reset state
		this.setState({
			reportsList: [],
			reportsListMessage: "",
			loading: true,
		});

		// Fetch api for user's orders
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=getReports&access_token=" +
			accessToken;

		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					this.setState({
						reportsList: [],
						usersList: [],
						reportsListMessage: responseJson.message,
						loading: false,
					});
				} else {
					// Call is ok, store ordersData in state
					this.setState({
						reportsList: responseJson.reports,
						usersList: responseJson.users,
						reportsListMessage: "",
						loading: false,
					});
				}
			})
			.catch((error) => {
				// Communication error - handle message
				this.setState({
					reportsList: [],
					usersList: [],
					reportsListMessage: Globals.errors.serverCall[this.state.language],
					loading: false,
				});
			});
	}

	removeReportFile(fileId) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteReportFile&access_token=" +
				accessToken;

			const formdata = new FormData();

			formdata.append("fileId", fileId);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.toggleFilesHandler();
						this.getReportsList();

						this.setState({
							loading: false,
						});
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	openFilesHandler(listPosition) {
		this.toggleFilesHandler();

		let report = cloneDeep(this.state.reportsList[listPosition]);

		this.setState({
			filesModalReport: report,
		});
	}

	toggleFilesHandler() {
		let showFilesModal = !this.state.showFilesModal;

		this.setState({
			showFilesModal: showFilesModal,
			filesModalReport: {},
			filesToUpload: [],
		});

		window.scrollTo(0, 0);
	}

	newReport() {
		this.toggleFormModal("Nuova relazione", "newReport");
	}

	editReport(listPosition) {
		this.toggleFormModal("Modifica relazione", "editReport");

		let report = cloneDeep(this.state.reportsList[listPosition]);

		let userSelected = { value: report.user.id, label: report.user.company };

		let report_active = false;
		if (parseInt(report.active) === 1) {
			report_active = true;
		}

		let date = report.date;
		if (date !== null && date !== undefined) {
			this.setState({
				formModalFieldDate: new Date(date),
			});
		}

		this.setState({
			formModalFieldId: report.id,
			formModalFieldActive: report_active,
			formModalFieldUser: userSelected,
			formModalFieldTitle: report.title,
			formModalFieldDescription: report.description,
		});
	}

	deleteReport(reportId) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteReport&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("reportId", reportId);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.getReportsList();

						this.setState({
							loading: false,
						});
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	toggleFormModal(title, action) {
		let showFormModal = this.state.showFormModal;

		if (showFormModal === true) {
			showFormModal = false;
		} else {
			showFormModal = true;
		}

		this.setState({
			showFormModal: showFormModal,
			formModalTitle: title,
			formModalAction: action,

			formModalFieldId: null,
			formModalFieldActive: false,
			formModalFieldUser: defaultUserSelectValue,
			formModalFieldDate: new Date(),
			formModalFieldTitle: "",
			formModalFieldDescription: "",

			showFormError: false,
			formErrorMessage: "",
		});
	}

	setInputErrorMessage(field, message) {
		switch (field) {
			case "stdError":
				this.setState({
					showFormError: true,
					formErrorMessage: message,
				});
				break;
			default:
				break;
		}
	}

	formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	handleOnDrop(files) {
		this.setState({
			loading: true,
		});

		// Fetch api
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=uploadReportFile&access_token=" +
			accessToken;

		const formdata = new FormData();

		formdata.append("reportId", this.state.filesModalReport.id);

		files.forEach((file) => {
			formdata.append("uploadedFiles[]", file, file.name);
		});

		fetch(url, {
			method: "POST",
			body: formdata,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					toast(responseJson.message, {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				} else {
					// Call is ok
					toast("Operazione completata", {
						autoClose: 4000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.toggleFilesHandler();
					this.getReportsList();

					this.setState({
						loading: false,
					});
				}
			})
			.catch((error) => {
				// Communication error - handle message
				toast(Globals.errors.serverCall[this.state.language], {
					autoClose: 8000,
					className: "std-black-toast",
					bodyClassName: "std-black-toast-body",
					progressClassName: "std-black-toast-progress-bar",
				});

				this.setState({
					loading: false,
				});
			});
	}

	confirmReportForm() {
		if (window.confirm("Confermi i dati forniti?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;
			let formAction = this.state.formModalAction;

			let reportData = {
				id: this.state.formModalFieldId,
				active: this.state.formModalFieldActive,
				usersId: this.state.formModalFieldUser.value,
				date: this.formatDate(this.state.formModalFieldDate),
				title: this.state.formModalFieldTitle,
				description: this.state.formModalFieldDescription,
			};

			if (reportData.usersId === "") {
				this.setInputErrorMessage("stdError", "Devi selezionare un utente");
				this.setState({
					loading: false,
				});
				return;
			}

			if (reportData.date === "") {
				this.setInputErrorMessage("stdError", "Devi indicare una data");
				this.setState({
					loading: false,
				});
				return;
			}

			if (reportData.title === "") {
				this.setInputErrorMessage("stdError", "Devi indicare il titolo");
				this.setState({
					loading: false,
				});
				return;
			}

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=" +
				formAction +
				"&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("reportData", JSON.stringify(reportData));

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.toggleFormModal();
						this.getReportsList();

						this.setState({
							loading: false,
						});
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	handleReportActiveChange() {
		let isActive = this.state.formModalFieldActive;

		this.setState({
			formModalFieldActive: !isActive,
		});
	}

	handleUserChange(element) {
		this.setState({
			formModalFieldUser: element,
		});
	}

	handleDateChange(date) {
		this.setState({
			formModalFieldDate: date,
		});
	}

	render() {
		if (this.state.isLogged !== true || this.state.isAdmin !== true) {
			return <Redirect to={{ pathname: "/login" }} />;
		}

		let loadingOnScreen;
		if (this.state.loading === true) {
			loadingOnScreen = <Loading />;
		}

		let reportsList = this.state.reportsList;
		let reportsListMessage = this.state.reportsListMessage;
		let reportsRender = "";
		if (reportsListMessage !== "") {
			reportsRender = <div className='message-wrapper'>{reportsListMessage}</div>;
		} else {
			// Prepare data for the table;
			const columns = [
				{
					Header: "Data",
					accessor: "date",
					Cell: (c) => <span>{moment(c.original.date).format("DD/MM/YYYY")}</span>,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Utente",
					accessor: "user",
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Titolo",
					accessor: "title",
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Attivo",
					accessor: "activeIcon",
					headerStyle: { textAlign: "center" },
					width: 100,
					Cell: (row) => (
						<div style={{ textAlign: "center" }}>
							{" "}
							{row.value === "1" ? (
								<img src={img1} alt={"Attivo"} />
							) : (
								<img src={img0} alt={"Non Attivo"} />
							)}{" "}
						</div>
					),
					filterable: true,
					filterMethod: (filter, row) => {
						if (filter.value === "Tutti") {
							return true;
						}
						if (filter.value === "1") {
							return row[filter.id] === "1";
						}
						if (filter.value === "0") {
							return row[filter.id] === "0";
						}
					},
					Filter: ({ filter, onChange }) => (
						<select
							onChange={(event) => onChange(event.target.value)}
							style={{ width: "100%", textAlign: "center" }}
							value={filter ? filter.value : "Tutti"}>
							<option value='Tutti'>Tutti</option>
							<option value='1'>Attivi</option>
							<option value='0'>Non Attivi</option>
						</select>
					),
				},
				{
					Header: " ",
					accessor: "filesIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "editIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "deleteIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
			];
			const data = [];
			for (let c = 0; c < reportsList.length; c++) {
				// let activeSrc = img0;
				// if (parseInt(reportsList[c].active) === 1) {
				//     activeSrc = img1;
				// }

				// Push data
				data.push({
					date: reportsList[c].date,
					user: reportsList[c].user.company,
					title: reportsList[c].title,
					activeIcon: reportsList[c].active,
					filesIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-file-pdf-o fa-2x'
							onClick={() => {
								this.openFilesHandler(c);
							}}></i>
					),
					editIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-edit fa-2x'
							onClick={() => {
								this.editReport(c);
							}}></i>
					),
					deleteIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-trash fa-2x'
							onClick={() => {
								this.deleteReport(reportsList[c].id);
							}}></i>
					),
				});
			}
			// Render Table
			reportsRender = (
				<ReactTable
					data={data}
					columns={columns}
					page={this.state.page}
					onPageChange={(page) => this.setState({ page })}
					pageSizeOptions={[50, 100, 200]}
					defaultPageSize={50}
					previousText={Globals.reactTableText.previous[this.state.language]}
					nextText={Globals.reactTableText.next[this.state.language]}
					loadingText={Globals.reactTableText.loading[this.state.language]}
					noDataText={Globals.reactTableText.noData[this.state.language]}
					pageText={Globals.reactTableText.page[this.state.language]}
					ofText={Globals.reactTableText.of[this.state.language]}
					rowsText={Globals.reactTableText.rows[this.state.language]}
					pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
					rowsSelectorText={
						Globals.reactTableText.rowsSelectorText[this.state.language]
					}
				/>
			);
		}

		let filesModal;
		if (this.state.showFilesModal === true) {
			let apiBaseGetUploadedFileUrl =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUploadedFile&access_token=" +
				this.state.accessToken +
				"&type=reports&fileId=";

			filesModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.filesModalTitle}{" "}
							<span className='modalTitleBarAndClient'>
								{" "}
								{this.state.filesModalReport.title || ""} --{" "}
								{this.state.filesModalReport.user.company || ""}{" "}
							</span>
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFilesHandler()}></i>
							</div>
						</div>
						<div className='content'>
							<Dropzone onDrop={this.handleOnDrop} accept='application/pdf'>
								{({ getRootProps, getInputProps, isDragActive }) => (
									<section>
										<div
											{...getRootProps()}
											className={
												"drag-and-drop-wrapper " + (isDragActive ? "active" : "")
											}>
											<input {...getInputProps()} />
											<p>
												Trascina qui i tuoi file o fai click <span>qui</span> per
												selezionarli
												<span className='small'>(File accettati: .pdf)</span>
											</p>
										</div>
									</section>
								)}
							</Dropzone>
							{this.state.filesModalReport.files !== undefined ? (
								<div className='uploaded-files-wrapper'>
									{this.state.filesModalReport.files.map((item, key) => {
										return (
											<div key={key} className='file'>
												<div className='icon'>
													<div className='pdfIcon'>
														<i className='fa fa-file-pdf-o' />
													</div>
												</div>
												<div className='name'>{item.title}</div>
												<div className='buttons'>
													<a
														href={apiBaseGetUploadedFileUrl + item.id}
														target='_blank'
														rel='noopener noreferrer'>
														<button type='button'>
															<i className='fa fa-eye'></i>
														</button>
													</a>
													<button
														type='button'
														onClick={() => this.removeReportFile(item.id)}>
														<i className='fa fa-trash'></i>
													</button>
												</div>
											</div>
										);
									})}
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			);
		}

		let formModal;
		if (this.state.showFormModal === true) {
			// Select utenti
			let formModalFieldUserSelectOptions = [defaultUserSelectValue];
			let usersList = this.state.usersList;
			for (let c = 0; c < usersList.length; c++) {
				let optionValue = { value: usersList[c].id, label: usersList[c].company };
				formModalFieldUserSelectOptions.push(optionValue);
			}

			let formErrorField;
			if (this.state.showFormError === true) {
				formErrorField = (
					<div className='input-wrapper full-width centered'>
						<div className='input-error-wrapper'>{this.state.formErrorMessage}</div>
					</div>
				);
			}

			formModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.formModalTitle}
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFormModal("", "")}></i>
							</div>
						</div>
						<div className='content three-sides-form'>
							{formErrorField}
							<div className='input-wrapper full-width centered'>
								<div className='label'>Attivo</div>
								<Switch
									isOn={this.state.formModalFieldActive}
									handleToggle={this.handleReportActiveChange}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Utente</div>
								<Select
									value={this.state.formModalFieldUser}
									onChange={this.handleUserChange}
									options={formModalFieldUserSelectOptions}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Data</div>
								<DatePicker
									locale={this.state.language}
									selected={this.state.formModalFieldDate}
									dateFormat='dd/MM/yyyy'
									onChange={this.handleDateChange}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Titolo</div>
								<input
									type='text'
									name='title'
									value={this.state.formModalFieldTitle}
									onChange={(e) =>
										this.setState({ formModalFieldTitle: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper full-width'>
								<div className='label'>Descrizione</div>
								<textarea
									name='description'
									value={this.state.formModalFieldDescription}
									onChange={(e) =>
										this.setState({ formModalFieldDescription: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper full-width small'>
								<button type='button' onClick={() => this.confirmReportForm()}>
									<span>Conferma</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='users-page-wrapper'>
				<div className='filters'>
					<div className='filters-wrapper'>
						<div className='filter-wrapper'>
							<div className='filter'>
								<button type='button' onClick={() => this.newReport()}>
									<span>Nuovo</span>
								</button>
								<span className='title-text'> GESTIONE RELAZIONI </span>
							</div>
						</div>
					</div>
				</div>
				{reportsRender}
				{loadingOnScreen}
				{formModal}
				{filesModal}
			</div>
		);
	}
}
