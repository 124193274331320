import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect, Link } from "react-router-dom";

export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogged:   props.isLogged,
            isAdmin:    props.isAdmin,
            isOperator: props.isOperator,
            language:   props.language,
            accessToken: props.accessToken,
            isPrivacyAccepted: null
        }

        if(props.location !== undefined){
            this.state = {
                isPrivacyAccepted: props.location.state.privacy || false
            }
        }

    }

    componentDidMount(){
        document.title = Globals.titles.home[this.state.language];

        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged:   props.isLogged,
            isAdmin:    props.isAdmin,
            isOperator: props.isOperator,
            language:   props.language,
            accessToken: props.accessToken
        });
        document.title = Globals.titles.home[props.language];
    }

    render() {

        if ( this.state.isLogged !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        return(
            <div className="home-box">
                
                <div className="home-links">
                    <div className="user-links">

                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/utenti">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-users fa-2x"></i></div>
                                            <div className="name">{Globals.labels.users[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-prodotti">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-archive fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminProducts[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-prodotti-camp-safety">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-archive fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminProductsCampSafety[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-installazioni">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-cogs fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminInstallations[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-relazioni">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-list fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminReports[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-interventi">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-briefcase fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminOperations[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-interventi-confermati">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-calendar-check-o  fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminConfirmedOperations[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-norme-e-direttive">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-graduation-cap fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminStandardsAndDirectives[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/gestione-manutenzioni">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-wrench fa-2x"></i></div>
                                            <div className="name">{Globals.labels.adminMaintenanceDescription[this.state.language]}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }


                        {this.state.isAdmin === false && this.state.isOperator === false
                            ? <div className="section-link-wrapper">
                                <Link to="/i-miei-prodotti">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-archive fa-2x"></i></div>
                                            <div className="name">Prodotti</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === false && this.state.isOperator === false
                            ? <div className="section-link-wrapper">
                                <Link to="/le-mie-relazioni">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-list fa-2x"></i></div>
                                            <div className="name">Relazioni</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === false && this.state.isOperator === false
                            ? <div className="section-link-wrapper">
                                <Link to="/i-miei-interventi">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-briefcase fa-2x"></i></div>
                                            <div className="name">Storico interventi</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === false && this.state.isOperator === false
                            ? <div className="section-link-wrapper">
                                <Link to="/norme-e-direttive">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-graduation-cap fa-2x"></i></div>
                                            <div className="name">Norme e direttive</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }
                        {this.state.isAdmin === false && this.state.isOperator === false
                            ? <div className="section-link-wrapper">
                                <Link to="/descrizione-manutenzioni">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-wrench fa-2x"></i></div>
                                            <div className="name">Manutenzioni</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }

                        {this.state.isAdmin === true || this.state.isOperator === true
                            ? <div className="section-link-wrapper">
                                <Link to="/sopralluoghi">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-eye fa-2x"></i></div>
                                            <div className="name">Sopralluoghi</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }

                        {this.state.isAdmin === true
                            ? <div className="section-link-wrapper">
                                <Link to="/preventivi">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-envelope-open-o fa-2x"></i></div>
                                            <div className="name">Preventivi</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }

                        {this.state.isAdmin === true || this.state.isOperator === true
                            ? <div className="section-link-wrapper">
                                <Link to="/preventivi-confermati">
                                    <div className="container">
                                        <div className="text-layer">
                                            <div className="icon"><i className="fa fa-envelope-o fa-2x"></i></div>
                                            <div className="name">Preventivi confermati</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            : <span></span>
                        }

                        <div className="section-link-wrapper">
                            <Link to="/modifica-profilo">
                                <div className="container">
                                    <div className="text-layer">
                                        <div className="icon"><i className="fa fa-user fa-2x"></i></div>
                                        <div className="name">Modifica profilo</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="section-link-wrapper">
                            <Link to="/modifica-password">
                                <div className="container">
                                    <div className="text-layer">
                                        <div className="icon"><i className="fa fa-key fa-2x"></i></div>
                                        <div className="name">Modifica password</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}