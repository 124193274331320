import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import moment from "moment/moment";
import { withRouter } from 'react-router-dom'

import Loading from './../components/Loading.js';

const defaultInstallationSelectValue = { value: "", label: "Seleziona un'installazione" };

class UserOperations extends Component {
    constructor(props) {
        super(props);

        let preselectedInstallationId = null;
        if (props.location !== undefined) {
            if (props.location.state !== undefined) {
                if (props.location.state.installationId !== undefined) {
                    preselectedInstallationId = props.location.state.installationId;
                }
            }
        }

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            language: props.language,
            list: [],
            listMessage: "",

            preselectedInstallationId: preselectedInstallationId,
            selectedInstallation: defaultInstallationSelectValue,
        }

        this.handleInstallationChange = this.handleInstallationChange.bind(this);
    }

    handleInstallationChange(element) {
        this.setState({
            selectedInstallation: element
        });
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.userOperations[this.state.language];

        window.scrollTo(0, 0);

        this.getList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            language: props.language
        });
        document.title = Globals.titles.userOperations[props.language];
    }

    getList() {
        // Reset state
        this.setState({
            loading: true,
            list: [],
            listMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getUserOperations&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    loading: false,
                    list: [],
                    listMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    loading: false,
                    list: responseJson.list,
                    listMessage: "",
                });

                this.handleListPreselection();
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                loading: false,
                list: [],
                listMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    handleListPreselection() {
        let preselectedInstallationId = this.state.preselectedInstallationId;

        if (preselectedInstallationId !== undefined && preselectedInstallationId !== null) {
            let preselectedInstallation = this.state.list.find((element) => {return parseInt(element.id) === parseInt(preselectedInstallationId)});

            let installationLabel = preselectedInstallation.product.name + ' (' + preselectedInstallation.type.description + ') - ' + preselectedInstallation.site + ' [Matr: ' + preselectedInstallation.serial_number + '] ' + ((preselectedInstallation.installationUser !== '') ? '[' + preselectedInstallation.installationUser + ']' : "");
            let selectedInstallation = { value: preselectedInstallationId, label: installationLabel };

            this.setState({
                selectedInstallation: selectedInstallation,
            });
        }
    }

    render() {
        if ( this.state.isLogged !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let listMessage = this.state.listMessage;
        let render  = "";
        if ( listMessage !== "" ) {
            render = <div className="message-wrapper">
                {listMessage}
            </div>;
        } else {
            // Select installations
            let installationsSelectOptions = [defaultInstallationSelectValue];
            let installationsList = this.state.list;
            for( let c = 0; c < installationsList.length; c++ ){
                let installationLabel = installationsList[c].product.name + ' (' + installationsList[c].type.description + ') - ' + installationsList[c].site + ' [Matr: ' + installationsList[c].serial_number + '] ' + ((installationsList[c].installationUser !== '') ? '[' + installationsList[c].installationUser + ']' : "");
                let optionValue = { value: installationsList[c].id, label: installationLabel };
                installationsSelectOptions.push(optionValue);
            }

            let selectedInstallationId = this.state.selectedInstallation.value;
            let operationsRender;
            if (selectedInstallationId !== "") {
                let selectedInstallation = this.state.list.find((element) => {return parseInt(element.id) === parseInt(selectedInstallationId)});

                operationsRender = <div className="operations-list">
                    {selectedInstallation.operations.map((item, key) => {
                        return <div className="operation" key={key}>
                            <div className="title">{moment(item.date).format("DD/MM/YYYY")} - {item.type}</div>
                            <div>
                                {item.nextOperationDate !== "" ? <div className="field evidence"><span>Prossimo controllo:</span> {moment(item.nextOperationDate).format("DD/MM/YYYY")}</div> : ""}
                            </div>
                            {item.notes !== "" ? <div className="field"><span>Note:</span> {item.notes}</div> : ""}
                        </div>
                    })}
                </div>;
            }

            render = <div className="contents-wrapper">
                <div className="select-wrapper">
                    <div className="label">Installazione</div>
                    <Select
                        value={this.state.selectedInstallation}
                        onChange={this.handleInstallationChange}
                        options={installationsSelectOptions}
                    />
                </div>
                {operationsRender}
            </div>
        }

        return(
            <div className="user-operations-wrapper">
                {render}
                {loadingOnScreen}
            </div>
        );
    }
}

export default withRouter(UserOperations);
