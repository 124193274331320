import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import Dropzone from "react-dropzone";

import Loading from "./../components/Loading.js";

import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";

import img0 from "./../assets/img/0.png";
import img1 from "./../assets/img/1.png";

import it from "date-fns/locale/it";
import Form from "../components/Form";

registerLocale("it", it);

const defaultUserSelectValue = { value: "", label: "Seleziona un cliente" };

export default class Quotations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			accessToken: props.accessToken,
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
			usersList: [],
			inspectionsList: [],
			list: [],
			listMessage: "",
			data: [],
			quotationFromInspection: "",

			showFormModal: false,
			formModalTitle: "",
			formModalAction: "",

			showFilesModal: false,
			filesModalTitle: "Gestione file",
			filesModalQuotation: {},
			filesToUpload: [],

			showInspectionsModal: false,
			inspectionsModalTitle: "Sopralluoghi correlati",
			modalInspectionsList: [],

			formModalFieldId: null,
			formModalFieldUser: defaultUserSelectValue,
			formModalFieldDate: new Date(),
			formModalFieldNumber: "",
			formModalFieldTitle: "",
			formModalFieldOfferDescription: "",
			formModalFieldOfferValues: "",
			formModalFieldTimingDescription: "",
			formModalFieldDescription: "",
			formModalFieldSelectedInspectionsList: [],
			formModalFieldAcceptanceDate: null,
			formModalFieldIntroduction: "",
			formModalFieldOfferQuotationList: [],

			showFormError: false,
			formErrorMessage: "",

			formData: {
				title: "",
				formAction: "",
				fields: [
					{
						name: "id",
						label: "ID",
						visible: false,
						value: null,
						type: "hidden",
						mandatory: false,
						errorMessage: "",
					},
					{
						name: "formModalFieldUser",
						label: "Cliente",
						visible: true,
						value: null,
						type: "select",
						options: [],
						mandatory: true,
						errorMessage: "",
					},
					{
						name: "formModalFieldDate",
						label: "Data",
						visible: true,
						value: null,
						type: "date",
						mandatory: true,
						errorMessage: "",
					},
					{
						name: "formModalFieldNumber",
						label: "Numero offerta",
						visible: true,
						value: null,
						type: "text",
						mandatory: true,
						errorMessage: "",
					},
					{
						name: "formModalFieldSelectedInspectionsList",
						label: "Sopralluoghi correlati",
						visible: true,
						value: null,
						type: "multiselect",
						mandatory: true,
						errorMessage: "",
					},
					{
						name: "formModalFieldTitle",
						label: "Titolo",
						visible: true,
						value: "",
						type: "text-title",
						mandatory: true,
						errorMessage: "",
					},
					{
						name: "formModalFieldAcceptanceDate",
						label: "Data di accettazione",
						visible: true,
						value: null,
						type: "date-input",
						mandatory: false,
						errorMessage: "",
					},
					{
						name: "formModalFieldIntroduction",
						label: "Introduzione",
						visible: true,
						value: "",
						type: "textarea",
						mandatory: false,
						errorMessage: "",
					},
					{
						nameOfferDescription: "formModalFieldOfferDescription",
						nameOfferValue: "formModalFieldOfferValues",
						nameList: "formModalOffer",
						nameTotalPrice: "formModalTotal",
						nameisListQuotation: "formModalList",
						labelOfferDescription: "Offerta Economica (Descrizione)",
						labelOfferValue: "Offerta Economica (Valori)",
						labelList: "Offerta Economica (Lista)",
						labelTotal: "Totale complessivo",
						labelisListQuotation: "Lista",
						visible: true,
						valueOfferDescription: "",
						valueOfferPrice: "",
						valueOfferList: "",
						valueTotalPrice: "",
						isListQuotation: "",
						isEditable: "",
						type: "offerInput",
						mandatory: false,
						errorMessage: "",
					},
					{
						name: "formModalFieldTimingDescription",
						label: "Tempistiche",
						visible: true,
						value: "",
						type: "textarea",
						mandatory: false,
						errorMessage: "",
					},
					{
						name: "formModalFieldDescription",
						label: "Descrizione Finale",
						visible: true,
						value: "",
						type: "textarea",
						mandatory: false,
						errorMessage: "",
					},
				],
			},
		};

		this.handleUserChange = this.handleUserChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleAcceptanceDateChange = this.handleAcceptanceDateChange.bind(this);
		this.handleOnDrop = this.handleOnDrop.bind(this);
		this.removeFile = this.removeFile.bind(this);
	}

	componentWillUnmount() {
		document.body.classList.remove("no-background");
		document.body.classList.remove("page-with-filter-bar");
	}

	componentDidMount() {
		document.body.classList.add("no-background");
		document.body.classList.add("page-with-filter-bar");

		document.title = Globals.titles.quotations[this.state.language];

		window.scrollTo(0, 0);

		this.getList();

		if (this.props.location.state !== undefined) {
			if (
				this.props.location.state.fromInspection !== undefined &&
				this.props.location.state.fromInspection === true
			) {
				if (
					this.props.location.state.quotationId !== undefined &&
					this.props.location.state.quotationId !== ""
				) {
					this.setState({ loading: true });

					setTimeout(() => {
						this.editQuotation(null, this.props.location.state.quotationId);
					}, 1000);
				}
			}
		}
	}

	componentWillReceiveProps(props) {
		this.setState({
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
		});
		document.title = Globals.titles.quotations[props.language];
	}

	// renderEditable = cellInfo => {
	//     return (
	//         <div
	//             style={{ backgroundColor: "#fafafa" }}
	//             contentEditable
	//             suppressContentEditableWarning
	//             onBlur={e => {
	//                 const data = [...this.state.data];
	//                 data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
	//                 this.setState({ data });
	//             }}
	//             dangerouslySetInnerHTML={{
	//                 __html: this.state.data[cellInfo.index][cellInfo.column.id]
	//             }}
	//         />
	//     );
	// };
	getList(specialAction) {
		// Reset state
		this.setState({
			list: [],
			listMessage: "",
			loading: true,
		});

		// Fetch api for user's orders
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=getQuotations&access_token=" +
			accessToken;

		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					this.setState({
						list: [],
						usersList: [],
						inspectionsList: [],
						listMessage: responseJson.message,
						loading: false,
					});
				} else {
					// Call is ok, store ordersData in state
					this.setState({
						list: responseJson.quotations,
						usersList: responseJson.users,
						inspectionsList: responseJson.inspections,
						listMessage: "",
						loading: false,
					});

					if (undefined !== specialAction) {
						switch (specialAction) {
							case "reloadFiles":
								let actualFilesElement = this.state.filesModalQuotation;

								let refreshedElement = cloneDeep(
									this.state.list.find((element) => {
										return parseInt(element.id) === parseInt(actualFilesElement.id);
									})
								);

								this.setState({
									filesModalQuotation: refreshedElement,
									loading: false,
								});
								break;
							default:
								break;
						}
					}
				}
			})
			.catch((error) => {
				// Communication error - handle message
				this.setState({
					list: [],
					usersList: [],
					inspectionsList: [],
					listMessage: Globals.errors.serverCall[this.state.language],
					loading: false,
				});
			});
	}

	handleOnDrop(files) {
		this.setState({
			loading: true,
		});

		// Fetch api
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=uploadQuotationFile&access_token=" +
			accessToken;

		const formdata = new FormData();

		formdata.append("id", this.state.filesModalQuotation.id);

		files.forEach((file) => {
			formdata.append("uploadedFiles[]", file, file.name);
		});

		fetch(url, {
			method: "POST",
			body: formdata,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					toast(responseJson.message, {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				} else {
					// Call is ok
					toast("Operazione completata", {
						autoClose: 4000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});

					//this.toggleFilesHandler();
					this.getList("reloadFiles");
				}
			})
			.catch((error) => {
				// Communication error - handle message
				toast(Globals.errors.serverCall[this.state.language], {
					autoClose: 8000,
					className: "std-black-toast",
					bodyClassName: "std-black-toast-body",
					progressClassName: "std-black-toast-progress-bar",
				});

				this.setState({
					loading: false,
				});
			});
	}

	removeFile(fileId) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteQuotationFile&access_token=" +
				accessToken;

			const formdata = new FormData();

			formdata.append("fileId", fileId);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						//this.toggleFilesHandler();
						this.getList("reloadFiles");
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	openFilesHandler(listPosition) {
		this.toggleFilesHandler();

		let report = cloneDeep(this.state.list[listPosition]);

		this.setState({
			filesModalQuotation: report,
		});
	}

	toggleFilesHandler() {
		let showFilesModal = !this.state.showFilesModal;

		this.setState({
			showFilesModal: showFilesModal,
			filesModalQuotation: {},
			filesToUpload: [],
		});
	}

	newQuotation() {
		this.toggleFormModal("Nuovo preventivo", "newQuotation");

		let formModalFieldInspectionsList = [];
		let inspectionsList = this.state.inspectionsList;
		for (let c = 0; c < inspectionsList.length; c++) {
			let inspection_code = inspectionsList[c].inspection_code || "";
			let label =
				moment(inspectionsList[c].date).format("DD/MM/YYYY") +
				" - " +
				inspectionsList[c].user.company +
				" - " +
				inspection_code +
				" " +
				inspectionsList[c].title;
			let optionValue = {
				label: label,
				value: inspectionsList[c].id,
			};
			formModalFieldInspectionsList.push(optionValue);
		}

		let formModalFieldUserSelectOptions = [defaultUserSelectValue];
		let usersList = this.state.usersList;
		for (let c = 0; c < usersList.length; c++) {
			let optionValue = {
				value: usersList[c].id,
				label: usersList[c].company,
			};
			formModalFieldUserSelectOptions.push(optionValue);
		}

		this.setState((prevState) => ({
			showForm: true,

			formData: {
				title: "Nuovo preventivo",
				formAction: "newQuotation",
				fields: [
					{
						...prevState.formData.fields[0],
						value: null,
						errorMessage: "",
					},
					{
						...prevState.formData.fields[1],
						options: formModalFieldUserSelectOptions,
						value: null,
						errorMessage: "",
					},
					{
						...prevState.formData.fields[2],
						value: null,
						errorMessage: "",
					},
					{
						...prevState.formData.fields[3],
						value: null,
						errorMessage: "",
					},
					{
						...prevState.formData.fields[4],
						options: formModalFieldInspectionsList,
						value: null,
						errorMessage: "",
					},
					{
						...prevState.formData.fields[5],
						value: "",
						errorMessage: "",
					},
					{
						...prevState.formData.fields[6],
						value: null,
						errorMessage: "",
					},
					{
						...prevState.formData.fields[7],
						value: "",
						errorMessage: "",
					},
					{
						...prevState.formData.fields[8],
						valueOfferDescription: "",
						valueOfferPrice: "",
						valueOfferList: "",
						valueTotalPrice: "",
						isListQuotation: "",
						errorMessage: "",
					},
					{
						...prevState.formData.fields[9],
						value: "",
						errorMessage: "",
					},
					{
						...prevState.formData.fields[10],
						value: "",
						errorMessage: "",
					},
				],
			},
		}));
	}

	editQuotation(listPosition = null, id = null) {
		let quotation;

		if (id !== null) {

			let searchIndex = this.state.list.findIndex((item) => parseInt(item.id) === parseInt(id));
			quotation = cloneDeep(this.state.list[searchIndex]);
		} else {
			quotation = cloneDeep(this.state.list[listPosition]);
		}

		if (quotation !== undefined) {
			let userSelected = {
				value: quotation.user.id,
				label: quotation.user.company,
			};

			let date = quotation.date;
			if (date !== null && date !== undefined) {
				this.setState({
					formModalFieldDate: new Date(date),
				});
			}

			let selectedInspections = [];
			for (let c = 0; c < quotation.inspections.length; c++) {
				let inspection_code = quotation.inspections[c].inspection_code || "";
				let label =
					moment(quotation.inspections[c].date).format("DD/MM/YYYY") +
					" - " +
					quotation.inspections[c].user.company +
					"  " +
					inspection_code +
					" - " +
					quotation.inspections[c].title;
				let optionValue = {
					label: label,
					value: quotation.inspections[c].id,
				};
				selectedInspections.push(optionValue);
			}

			let formModalFieldUserSelectOptions = [defaultUserSelectValue];
			let usersList = this.state.usersList;
			for (let c = 0; c < usersList.length; c++) {
				let optionValue = {
					value: usersList[c].id,
					label: usersList[c].company,
				};
				formModalFieldUserSelectOptions.push(optionValue);
			}

			let formModalFieldInspectionsList = [];
			let inspectionsList = this.state.inspectionsList;
			for (let c = 0; c < inspectionsList.length; c++) {
				let inspection_code = inspectionsList[c].inspection_code || "";
				let label =
					moment(inspectionsList[c].date).format("DD/MM/YYYY") +
					" - " +
					inspectionsList[c].user.company +
					" - " +
					inspection_code +
					" " +
					inspectionsList[c].title;
				let optionValue = {
					label: label,
					value: inspectionsList[c].id,
				};
				formModalFieldInspectionsList.push(optionValue);
			}

			this.setState((prevState) => ({
				formData: {
					title: "Modifica",
					formAction: "editQuotation",
					fields: [
						{
							...prevState.formData.fields[0],
							value: quotation.id,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[1],
							options: formModalFieldUserSelectOptions,
							value: userSelected,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[2],
							value: prevState.formModalFieldDate,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[3],
							value: quotation.number,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[4],
							options: formModalFieldInspectionsList,
							value: selectedInspections,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[5],
							value: quotation.title,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[6],
							value:
								quotation.acceptance_date !== null
									? new Date(quotation.acceptance_date)
									: null,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[7],
							value: quotation.introduction,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[8],
							valueOfferDescription: quotation.offer_description,
							valueOfferPrice: quotation.offer_values,
							valueOfferList: quotation.quotationList,
							valueTotalPrice: quotation.total_price,
							isListQuotation: quotation.is_list,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[9],
							value: quotation.timing_description,
							errorMessage: "",
						},
						{
							...prevState.formData.fields[10],
							value: quotation.description,
							errorMessage: "",
						},
					],
				},
			}));

			this.toggleFormModal("Modifica preventivo", "editQuotation");
		}
	}

	deleteQuotation(id) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteQuotation&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	toggleFormModal(title, action) {
		let showFormModal = this.state.showFormModal;

		if (showFormModal === true) {
			showFormModal = false;
		} else {
			showFormModal = true;
		}

		this.setState({
			showFormModal: showFormModal,
			formModalTitle: title,
			formModalAction: action,

			formModalFieldId: null,
			formModalFieldUser: defaultUserSelectValue,
			formModalFieldDate: new Date(),
			formModalFieldNumber: "",
			formModalFieldTitle: "",
			formModalFieldIntroduction: "",
			formModalFieldOfferDescription: "",
			formModalFieldOfferValues: "",
			formModalFieldTimingDescription: "",
			formModalFieldDescription: "",
			formModalFieldSelectedInspectionsList: [],
			formModalFieldAcceptanceDate: null,
			formModalFieldOfferQuotationList: [],

			showFormError: false,
			formErrorMessage: "",
		});
	}

	setInputErrorMessage(field, message) {
		switch (field) {
			case "stdError":
				this.setState({
					showFormError: true,
					formErrorMessage: message,
				});
				break;
			default:
				break;
		}
	}

	formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	confirmForm(jsondata) {
		if (window.confirm("Confermi i dati forniti?")) {
			let fieldsErrors = false;

			for (let c = 0; c < jsondata.fields.length; c++) {
				let field = jsondata.fields[c];

				if (field.mandatory === true) {
					jsondata.fields[c].errorMessage = "";

					switch (field.type) {
						case "select":
							if (field.value === "" || field.value === null) {
								jsondata.fields[c].errorMessage = "Campo obbligatorio";
								fieldsErrors = true;
							}
							break;

						default:
							if (field.value === "" || field.value === null) {
								jsondata.fields[c].errorMessage = "Campo obbligatorio";
								fieldsErrors = true;
							}
							break;
					}
				}
			}

			// Fetch api
			let accessToken = this.state.accessToken;
			let formAction = this.state.formModalAction;

			// Aggiorno lo stato con i dati inseriti e gli eventuali errori
			this.setState({
				formData: jsondata,
			});

			if (fieldsErrors === true) {
				return;
			}

			let formData = {
				id: jsondata.fields[0].value,
				usersId: jsondata.fields[1].value.value,
				date: this.formatDate(jsondata.fields[2].value),
				number: jsondata.fields[3].value,
				title: jsondata.fields[5].value,
				introduction: jsondata.fields[7].value,
				offer_description: jsondata.fields[8].valueOfferDescription,
				offer_values: jsondata.fields[8].valueOfferPrice,
				total_price: jsondata.fields[8].valueTotalPrice,
				is_list: jsondata.fields[8].isListQuotation,
				timing_description: jsondata.fields[9].value,
				description: jsondata.fields[10].value,
				inspections: jsondata.fields[4].value,
				list: jsondata.fields[8].valueOfferList,
				acceptance_date:
					jsondata.fields[6].value !== null
						? this.formatDate(jsondata.fields[6].value)
						: null,
			};
			if (formData.usersId === "") {
				this.setInputErrorMessage("stdError", "Devi selezionare un utente");
				this.setState({
					loading: false,
				});
				return;
			}
			if (formData.date === "") {
				this.setInputErrorMessage("stdError", "Devi indicare una data");
				this.setState({
					loading: false,
				});
				return;
			}

			if (formData.number === "") {
				this.setInputErrorMessage("stdError", "Devi indicare un numero offerta");
				this.setState({
					loading: false,
				});
				return;
			}

			if (formData.title === "") {
				this.setInputErrorMessage("stdError", "Devi indicare il titolo");
				this.setState({
					loading: false,
				});
				return;
			}

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=" +
				formAction +
				"&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("formData", JSON.stringify(formData));

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.toggleFormModal();
						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	confirmQuotation(id) {
		if (window.confirm("Confermi l'approvazione del preventivo?")) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=confirmQuotation&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	handleUserChange(element) {
		this.setState({
			formModalFieldUser: element,
		});
	}

	handleDateChange(date) {
		this.setState({
			formModalFieldDate: date,
		});
	}

	handleAcceptanceDateChange(date) {
		this.setState({
			formModalFieldAcceptanceDate: date,
		});
	}

	openInspectionsViewer(listPosition) {
		this.toggleInspectionsHandler();

		let quotation = cloneDeep(this.state.list[listPosition]);

		this.setState({
			modalInspectionsList: quotation.inspections,
		});
	}

	toggleInspectionsHandler() {
		let showInspectionsModal = !this.state.showInspectionsModal;

		this.setState({
			showInspectionsModal: showInspectionsModal,
			modalInspectionsList: [],
		});
	}

	toggleArchiving(id) {
		if (
			window.confirm(
				"Confermi di voler modificare lo stato di archiviazione del preventivo?"
			)
		) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=toggleArchiving&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	createPdf(id) {
		// Fetch api for user's orders
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=quotationCreatePdf&access_token=" +
			accessToken +
			"&id=" +
			id;

		window.open(url, "name", "titlebar=no").focus();
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		if (this.state.isLogged !== true || this.state.isAdmin !== true) {
			return <Redirect to={{ pathname: "/login" }} />;
		}

		let loadingOnScreen;
		if (this.state.loading === true) {
			loadingOnScreen = <Loading />;
		}

		let list = this.state.list;
		let listMessage = this.state.listMessage;
		let bodyRender = "";
		if (listMessage !== "") {
			bodyRender = <div className='message-wrapper'>{listMessage}</div>;
		} else {
			// Prepare data for the table;
			const columns = [
				{
					Header: "Id",
					accessor: "id",
					show: false,
				},
				{
					Header: "Archiviato",
					accessor: "archived",
					headerStyle: { textAlign: "center" },
					width: 140,
					Cell: (row) => (
						<div style={{ textAlign: "center" }}>
							{" "}
							{row.value === "1" ? (
								<img
									src={img1}
									className='std-hoverable-cursor-pointer'
									alt={"Attivo"}
									onClick={() => {
										this.toggleArchiving(row.row["id"]);
									}}
								/>
							) : (
								<img
									src={img0}
									className='std-hoverable-cursor-pointer'
									alt={"Non Attivo"}
									onClick={() => {
										this.toggleArchiving(row.row["id"]);
									}}
								/>
							)}{" "}
						</div>
					),
					filterable: true,
					filterMethod: (filter, row) => {
						if (filter.value === "Tutti") {
							return true;
						}
						if (filter.value === "1") {
							return row[filter.id] === "1";
						}
						if (filter.value === "0") {
							return row[filter.id] === "0";
						}
					},
					Filter: ({ filter, onChange }) => (
						<select
							onChange={(event) => onChange(event.target.value)}
							style={{ width: "100%", textAlign: "center" }}
							value={filter ? filter.value : "Tutti"}>
							<option value='Tutti'>Tutti</option>
							<option value='1'>Archiviato</option>
							<option value='0'>Non Archiviato</option>
						</select>
					),
				},
				{
					Header: "Data",
					accessor: "date",
					Cell: (c) => <span>{moment(c.original.date).format("DD/MM/YYYY")}</span>,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Utente",
					accessor: "user",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Numero offerta",
					accessor: "number",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Titolo",
					accessor: "title",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Data di accettazione",
					accessor: "acceptance_date",
					Cell: (c) =>
						c.original.acceptance_date !== null ? (
							<span>{moment(c.original.acceptance_date).format("DD/MM/YYYY")}</span>
						) : (
							<span></span>
						),
					filterable: true,
					filterMethod: (filter, row) => {
						if (row[filter.id] !== null) {
							return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
						}
					},
				},
				{
					Header: "n°allegati",
					accessor: "attachments",
					width: 120,
					Cell: (row) => (
						<div style={{ textAlign: "center", fontSize: 15 }}>{row.value}</div>
					),
				},
				{
					Header: " ",
					accessor: "inspectionsIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "printIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "filesIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "confirmIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "editIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "deleteIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
			];
			const data = [];
			for (let c = 0; c < list.length; c++) {
				// Push data
				data.push({
					id: list[c].id,
					archived: list[c].archived,
					date: list[c].date,
					user: list[c].user.company,
					number: list[c].number,
					title: list[c].title,
					acceptance_date: list[c].acceptance_date,
					inspectionsIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-eye fa-2x'
							onClick={() => {
								this.openInspectionsViewer(c);
							}}></i>
					),
					attachments: list[c].files.length,
					filesIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-file-pdf-o fa-2x'
							onClick={() => {
								this.openFilesHandler(c);
							}}></i>
					),
					printIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-print fa-2x'
							onClick={() => this.createPdf(list[c].id)}></i>
					),
					confirmIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-check fa-2x'
							onClick={() => {
								this.confirmQuotation(list[c].id);
							}}></i>
					),
					editIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-edit fa-2x'
							onClick={() => {
								this.editQuotation(c);
							}}></i>
					),
					deleteIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-trash fa-2x'
							onClick={() => {
								this.deleteQuotation(list[c].id);
							}}></i>
					),
				});
			}
			// Render Table
			bodyRender = (
				<ReactTable
					data={data}
					columns={columns}
					pageSizeOptions={[50, 100, 200]}
					defaultPageSize={100}
					page={this.state.page}
					onPageChange={(page) => this.setState({ page })}
					previousText={Globals.reactTableText.previous[this.state.language]}
					nextText={Globals.reactTableText.next[this.state.language]}
					loadingText={Globals.reactTableText.loading[this.state.language]}
					noDataText={Globals.reactTableText.noData[this.state.language]}
					pageText={Globals.reactTableText.page[this.state.language]}
					ofText={Globals.reactTableText.of[this.state.language]}
					rowsText={Globals.reactTableText.rows[this.state.language]}
					pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
					rowsSelectorText={
						Globals.reactTableText.rowsSelectorText[this.state.language]
					}
				/>
			);
		}

		let inspectionsModal;
		if (this.state.showInspectionsModal === true) {
			let apiBaseGetUploadedFileUrl =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUploadedFile&access_token=" +
				this.state.accessToken +
				"&type=inspections&fileId=";

			inspectionsModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.inspectionsModalTitle}
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleInspectionsHandler()}></i>
							</div>
						</div>
						<div className='content'>
							{this.state.modalInspectionsList.length > 0 ? (
								<div className='inspections-list'>
									{this.state.modalInspectionsList.map((item, key) => {
										return (
											<div key={key} className='inspection'>
												<div className='title'>
													{moment(item.date).format("DD/MM/YYYY")} - {item.user.company} -{" "}
													S{item.inspection_code || ""} {item.title}
												</div>
												<div className='description'>{item.description}</div>
												{item.files !== undefined ? (
													<div className='uploaded-files-wrapper'>
														{item.files.map((item2, key2) => {
															return (
																<div key={key2} className='file'>
																	<div className='icon'>
																		{item2.url.split(".").pop() === "jpg" ||
																		item2.url.split(".").pop() === "png" ||
																		item2.url.split(".").pop() === "jpeg" ? (
																			<img
																				src={item2.url}
																				className='previewImage'
																				alt='preventivo'
																			/>
																		) : (
																			<div className='pdfIcon'>
																				<i className='fa fa-file-pdf-o' />
																			</div>
																		)}
																	</div>
																	<div className='name'>{item2.title}</div>
																	<div className='buttons'>
																		<a
																			href={apiBaseGetUploadedFileUrl + item2.id}
																			target='_blank'
																			rel='noopener noreferrer'>
																			<button className='full-size' type='button'>
																				<i className='fa fa-eye'></i>
																			</button>
																		</a>
																	</div>
																</div>
															);
														})}
													</div>
												) : (
													<div></div>
												)}
											</div>
										);
									})}
								</div>
							) : (
								<div className='modal-message'>Nessun sopralluogo correlato</div>
							)}
						</div>
					</div>
				</div>
			);
		}

		let filesModal;
		if (this.state.showFilesModal === true) {
			let apiBaseGetUploadedFileUrlQuotations =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUploadedFile&access_token=" +
				this.state.accessToken +
				"&type=quotations&fileId=";

			filesModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.filesModalTitle}{" "}
							<span className='modalTitleBarAndClient'>
								{" "}
								{this.state.filesModalQuotation.title || ""} --{" "}
								{this.state.filesModalQuotation.user.company || ""}{" "}
							</span>
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFilesHandler()}></i>
							</div>
						</div>
						<div className='content'>
							<Dropzone
								onDrop={this.handleOnDrop}
								accept='application/pdf, image/*'>
								{({ getRootProps, getInputProps, isDragActive }) => (
									<section>
										<div
											{...getRootProps()}
											className={
												"drag-and-drop-wrapper " + (isDragActive ? "active" : "")
											}>
											<input {...getInputProps()} />
											<p>
												Trascina qui i tuoi file o fai click <span>qui</span> per
												selezionarli
												<span className='small'>
													(File accettati: .pdf, .jpeg, .jpg, .png, .gif)
												</span>
											</p>
										</div>
									</section>
								)}
							</Dropzone>
							{this.state.filesModalQuotation.files !== undefined ? (
								<div className='uploaded-files-wrapper'>
									{this.state.filesModalQuotation.files.map((item, key) => {
										return (
											<div key={key} className='file'>
												<div className='icon'>
													{item.url.split(".").pop() === "jpg" ||
													item.url.split(".").pop() === "png" ||
													item.url.split(".").pop() === "jpeg" ||
													item.url.split(".").pop() === "heic"
													 ? (
														<img src={item.url} className='previewImage' alt='preventivo' />
													) : (
														<div className='pdfIcon'>
															<i className='fa fa-file-pdf-o' />
														</div>
													)}
												</div>
												<div className='name'>{item.title}</div>
												<div className='buttons'>
													<a
														href={apiBaseGetUploadedFileUrlQuotations + item.id}
														target='_blank'
														rel='noopener noreferrer'>
														<button type='button'>
															<i className='fa fa-eye'></i>
														</button>
													</a>
													<button type='button' onClick={() => this.removeFile(item.id)}>
														<i className='fa fa-trash'></i>
													</button>
												</div>
											</div>
										);
									})}
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			);
		}

		let formModal;
		if (this.state.showFormModal === true) {
			// Select utenti
			let formModalFieldUserSelectOptions = [defaultUserSelectValue];
			let usersList = this.state.usersList;
			for (let c = 0; c < usersList.length; c++) {
				let optionValue = {
					value: usersList[c].id,
					label: usersList[c].company,
				};
				formModalFieldUserSelectOptions.push(optionValue);
			}

			// Multiselect sopralluoghi
			let formModalFieldInspectionsList = [];
			let inspectionsList = this.state.inspectionsList;
			for (let c = 0; c < inspectionsList.length; c++) {
				let inspection_code = inspectionsList[c].inspection_code || "";
				let label =
					moment(inspectionsList[c].date).format("DD/MM/YYYY") +
					" - " +
					inspectionsList[c].user.company +
					" - " +
					inspection_code +
					" " +
					inspectionsList[c].title;
				let optionValue = {
					label: label,
					value: inspectionsList[c].id,
				};
				formModalFieldInspectionsList.push(optionValue);
			}

			formModal = (
				<Form
					formData={this.state.formData}
					language={this.state.language}
					accessToken={this.state.accessToken}
					confirmForm={this.confirmForm.bind(this)}
					closeForm={this.toggleFormModal.bind(this)}
				/>
			);
		}

		return (
			<div className='users-page-wrapper'>
				<div className='filters'>
					<div className='filters-wrapper'>
						<div className='filter-wrapper'>
							<div className='filter'>
								<button type='button' onClick={() => this.newQuotation()}>
									<span>Nuovo</span>
								</button>
								<span className='title-text'> GESTIONE PREVENTIVI </span>
							</div>
						</div>
					</div>
				</div>
				{bodyRender}
				{loadingOnScreen}
				{formModal}
				{filesModal}
				{inspectionsModal}
			</div>
		);
	}
}
