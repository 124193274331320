import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import moment from "moment/moment";
import { Link } from "react-router-dom";

import Loading from './../components/Loading.js';

export default class UserProducts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            language: props.language,
            productsList: [],
            productsListMessage: "",
        }
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        //document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        //document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.userProducts[this.state.language];

        window.scrollTo(0, 0);

        this.getProductsList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            language: props.language
        });
        document.title = Globals.titles.userProducts[props.language];
    }

    getProductsList() {
        // Reset state
        this.setState({
            loading: true,
            productsList: [],
            productsListMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getUserProducts&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    loading: false,
                    productsList: [],
                    productsListMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    loading: false,
                    productsList: responseJson.products,
                    productsListMessage: "",
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                loading: false,
                productsList: [],
                productsListMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    render() {
        if ( this.state.isLogged !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let productsListMessage = this.state.productsListMessage;
        let productsRender  = "";
        if ( productsListMessage !== "" ) {
            productsRender = <div className="message-wrapper">
                {productsListMessage}
            </div>;
        } else {
            productsRender = <div className="products-list-wrapper">
                {this.state.productsList.map((item, key) => {
                    return <div key={key} className="product-wrapper">
                        <div className="name">{item.product.name}{item.type.description !== "" ? " (" + item.type.description + ")" : ""}{item.site !== "" ? " " + item.site : ""}</div>
                        <div>
                            <div className="field"><span>Marca:</span> {item.brand}</div>
                            <div className="field"><span>Modello:</span> {item.model}</div>
                            <div className="field"><span>Matricola:</span> {item.serial_number}</div>
                        </div>
                        <div>
                            {item.lot !== "" ? <div className="field"><span>Lotto ed anno di fabbricazione:</span> {item.lot}</div> : ""}
                            {item.duration !== "" ? <div className="field"><span>Durata di vita massima prevista:</span> {item.duration}</div> : ""}
                        </div>
                        <div>
                            {item.date_of_purchase !== null ? <div className="field"><span>Data di acquisto:</span> {moment(item.date_of_purchase).format("DD/MM/YYYY")}</div> : ""}
                            {item.date_of_first_use !== null ? <div className="field"><span>Data del primo utilizzo:</span> {moment(item.date_of_first_use).format("DD/MM/YYYY")}</div> : ""}
                        </div>
                        <div>
                            {item.installationUser !== "" ? <div className="field"><span>Utilizzatore:</span> {item.installationUser}</div> : ""}
                        </div>
                        <div className="attributes-wrapper">
                            {item.attributes.sicurezza.length > 0 ? <div className="attributes-list">
                                <div className="attribute-title">Dispositivi di sicurezza</div>
                                <ul className="fa-ul">
                                    {item.attributes.sicurezza.map((item2, key2) => {
                                        return <li key={key2}><span className="fa-li"><i className="fa fa-check"></i></span>{item2.description}</li>
                                    })}
                                </ul>
                            </div> : ""}
                            {item.attributes.apertura.length > 0 ? <div className="attributes-list">
                                <div className="attribute-title">Dispositivi di apertura</div>
                                <ul className="fa-ul">
                                    {item.attributes.apertura.map((item2, key2) => {
                                        return <li key={key2}><span className="fa-li"><i className="fa fa-check"></i></span>{item2.description}</li>
                                    })}
                                </ul>
                            </div> : ""}
                        </div>
                        <div>
                            {item.nextOperationDate !== "" ? <div className="field evidence"><span>Prossimo controllo:</span> {moment(item.nextOperationDate).format("DD/MM/YYYY")}</div> : ""}
                        </div>
                        {item.notes !== "" ? <div className="field"><span>Note:</span> {item.notes}</div> : ""}
                        <div className="buttons">
                            <Link to={{pathname: "/i-miei-interventi", state: {installationId: item.id}}}>
                                <button type="button"><i className="fa fa-briefcase"></i>   Interventi</button>
                            </Link>
                            <Link to={{pathname: "/prodotti/pdf", state: {installation: item}}}>
                                <button type="button"><i className="fa fa-print"></i>   Stampa</button>
                            </Link>
                        </div>
                    </div>;
                })}
            </div>;
        }

        return(
            <div className="user-products-wrapper">
                {productsRender}
                {loadingOnScreen}
            </div>
        );
    }

}