import React, { Component } from 'react';
import Globals from './../globals/globals.js';
import Loading from './../components/Loading.js';
import { toast } from 'react-toastify';
import 'font-awesome/css/font-awesome.min.css';

import  { Redirect, Link } from 'react-router-dom';

export default class EditPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToHome: false,

            isLogged: props.isLogged,
            language: props.language,
            accessToken: props.accessToken,

            oldPassword: "",
            newPassword: "",
            showOldPasswordError: false,
            oldPasswordErrorMessage: "",
            showNewPasswordError: false,
            newPasswordErrorMessage: "",
        }
    }

    componentDidMount(){
        document.title = Globals.titles.editPassword[this.state.language];

        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(props) {
        this.setState({
            language: props.language,
            isLogged: props.isLogged,
        });
        document.title = Globals.titles.editPassword[props.language];
    }

    setInputErrorMessage( field, message ) {
        switch(field) {
            case 'oldPassword':
                this.setState({
                    showOldPasswordError: true,
                    oldPasswordErrorMessage: message
                });
                break;
            case 'newPassword':
                this.setState({
                    showNewPasswordError: true,
                    newPasswordErrorMessage: message
                });
                break;
            default:
                break;
        }
    }

    changePassword() {
        // Reset previous input error
        this.setState({
            showOldPasswordError: false,
            oldPasswordErrorMessage: "",
            showNewPasswordError: false,
            newPasswordErrorMessage: "",
        });

        let oldPassword = this.state.oldPassword;
        let newPassword = this.state.newPassword;

        let fieldsAreOk = true;

        // Check email (mandatory field)
        if ( oldPassword === "" || oldPassword === null || oldPassword === undefined ) {
            this.setInputErrorMessage('oldPassword', Globals.alerts.mandatoryField[this.state.language]);
            fieldsAreOk = false;
        }

        // Check password (mandatory field)
        if ( newPassword === "" || newPassword === null || newPassword === undefined ) {
            this.setInputErrorMessage('newPassword', Globals.alerts.mandatoryField[this.state.language]);
            fieldsAreOk = false;
        }

        // If email or password are empty exit the function
        if ( fieldsAreOk === false ) {
            return;
        }

        // Set the loading state (it's an async action)
        this.setState({
            loading: true
        });

        let accessToken = this.state.accessToken;

        // Try to login
        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=changePassword&access_token=' + accessToken;

        let formdata = new FormData();

        formdata.append("oldPassword", oldPassword);
        formdata.append("newPassword", newPassword);

        fetch( url, {
            method: 'POST',
            body: formdata
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                toast(responseJson.message, {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            } else {
                // Call is ok
                toast("Operazione completata", {
                    autoClose: 4000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    redirectToHome: true,
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            toast(Globals.errors.serverCall[this.state.language], {
                autoClose: 8000,
                className: 'std-black-toast',
                bodyClassName: "std-black-toast-body",
                progressClassName: 'std-black-toast-progress-bar'
            });

            this.setState({
                loading: false,
            });
        });
    }

    render() {
        if ( !this.state.isLogged ) {
            return(
                <Redirect to="/" />
            );
        }

        if (this.state.redirectToHome === true) {
            return <Redirect to='/' />
        }

        let loading;
        if ( this.state.loading === true ) {
            loading = <Loading/>;
        }

        let oldPasswordErrorField;
        if ( this.state.showOldPasswordError === true ) {
            oldPasswordErrorField = <div className="input-error-wrapper">{this.state.oldPasswordErrorMessage}</div>;
        }

        let newPasswordErrorField;
        if ( this.state.showNewPasswordError === true ) {
            newPasswordErrorField = <div className="input-error-wrapper">{this.state.newPasswordErrorMessage}</div>;
        }

        return(
            <div className="edit-password-box">
                <div className="fields-wrapper">
                    <div className="input-wrapper">
                        <input type="password" name="oldPassword" placeholder="Vecchia password" value={this.state.oldPassword} onChange={(e) => this.setState({oldPassword: e.target.value})} />
                    </div>
                    {oldPasswordErrorField}
                    <div className="input-wrapper">
                        <input type="password" name="newPassword" placeholder="Nuova password" value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})} />
                    </div>
                    {newPasswordErrorField}
                    <div className="input-wrapper">
                        <button type="button" onClick={this.changePassword.bind(this)}><i className="fa fa-key"></i><span>Cambia la password</span></button>
                    </div>
                    <Link to="/">
                        <div className="input-wrapper">
                            <button type="button"><i className="fa fa-arrow-left"></i><span>Annulla</span></button>
                        </div>
                    </Link>
                    {loading}
                </div>
            </div>
        );
    }
}