import React, { Component } from 'react';
import logo from './../assets/img/logo.png';
import { Link } from "react-router-dom";

import 'font-awesome/css/font-awesome.min.css';

class TopBar extends Component {

    render() {
        return(
            <header>
                <div className="menu-bar">
                    <div className="top-bar">
                        <div className="logo-wrapper">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

export default TopBar;