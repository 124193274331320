import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import Loading from './../components/Loading.js';

import 'react-table/react-table.css';

export default class AdminMaintenanceDescription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            isAdmin: props.isAdmin,
            language: props.language,
            objectsList: [],
            objectsListMessage: "",

            showFormModal: false,
            formModalTitle: "",
            formModalAction: "",

            formModalFieldId: null,
            formModalFieldTitle: "",
            formModalFieldDescription: "",

            showFormError: false,
            formErrorMessage: "",
        }
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.adminMaintenanceDescription[this.state.language];

        window.scrollTo(0, 0);

        this.getList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            isAdmin:  props.isAdmin,
            language: props.language
        });
        document.title = Globals.titles.adminMaintenanceDescription[props.language];
    }

    getList() {
        // Reset state
        this.setState({
            objectsList: [],
            objectsListMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getMaintenanceDescription&access_token=' + accessToken;

        fetch( url, {
            method: 'GET',
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    objectsList: [],
                    objectsListMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    objectsList: responseJson.maintenanceDescription,
                    objectsListMessage: "",
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                objectsList: [],
                objectsListMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    newObject() {
        this.toggleFormModal("Nuova manutenzione", "newMaintenanceDescription");
    }

    editObject(listPosition) {
        this.toggleFormModal("Modifica manutenzione", "editMaintenanceDescription");

        let object = cloneDeep(this.state.objectsList[listPosition]);

        this.setState({
            formModalFieldId: object.id,
            formModalFieldTitle: object.title,
            formModalFieldDescription: object.description,
        });
    }

    deleteObject(objectId) {
        if (window.confirm('Confermi l\'eliminazione?')) {
            this.setState({
                loading: true,
            });

            // Fetch api for user's orders
            let accessToken = this.state.accessToken;

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=deleteMaintenanceDescription&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("id", objectId);

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });

                    this.getList();
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    toggleFormModal(title, action) {
        let showFormModal = !this.state.showFormModal;

        this.setState({
            showFormModal: showFormModal,
            formModalTitle: title,
            formModalAction: action,

            formModalFieldId: null,
            formModalFieldTitle: "",
            formModalFieldDescription: "",

            showFormError: false,
            formErrorMessage: "",
        });
    }

    setInputErrorMessage( field, message ) {
        switch(field) {
            case 'stdError':
                this.setState({
                    showFormError: true,
                    formErrorMessage: message
                });
                break;
            default:
                break;
        }
    }

    confirmObjectForm() {
        if (window.confirm('Confermi i dati forniti?')) {
            this.setState({
                loading: true,
            });

            // Fetch api
            let accessToken = this.state.accessToken;
            let formAction  = this.state.formModalAction;

            let objectData = {
                id:          this.state.formModalFieldId,
                title:       this.state.formModalFieldTitle,
                description: this.state.formModalFieldDescription,
            };

            if (objectData.title === "") {
                this.setInputErrorMessage('stdError', "Devi indicare il titolo");
                this.setState({
                    loading: false,
                });
                return;
            }

            if (objectData.description === "") {
                this.setInputErrorMessage('stdError', "Devi indicare una descrizione");
                this.setState({
                    loading: false,
                });
                return;
            }

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=' + formAction + '&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("objectData", JSON.stringify(objectData));

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.toggleFormModal();

                    this.setState({
                        loading: false,
                    });

                    this.getList();
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    render() {
        if ( this.state.isLogged !== true || this.state.isAdmin !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        if ( this.state.productsList === null ) {
            return(
                <Loading/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let objectsList = this.state.objectsList;
        let objectsListMessage = this.state.objectsListMessage;
        let objectRender = "";
        if ( objectsListMessage !== "" ) {
            objectRender = <div className="message-wrapper">
                {objectsListMessage}
            </div>;
        } else {
            // Prepare data for the table;
            const columns = [
                {
                    Header: "Titolo",
                    accessor: 'title',
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: " ",
                    accessor: 'editIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
                {
                    Header: " ",
                    accessor: 'deleteIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
            ];
            const data = [];
            for( let c = 0; c < objectsList.length; c++ ){
                // Push data
                data.push( {
                    title: objectsList[c].title,
                    editIcon: <i className="std-hoverable-cursor-pointer fa fa-edit fa-2x" onClick={() => {this.editObject(c)}}></i>,
                    deleteIcon: <i className="std-hoverable-cursor-pointer fa fa-trash fa-2x" onClick={() => {this.deleteObject(objectsList[c].id)}}></i>
                } );
            }

            let tableRows = 5;
            if (objectsList.length > 0) {
                tableRows = objectsList.length;
            }

            // Render Table
            objectRender = <ReactTable
                data={data}
                columns={columns}
                showPagination={false}
                pageSize={tableRows}
                previousText={Globals.reactTableText.previous[this.state.language]}
                nextText={Globals.reactTableText.next[this.state.language]}
                loadingText={Globals.reactTableText.loading[this.state.language]}
                noDataText={Globals.reactTableText.noData[this.state.language]}
                pageText={Globals.reactTableText.page[this.state.language]}
                ofText={Globals.reactTableText.of[this.state.language]}
                rowsText={Globals.reactTableText.rows[this.state.language]}
                pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
                rowsSelectorText={Globals.reactTableText.rowsSelectorText[this.state.language]}
            />;
        }

        let formModal;
        if (this.state.showFormModal === true) {
            let formErrorField;
            if ( this.state.showFormError === true ) {
                formErrorField = <div className="input-wrapper full-width centered"><div className="input-error-wrapper">{this.state.formErrorMessage}</div></div>;
            }

            formModal = <div className="modal">
                <div className="background"></div>
                <div className="body large">
                    <div className="titleBar">
                        {this.state.formModalTitle}
                        <div className="close-button">
                            <i className="fa fa-times" onClick={() => this.toggleFormModal("", "")}></i>
                        </div>
                    </div>
                    <div className="content">
                        {formErrorField}
                        <div className="input-wrapper">
                            <div className="label">Titolo</div>
                            <input type="text" name="title" value={this.state.formModalFieldTitle} onChange={(e) => this.setState({formModalFieldTitle: e.target.value})} />
                        </div>
                        <div className="input-wrapper">
                            <div className="label">Descrizione</div>
                            <textarea className="large" name="description" value={this.state.formModalFieldDescription} onChange={(e) => this.setState({formModalFieldDescription: e.target.value})} />
                        </div>
                        <div className="input-wrapper full-width small">
                            <button type="button" style={{display:"flex", width:"15em", justifyContent:"center", margin:"0 auto", textAlign:"center"}} onClick={() => this.confirmObjectForm()}><span>Conferma</span></button>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return(
            <div className="users-page-wrapper">
                <div className="filters">
                    <div className="filters-wrapper">
                        <div className="filter-wrapper">
                            <div className="filter">
                                <button type="button" onClick={() => this.newObject()}><span>Nuovo</span></button>
                                <span className="title-text"> GESTIONE MANUTENZIONI </span>
                            </div>
                        </div>
                    </div>
                </div>
                {objectRender}
                {loadingOnScreen}
                {formModal}
            </div>
        );
    }

}