import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import Dropzone from "react-dropzone";

import Loading from "./../components/Loading.js";

import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";

import img0 from "./../assets/img/0.png";
import img1 from "./../assets/img/1.png";

import it from "date-fns/locale/it";

registerLocale("it", it);

const defaultUserSelectValue = { value: "", label: "Seleziona un cliente" };

export default class Inspections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      accessToken: props.accessToken,
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      isOperator: props.isOperator,
      language: props.language,
      usersList: [],
      list: [],
      listMessage: "",
      redirect: false,

      showFormModal: false,
      formModalTitle: "",
      formModalAction: "",

      showFilesModal: false,
      filesModalTitle: "Gestione file",
      filesModalInspection: {},
      filesToUpload: [],

      formModalFieldId: null,
      formModalFieldUser: defaultUserSelectValue,
      formModalFieldDate: new Date(),
      formModalFieldInspectionCode: "",
      formModalFieldTitle: "",
      formModalFieldDescription: "",

      showFormError: false,
      formErrorMessage: "",
      optionElaborated: [
        { value: 0, label: "Non Elaborato" },
        { value: 1, label: "Elaborato" },
      ],
      lastcode: "",
      quotationId: "",
    };

    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentWillUnmount() {
    document.body.classList.remove("no-background");
    document.body.classList.remove("page-with-filter-bar");
  }

  componentDidMount() {
    document.body.classList.add("no-background");
    document.body.classList.add("page-with-filter-bar");

    document.title = Globals.titles.inspections[this.state.language];

    window.scrollTo(0, 0);

    this.getList();
  }

  componentWillReceiveProps(props) {
    this.setState({
      isLogged: props.isLogged,
      isAdmin: props.isAdmin,
      isOperator: props.isOperator,
      language: props.language,
    });
    document.title = Globals.titles.inspections[props.language];
  }

  getList(specialAction) {
    // Reset state
    this.setState({
      list: [],
      listMessage: "",
      loading: true,
    });

    // Fetch api for user's orders
    let accessToken = this.state.accessToken;

    let url =
      Globals.baseUrl +
      "?lang=" +
      this.state.language +
      "&a=getInspections&access_token=" +
      accessToken;

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          // Error during the call - handle message
          this.setState({
            list: [],
            usersList: [],
            listMessage: responseJson.message,
            loading: false,
          });
        } else {
          // Call is ok, store ordersData in state
          this.setState({
            list: responseJson.inspections,
            usersList: responseJson.users,
            listMessage: "",
            loading: false,
            lastCode: responseJson.lastcode,
          });

          if (undefined !== specialAction) {
            switch (specialAction) {
              case "reloadFiles":
                let actualFilesElement = this.state.filesModalInspection;

                let refreshedElement = cloneDeep(
                  this.state.list.find((element) => {
                    return (
                      parseInt(element.id) === parseInt(actualFilesElement.id)
                    );
                  })
                );

                this.setState({
                  filesModalInspection: refreshedElement,
                  loading: false,
                });
                break;
              default:
                break;
            }
          }
        }
      })
      .catch((error) => {
        // Communication error - handle message
        this.setState({
          list: [],
          usersList: [],
          listMessage: Globals.errors.serverCall[this.state.language],
          loading: false,
        });
      });
  }

  handleOnDrop(files) {
    this.setState({
      loading: true,
    });

    // Fetch api
    let accessToken = this.state.accessToken;

    let url =
      Globals.baseUrl +
      "?lang=" +
      this.state.language +
      "&a=uploadInspectionFile&access_token=" +
      accessToken;

    const formdata = new FormData();

    formdata.append("id", this.state.filesModalInspection.id);

    files.forEach((file) => {
      formdata.append("uploadedFiles[]", file, file.name);
    });

    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          // Error during the call - handle message
          toast(responseJson.message, {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        } else {
          // Call is ok
          toast("Operazione completata", {
            autoClose: 4000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });

          //this.toggleFilesHandler();
          this.getList("reloadFiles");
        }
      })
      .catch((error) => {
        // Communication error - handle message
        toast(Globals.errors.serverCall[this.state.language], {
          autoClose: 8000,
          className: "std-black-toast",
          bodyClassName: "std-black-toast-body",
          progressClassName: "std-black-toast-progress-bar",
        });

        this.setState({
          loading: false,
        });
      });
  }

  createPdf(id) {
    // Fetch api for user's orders
    let accessToken = this.state.accessToken;

    let url =
      Globals.baseUrl +
      "?lang=" +
      this.state.language +
      "&a=inspectionCreatePdf&access_token=" +
      accessToken +
      "&id=" +
      id;

    window.open(url, "name", "titlebar=no").focus();
  }

  removeFile(fileId) {
    if (window.confirm("Confermi l'eliminazione?")) {
      this.setState({
        loading: true,
      });

      // Fetch api
      let accessToken = this.state.accessToken;

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=deleteInspectionFile&access_token=" +
        accessToken;

      const formdata = new FormData();

      formdata.append("fileId", fileId);

      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast("Operazione completata", {
              autoClose: 4000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            //this.toggleFilesHandler();
            this.getList("reloadFiles");

            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    }
  }

  openFilesHandler(listPosition) {
    this.toggleFilesHandler();

    let report = cloneDeep(this.state.list[listPosition]);
    console.log(report);
    this.setState({
      filesModalInspection: report,
    });
  }

  confirmOperation(id, date, company, code, title) {
    if (
      window.confirm(
        "Desideri devolvere il sopralluogo " +
          code +
          title +
          " dell' intervento confermato ?"
      )
    ) {
      this.setState({
        loading: true,
      });

      // Fetch api for user's orders
      let accessToken = this.state.accessToken;

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=confirmOperation&access_token=" +
        accessToken;

      let formdata = new FormData();

      let formData = {
        id: id,
        date: this.formatDate(date),
        company: company,
        code: code,
        title: title,
      };

      formdata.append("formData", JSON.stringify(formData));

      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast("Operazione completata", {
              autoClose: 4000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });

            this.getList();
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    }
  }

  toQuotation(id, date, company, code, title) {
    if (window.confirm("Devolvi questo sopralluogo in preventivo ?")) {
      this.setState({
        loading: true,
      });

      // Fetch api for user's orders
      let accessToken = this.state.accessToken;

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=toQuotation&access_token=" +
        accessToken;

      let formdata = new FormData();

      let formData = {
        id: id,
        date: this.formatDate(date),
        company: company,
        code: code,
        title: title,
      };

      formdata.append("formData", JSON.stringify(formData));

      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast(
              "Operazione completata, sopralluogo devoluto in preventivo con numero offerta :" +
                responseJson.offerFinalValue,
              {
                autoClose: 4000,
                className: "std-black-toast",
                bodyClassName: "std-black-toast-body",
                progressClassName: "std-black-toast-progress-bar",
              }
            );

            if (
              window.confirm("Vuoi modificare questo preventivo appena creato?")
            ) {
              this.setState({
                redirect: true,
                quotationId: responseJson.quotationId,
              });
            }

            this.setState({
              loading: false,
            });

            this.getList();
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    }
  }

  toggleFilesHandler() {
    let showFilesModal = !this.state.showFilesModal;

    this.setState({
      showFilesModal: showFilesModal,
      filesModalInspection: {},
      filesToUpload: [],
    });
  }

  newInspection() {
    this.toggleFormModal("Nuovo sopralluogo", "newInspection");
    this.getLastInspectionCode();
  }

  editInspection(listPosition) {
    this.toggleFormModal("Modifica sopralluogo", "editInspection");

    let inspection = cloneDeep(this.state.list[listPosition]);

    let userSelected = {
      value: inspection.user.id,
      label: inspection.user.company,
    };

    let date = inspection.date;
    if (date !== null && date !== undefined) {
      this.setState({
        formModalFieldDate: new Date(date),
      });
    }
    this.getLastInspectionCode();

    this.setState({
      formModalFieldId: inspection.id,
      formModalFieldUser: userSelected,
      formModalFieldInspectionName: inspection.inspectionsName,
      formModalFieldInspectionCode: inspection.inspection_code,
      formModalFieldTitle: inspection.title,
      formModalFieldDescription: inspection.description,
    });
  }

  deleteInspection(id) {
    if (window.confirm("Confermi l'eliminazione?")) {
      this.setState({
        loading: true,
      });

      // Fetch api for user's orders
      let accessToken = this.state.accessToken;

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=deleteInspection&access_token=" +
        accessToken;

      let formdata = new FormData();

      formdata.append("id", id);

      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast("Operazione completata", {
              autoClose: 4000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });

            this.getList();
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    }
  }

  getLastInspectionCode() {
    // Fetch api for user's orders
    let accessToken = this.state.accessToken;

    let url =
      Globals.baseUrl +
      "?lang=" +
      this.state.language +
      "&a=getLastInspections&access_token=" +
      accessToken;

    fetch(url, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          // Error during the call - handle message
          toast(responseJson.message, {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });
        } else {
          this.setState({
            lastCode: responseJson.lastcode,
          });
        }
      })
      .catch((error) => {
        // Communication error - handle message
        toast(Globals.errors.serverCall[this.state.language], {
          autoClose: 8000,
          className: "std-black-toast",
          bodyClassName: "std-black-toast-body",
          progressClassName: "std-black-toast-progress-bar",
        });

        this.setState({
          loading: false,
        });
      });
  }

  toggleFormModal(title, action) {
    let showFormModal = this.state.showFormModal;

    if (showFormModal === true) {
      showFormModal = false;
    } else {
      showFormModal = true;
    }

    this.setState({
      showFormModal: showFormModal,
      formModalTitle: title,
      formModalAction: action,

      formModalFieldId: null,
      formModalFieldUser: defaultUserSelectValue,
      formModalFieldDate: new Date(),
      formModalFieldInspectionName: "",
      formModalFieldInspectionCode: "",
      formModalFieldTitle: "",
      formModalFieldDescription: "",

      showFormError: false,
      formErrorMessage: "",
      lastCode: "",
    });
  }

  setInputErrorMessage(field, message) {
    switch (field) {
      case "stdError":
        this.setState({
          showFormError: true,
          formErrorMessage: message,
        });
        break;
      default:
        break;
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  confirmForm() {
    if (window.confirm("Confermi i dati forniti?")) {
      this.setState({
        loading: true,
      });

      // Fetch api
      let accessToken = this.state.accessToken;
      let formAction = this.state.formModalAction;

      let formData = {
        id: this.state.formModalFieldId,
        usersId: this.state.formModalFieldUser.value,
        date: this.formatDate(this.state.formModalFieldDate),
        inspection_code: this.state.formModalFieldInspectionCode,
        title: this.state.formModalFieldTitle,
        description: this.state.formModalFieldDescription,
      };

      if (formData.usersId === "") {
        this.setInputErrorMessage("stdError", "Devi selezionare un utente");
        this.setState({
          loading: false,
        });
        return;
      }

      if (formData.date === "") {
        this.setInputErrorMessage("stdError", "Devi indicare una data");
        this.setState({
          loading: false,
        });
        return;
      }

      if (formData.title === "") {
        this.setInputErrorMessage("stdError", "Devi indicare il titolo");
        this.setState({
          loading: false,
        });
        return;
      }

      let url =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=" +
        formAction +
        "&access_token=" +
        accessToken;

      let formdata = new FormData();

      formdata.append("formData", JSON.stringify(formData));

      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === true) {
            // Error during the call - handle message
            toast(responseJson.message, {
              autoClose: 8000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });
          } else {
            // Call is ok
            toast("Operazione completata", {
              autoClose: 4000,
              className: "std-black-toast",
              bodyClassName: "std-black-toast-body",
              progressClassName: "std-black-toast-progress-bar",
            });

            this.setState({
              loading: false,
            });

            this.toggleFormModal();
            this.getList();
          }
        })
        .catch((error) => {
          // Communication error - handle message
          toast(Globals.errors.serverCall[this.state.language], {
            autoClose: 8000,
            className: "std-black-toast",
            bodyClassName: "std-black-toast-body",
            progressClassName: "std-black-toast-progress-bar",
          });

          this.setState({
            loading: false,
          });
        });
    }
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  handleUserChange(element) {
    this.setState({
      formModalFieldUser: element,
    });
  }

  handleDateChange(date) {
    this.setState({
      formModalFieldDate: date,
    });
  }

  render() {
    if (
      this.state.isLogged !== true ||
      (this.state.isAdmin !== true && this.state.isOperator !== true)
    ) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    if (this.state.redirect === true) {
      return (
        <Redirect
          to={{
            pathname: "/preventivi",
            state: {
              referrer: true,
              quotationId: this.state.quotationId,
              fromInspection: true,
            },
          }}
        />
      );
    }

    let loadingOnScreen;
    if (this.state.loading === true) {
      loadingOnScreen = <Loading />;
    }

    let list = this.state.list;
    let listMessage = this.state.listMessage;
    let bodyRender = "";
    if (listMessage !== "") {
      bodyRender = <div className="message-wrapper">{listMessage}</div>;
    } else {
      // Prepare data for the table;
      const columns = [
        {
          Header: "Elaborato",
          accessor: "elaborated",
          headerStyle: { textAlign: "center" },
          width: 150,
          Cell: (row) => (
            <div style={{ textAlign: "center" }}>
              {" "}
              {row.value === "Yes" ? (
                <img src={img1} alt={"Elaborato"} />
              ) : (
                <img src={img0} alt={"Non-Elaborato"} />
              )}{" "}
            </div>
          ),
          filterable: true,
          filterMethod: (filter, row) => {
            if (filter.value === "Tutti") {
              return true;
            }
            if (filter.value === "Yes") {
              return row[filter.id] === "Yes";
            }
            if (filter.value === "No") {
              return row[filter.id] === "No";
            }
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%", textAlign: "center" }}
              value={filter ? filter.value : "Tutti"}
            >
              <option value="Tutti">Tutti</option>
              <option value="Yes">Elaborato</option>
              <option value="No">Non Elaborato</option>
            </select>
          ),
        },
        {
          Header: "Data",
          accessor: "date",
          Cell: (c) => (
            <span>{moment(c.original.date).format("DD/MM/YYYY")}</span>
          ),
          filterable: true,
          filterMethod: (filter, row) => {
            return row[filter.id]
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          },
        },
        {
          Header: "Utente",
          accessor: "user",
          style: { overflow: "visible", whiteSpace: "normal" },
          filterable: true,
          filterMethod: (filter, row) => {
            return row[filter.id]
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          },
        },
        {
          Header: "Codice",
          accessor: "inspection_code",
          style: { overflow: "visible", whiteSpace: "normal" },
          filterable: true,
          filterMethod: (filter, row) => {
            return row[filter.id]
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          },
        },
        {
          Header: "Titolo",
          accessor: "title",
          style: { overflow: "visible", whiteSpace: "normal" },
          filterable: true,
          filterMethod: (filter, row) => {
            return row[filter.id]
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          },
          Cell: (c) => (
            <span>
              {c.original.title.length > 50
                ? c.original.title.substring(0, 50) + " [...] "
                : c.original.title}
            </span>
          ),
        },
        {
          Header: "n°allegati",
          accessor: "attachments",
          width: 120,
          Cell: (row) => (
            <div style={{ textAlign: "center", fontSize: 15 }}>{row.value}</div>
          ),
        },
        {
          Header: " ",
          accessor: "filesIcon",
          width: 50,
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
        },
        {
          Header: " ",
          accessor: "confirmedOperationIcon",
          width: 50,
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
        },
        {
          Header: " ",
          accessor: "toQuotationIcon",
          width: 50,
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
        },
        {
          Header: " ",
          accessor: "printIcon",
          width: 50,
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
        },
        {
          Header: " ",
          accessor: "editIcon",
          width: 50,
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
        },
        {
          Header: " ",
          accessor: "deleteIcon",
          width: 50,
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
        },
      ];
      const data = [];
      for (let c = 0; c < list.length; c++) {
        let ElaboratedValue = "No";

        if (list[c].isInQuotation === true) {
          ElaboratedValue = "Yes";
        }

        // Push data
        data.push({
          elaborated: ElaboratedValue,
          date: list[c].date,
          user: list[c].user.company,
          inspection_code: list[c].inspection_code || " ",
          title: list[c].title,
          filesIcon: (
            <i
              className="std-hoverable-cursor-pointer fa fa-file-pdf-o fa-2x"
              onClick={() => {
                this.openFilesHandler(c);
              }}
            ></i>
          ),
          attachments: list[c].files.length,
          confirmedOperationIcon: (
            <i
              className="std-hoverable-cursor-pointer fa fa-calendar-check-o -o fa-2x"
              onClick={() => {
                this.confirmOperation(
                  list[c].id,
                  list[c].date,
                  list[c].user.id,
                  list[c].inspection_code,
                  list[c].title
                );
              }}
            ></i>
          ),
          toQuotationIcon: (
            <i
              className="std-hoverable-cursor-pointer fa fa fa-envelope-open-o -o fa-2x"
              onClick={() => {
                this.toQuotation(
                  list[c].id,
                  list[c].date,
                  list[c].user.id,
                  list[c].inspection_code,
                  list[c].title
                );
              }}
            ></i>
          ),
          OperationIcon: (
            <i
              className="std-hoverable-cursor-pointer fa fa-calendar-check-o -o fa-2x"
              onClick={() => {
                this.confirmOperation(
                  list[c].id,
                  list[c].date,
                  list[c].user.id,
                  list[c].inspection_code,
                  list[c].title
                );
              }}
            ></i>
          ),
          printIcon: (
            // <Link
            // 	to={{
            // 		pathname: "/sopralluogo/pdf",
            // 		state: { inspection: list[c] },
            // 	}}>
            // 	<i className='std-hoverable-cursor-pointer fa fa-print fa-2x'></i>
            // </Link>
            <i
              className="std-hoverable-cursor-pointer fa fa-print fa-2x"
              onClick={() => this.createPdf(list[c].id)}
            ></i>
          ),
          editIcon: (
            <i
              className="std-hoverable-cursor-pointer fa fa-edit fa-2x"
              onClick={() => {
                this.editInspection(c);
              }}
            ></i>
          ),
          deleteIcon: (
            <i
              className="std-hoverable-cursor-pointer fa fa-trash fa-2x"
              onClick={() => {
                this.deleteInspection(list[c].id);
              }}
            ></i>
          ),
        });
      }
      // Render Table
      bodyRender = (
        <ReactTable
          data={data}
          columns={columns}
          page={this.state.page}
          onPageChange={(page) => this.setState({ page })}
          pageSizeOptions={[50, 100, 200]}
          defaultPageSize={50}
          previousText={Globals.reactTableText.previous[this.state.language]}
          nextText={Globals.reactTableText.next[this.state.language]}
          loadingText={Globals.reactTableText.loading[this.state.language]}
          noDataText={Globals.reactTableText.noData[this.state.language]}
          pageText={Globals.reactTableText.page[this.state.language]}
          ofText={Globals.reactTableText.of[this.state.language]}
          rowsText={Globals.reactTableText.rows[this.state.language]}
          pageJumpText={
            Globals.reactTableText.pageJumpText[this.state.language]
          }
          rowsSelectorText={
            Globals.reactTableText.rowsSelectorText[this.state.language]
          }
        />
      );
    }

    let filesModal;
    if (this.state.showFilesModal === true) {
      let apiBaseGetUploadedFileUrl =
        Globals.baseUrl +
        "?lang=" +
        this.state.language +
        "&a=getUploadedFile&access_token=" +
        this.state.accessToken +
        "&type=inspections&fileId=";

      filesModal = (
        <div className="modal">
          <div className="background"></div>
          <div className="body large">
            <div className="titleBar">
              {this.state.filesModalTitle}{" "}
              <span className="modalTitleBarAndClient">
                {" "}
				s{this.state.filesModalInspection.inspection_code || ""}-{" "}
                {this.state.filesModalInspection.title || ""} -{" "}
                {this.state.filesModalInspection.user.company || ""}{" "}
              </span>
              <div className="close-button">
                <i
                  className="fa fa-times"
                  onClick={() => this.toggleFilesHandler()}
                ></i>
              </div>
            </div>
            <div className="content">
              <Dropzone
                onDrop={this.handleOnDrop}
                accept="application/pdf, image/*"
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className={
                        "drag-and-drop-wrapper " +
                        (isDragActive ? "active" : "")
                      }
                    >
                      <input {...getInputProps()} />
                      <p>
                        Trascina qui i tuoi file o fai click <span>qui</span>{" "}
                        per selezionarli
                        <span className="small">
                          (File accettati: .pdf, .jpeg, .jpg, .png, .gif)
                        </span>
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              {this.state.filesModalInspection.files !== undefined ? (
                <div className="uploaded-files-wrapper">
                  {this.state.filesModalInspection.files.map((item, key) => {
                    return (
                      <div key={key} className="file">
                        <div className="icon">
                          {item.url.split(".").pop() === "jpg" ||
                          item.url.split(".").pop() === "png" ||
                          item.url.split(".").pop() === "jpeg" ? (
                            <img
                              src={item.url}
                              className="previewImage"
                              alt="sopralluogo"
                            />
                          ) : (
                            <div className="pdfIcon">
                              <i className="fa fa-file-pdf-o" />
                            </div>
                          )}
                        </div>
                        <div className="name">{item.title}</div>
                        <div className="buttons">
                          <a
                            href={apiBaseGetUploadedFileUrl + item.id}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button type="button">
                              <i className="fa fa-eye"></i>
                            </button>
                          </a>
                          <button
                            type="button"
                            onClick={() => this.removeFile(item.id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    }

    let formModal;
    if (this.state.showFormModal === true) {
      // Select utenti
      let formModalFieldUserSelectOptions = [defaultUserSelectValue];
      let usersList = this.state.usersList;
      for (let c = 0; c < usersList.length; c++) {
        let optionValue = {
          value: usersList[c].id,
          label: usersList[c].company,
        };
        formModalFieldUserSelectOptions.push(optionValue);
      }

      let formErrorField;
      if (this.state.showFormError === true) {
        formErrorField = (
          <div className="input-wrapper full-width centered">
            <div className="input-error-wrapper">
              {this.state.formErrorMessage}
            </div>
          </div>
        );
      }

      formModal = (
        <div className="modal">
          <div className="background"></div>
          <div className="body large">
            <div className="titleBar">
              {this.state.formModalTitle}
              <div className="close-button">
                <i
                  className="fa fa-times"
                  onClick={() => this.toggleFormModal("", "")}
                ></i>
              </div>
            </div>
            <div className="content three-sides-form">
              {formErrorField}
              <div className="input-wrapper">
                <div className="label">Cliente</div>
                <Select
                  value={this.state.formModalFieldUser}
                  onChange={this.handleUserChange}
                  options={formModalFieldUserSelectOptions}
                />
              </div>
              <div className="input-wrapper">
                <div className="label">Data</div>
                <DatePicker
                  locale={this.state.language}
                  selected={this.state.formModalFieldDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="input-wrapper">
                <div className="label">Codice sopralluogo</div>
                <input
                  type="text"
                  name="inspection_code"
                  value={this.state.formModalFieldInspectionCode}
                  onChange={(e) =>
                    this.setState({
                      formModalFieldInspectionCode: e.target.value,
                    })
                  }
                />
                {this.state.formModalTitle === "Nuovo sopralluogo" ? (
                  <span>
                    {" "}
                    Ultimo codice registrato : {this.state.lastCode}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="input-wrapper">
                <div className="label">TITOLO</div>
                <input
                  type="text"
                  name="title"
                  value={this.state.formModalFieldTitle}
                  onChange={(e) =>
                    this.setState({
                      formModalFieldTitle: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-wrapper full-width">
                <div className="label">Descrizione</div>
                <textarea
                  name="description"
                  className="medium"
                  value={this.state.formModalFieldDescription}
                  onChange={(e) =>
                    this.setState({
                      formModalFieldDescription: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input-wrapper full-width small">
                <button type="button" onClick={() => this.confirmForm()}>
                  <span>Conferma</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="users-page-wrapper">
        <div className="filters">
          <div className="filters-wrapper">
            <div className="filter-wrapper">
              <div className="filter">
                <button type="button" onClick={() => this.newInspection()}>
                  <span>Nuovo</span>
                </button>
                <span className="title-text"> GESTIONE SOPRALLUOGHI </span>
              </div>
            </div>
          </div>
        </div>
        {bodyRender}
        {loadingOnScreen}
        {formModal}
        {filesModal}
      </div>
    );
  }
}
