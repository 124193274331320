import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import cloneDeep from 'lodash/cloneDeep';

import Loading from './../components/Loading.js';

import "react-datepicker/dist/react-datepicker.css";
import 'react-table/react-table.css';

import it from "date-fns/locale/it";
import moment from "moment";

registerLocale("it", it);

const defaultInstallationSelectValue = { value: "", label: "Seleziona un'installazione" };
const defaultTypeSelectValue = {value: "CONTROLLO ORDINARIO", label: "CONTROLLO ORDINARIO"};

const formModalFieldtypeSelectOptions = [{value: "CONTROLLO ORDINARIO", label: "CONTROLLO ORDINARIO"}, {value: "CONTROLLO STRAORDINARIO", label: "CONTROLLO STRAORDINARIO"}];

export default class AdminOperations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            isAdmin: props.isAdmin,
            language: props.language,
            operationsList: [],
            installationsList: [],
            operationsListMessage: "",

            formModalFieldId: null,
            formModalFieldInstallation: defaultInstallationSelectValue,
            formModalFieldDate: new Date(),
            formModalFieldType: defaultTypeSelectValue,
            formModalFieldNotes: "",

            showFormModal: false,
            formModalTitle: "",
            formModalAction: "",

            showErrorMessage: false,
            formErrorMessage: "",
        }

        this.handleInstallationChange = this.handleInstallationChange.bind(this);
        this.handleDateChange         = this.handleDateChange.bind(this);
        this.handleTypeChange         = this.handleTypeChange.bind(this);
    }

    handleTypeChange(element) {
        this.setState({
            formModalFieldType: element
        });
    }

    handleDateChange(date) {
        this.setState({
            formModalFieldDate: date
        });
    }

    handleInstallationChange(element) {
        this.setState({
            formModalFieldInstallation: element
        });
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.adminOperations[this.state.language];

        window.scrollTo(0, 0);

        this.getOperationsList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            isAdmin:  props.isAdmin,
            language: props.language
        });
        document.title = Globals.titles.adminOperations[props.language];
    }

    getOperationsList() {
        // Reset state
        this.setState({
            loading: true,
            operationsList: [],
            installationsList: [],
            operationsListMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getOperations&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    loading: false,
                    operationsList: [],
                    installationsList: [],
                    operationsListMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    loading: false,
                    installationsList: responseJson.installations,
                    operationsList: responseJson.operations,
                    installationsListMessage: "",
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                loading: false,
                operationsList: [],
                installationsList: [],
                operationsListMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    newOperation() {
        this.toggleFormModal("Nuovo intervento", "newOperation");
    }

    editOperation(listPosition) {
        this.toggleFormModal("Modifica intervento", "editOperation");

        let operation = cloneDeep(this.state.operationsList[listPosition]);
        
        let installationLabel = operation.user.company + ' - ' + operation.product.name + ' (' + operation.installationType.descriprion + ') - ' + operation.installation.site;

        let installationSelected = {value: operation.installation.id, label: installationLabel};

        let date = operation.date;
        if (date !== null && date !== undefined) {
            this.setState({
                formModalFieldDate: new Date(date),
            });
        }

        this.setState({
            formModalFieldId: operation.id,
            formModalFieldInstallation: installationSelected,
            formModalFieldType: {value: operation.type, label: operation.type},
            formModalFieldNotes: operation.notes,
        });
    }

    toggleFormModal(title, action) {
        let showFormModal = !this.state.showFormModal;

        this.setState({
            showFormModal: showFormModal,
            formModalTitle: title,
            formModalAction: action,

            formModalFieldId: null,
            formModalFieldInstallation: defaultInstallationSelectValue,
            formModalFieldDate: new Date(),
            formModalFieldType: defaultTypeSelectValue,
            formModalFieldNotes: "",

            showErrorMessage: false,
            formErrorMessage: "",
        });
    }

    setInputErrorMessage( field, message ) {
        switch(field) {
            case 'general':
                this.setState({
                    showErrorMessage: true,
                    formErrorMessage: message
                });
                break;
            default:
                break;
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    confirmOperationForm() {
        if (window.confirm('Confermi i dati forniti?')) {
            this.setState({
                loading: true,
            });

            // Fetch api
            let accessToken = this.state.accessToken;
            let formAction  = this.state.formModalAction;

            let operationData = {
                id:             this.state.formModalFieldId,
                installationId: this.state.formModalFieldInstallation.value,
                date:           this.formatDate( this.state.formModalFieldDate ),
                type:           this.state.formModalFieldType.value,
                notes:          this.state.formModalFieldNotes,
            };

            if (operationData.installationId === "") {
                this.setInputErrorMessage('general', "Devi selezionare un'installazione");
                this.setState({
                    loading: false,
                });
                return;
            }

            if (operationData.type === "") {
                this.setInputErrorMessage('general', "Devi selezionare una tipologia di controllo");
                this.setState({
                    loading: false,
                });
                return;
            }

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=' + formAction + '&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("operationData", JSON.stringify(operationData));

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.toggleFormModal();

                    this.setState({
                        loading: false,
                    });

                    this.getOperationsList();
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    deleteOperation(operationId) {
        if (window.confirm('Confermi l\'eliminazione?')) {
            this.setState({
                loading: true,
            });

            // Fetch api for user's orders
            let accessToken = this.state.accessToken;

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=deleteOperation&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("operationId", operationId);

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });

                    this.getOperationsList();
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    render() {
        if ( this.state.isLogged !== true || this.state.isAdmin !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        if ( this.state.installationsList === null ) {
            return(
                <Loading/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let operationsList = this.state.operationsList;
        let operationsListMessage = this.state.operationsListMessage;
        let operationsRender  = "";
        if ( operationsListMessage !== "" ) {
            operationsRender = <div className="message-wrapper">
                {operationsListMessage}
            </div>;
        } else {
            // Prepare data for the table;
            const columns = [
                {
                    Header: "Data",
                    accessor: 'date',
                    Cell: c => <span>{moment(c.original.date).format("DD/MM/YYYY")}</span>,
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Utente",
                    accessor: 'user',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Prodotto",
                    accessor: 'product',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Tipologia",
                    accessor: 'type',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Ubicazione",
                    accessor: 'site',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Utilizzatore",
                    accessor: 'installationUser',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Intervento",
                    accessor: 'operation',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: " ",
                    accessor: 'editIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
                {
                    Header: " ",
                    accessor: 'deleteIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
            ];
            const data = [];
            for( let c = 0; c < operationsList.length; c++ ){
                // Push data
                data.push( {
                    date: operationsList[c].date,
                    user: operationsList[c].user.company,
                    product: operationsList[c].product.name,
                    type: operationsList[c].installationType.description,
                    site: operationsList[c].installation.site,
                    installationUser: operationsList[c].installation.installationUser,
                    operation: operationsList[c].type,
                    editIcon: <i className="std-hoverable-cursor-pointer fa fa-edit fa-2x" onClick={() => {this.editOperation(c)}}></i>,
                    deleteIcon: <i className="std-hoverable-cursor-pointer fa fa-trash fa-2x" onClick={() => {this.deleteOperation(operationsList[c].id)}}></i>
                } );
            }
            // Render Table
            operationsRender = <ReactTable
                data={data}
                columns={columns}
                pageSizeOptions={[50,100,200]}
                defaultPageSize={50}
                previousText={Globals.reactTableText.previous[this.state.language]}
                nextText={Globals.reactTableText.next[this.state.language]}
                loadingText={Globals.reactTableText.loading[this.state.language]}
                noDataText={Globals.reactTableText.noData[this.state.language]}
                pageText={Globals.reactTableText.page[this.state.language]}
                ofText={Globals.reactTableText.of[this.state.language]}
                rowsText={Globals.reactTableText.rows[this.state.language]}
                pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
                rowsSelectorText={Globals.reactTableText.rowsSelectorText[this.state.language]}
            />;
        }

        let formModal;
        if (this.state.showFormModal === true) {
            // Select installations
            let formModalFieldInstallationOptions = [defaultInstallationSelectValue];
            let installationsList = this.state.installationsList;
            for( let c = 0; c < installationsList.length; c++ ){
                let installationLabel = installationsList[c].user.company + ' - ' + installationsList[c].product.name + ' (' + installationsList[c].type.description + ') - ' + installationsList[c].site + ' ' + installationsList[c].installationUser;
                let optionValue = { value: installationsList[c].id, label: installationLabel };
                formModalFieldInstallationOptions.push(optionValue);
            }

            let formErrorField;
            if ( this.state.showErrorMessage === true ) {
                formErrorField = <div className="input-wrapper full-width centered"><div className="input-error-wrapper">{this.state.formErrorMessage}</div></div>;
            }

            formModal = <div className="modal">
                <div className="background"></div>
                <div className="body large">
                    <div className="titleBar">
                        {this.state.formModalTitle}
                        <div className="close-button">
                            <i className="fa fa-times" onClick={() => this.toggleFormModal("", "")}></i>
                        </div>
                    </div>
                    <div className="content three-sides-form">
                        {formErrorField}
                        <div className="input-wrapper">
                            <div className="label">Installazione</div>
                            <Select
                                value={this.state.formModalFieldInstallation}
                                onChange={this.handleInstallationChange}
                                options={formModalFieldInstallationOptions}
                            />
                        </div>
                        <div className="input-wrapper">
                            <div className="label">Data</div>
                            <DatePicker
                                locale={this.state.language}
                                selected={this.state.formModalFieldDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.handleDateChange}
                            />
                        </div>
                        <div className="input-wrapper">
                            <div className="label">Tipologia</div>
                            <Select
                                value={this.state.formModalFieldType}
                                onChange={this.handleTypeChange}
                                options={formModalFieldtypeSelectOptions}
                            />
                        </div>
                        <div className="input-wrapper full-width">
                            <div className="label">Note</div>
                            <textarea name="notes" value={this.state.formModalFieldNotes} onChange={(e) => this.setState({formModalFieldNotes: e.target.value})} />
                        </div>
                        <div className="input-wrapper full-width small">
                            <button type="button" onClick={() => this.confirmOperationForm()}><span>Conferma</span></button>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return(
            <div className="users-page-wrapper">
                <div className="filters">
                    <div className="filters-wrapper">
                        <div className="filter-wrapper">
                            <div className="filter">
                                <button type="button" onClick={() => this.newOperation()}><span>Nuovo</span></button>
                                <span className="title-text"> GESTIONE INTERVENTI </span>
                            </div>
                        </div>
                    </div>
                </div>
                {operationsRender}
                {loadingOnScreen}
                {formModal}
            </div>
        );
    }

}
