import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Redirect } from "react-router-dom";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';

Font.register({
    family: "TitilliumWeb",
    src: "https://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fdMPmCKZRbrw.ttf",
});

Font.register({
    family: "TitilliumWebBold",
    src: "https://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffHjDGItzZpabuWI.ttf"
});

class InspectionPDF extends Component {
    constructor(props) {
        super(props);

        if (props.location !== undefined) {
            if (props.location.state !== undefined) {
                this.state = {
                    inspection: props.location.state.inspection,
                }
            }
        }
    }

    componentDidMount(){
        document.title = "CRM Officina Gelso - PDF";
    }

    componentWillReceiveProps(props) {
        this.setState({
            inspection: props.location.state.inspection,
        });

        document.title = "CRM Officina Gelso - PDF";
    }

    render() {
        if (this.props.location === undefined) {
            return (
                <Redirect to={{ pathname: '/sopralluoghi' }}/>
            );
        }

        if (this.props.location.state === undefined) {
            return (
                <Redirect to={{ pathname: '/sopralluoghi' }}/>
            );
        }

        if (this.props.location.state.inspection === undefined) {
            return (
                <Redirect to={{ pathname: '/sopralluoghi' }}/>
            );
        }

        let inspection = this.state.inspection;


        let text = inspection.description;        


        let n = text.length;
        let secondText = "";

        
        if (n > 1200){
            secondText = text.slice(791,n)
            text = text.slice(0,791);
        }


        const styles = StyleSheet.create({
            pdfViewer: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
            },
            page: {
                backgroundColor: '#FFFFFF',
                padding: 40,
            },
            pageHeader: {
                flexDirection: 'row',
            },
            title: {
                fontSize: 20,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'left',
            },
            description: {
                fontSize: 16,
                marginTop: 30,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'left',

            },
            secondText:{
                fontSize: 16,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'left',

            }
        });

        return(
            <PDFViewer style={styles.pdfViewer}>
                <Document>
                    <Page size="A4" style={styles.page} wrap>
                        <View>
                            <Text style={styles.title}>{inspection.user.company}</Text>
                        </View>
                        <View>
                            <Text style={styles.title}> {inspection.inspection_code} {inspection.title} </Text>
                        </View>
                        <View>
                            <Text style={styles.description}>{text}</Text>
                            <Text style={styles.secondText}>{secondText}</Text>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        );
    }
}

export default withRouter(InspectionPDF);