import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import img0 from './../assets/img/0.png';
import img1 from './../assets/img/1.png';

import Loading from './../components/Loading.js';
import Switch from "./../components/Switch";

import 'react-table/react-table.css';

export default class AdminProducts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            isAdmin: props.isAdmin,
            language: props.language,
            productsList: null,
            productsListMessage: "",

            showFormModal: false,
            formModalTitle: "",
            formModalAction: "",

            formModalFieldId: null,
            formModalFieldActive: false,
            formModalFieldType: "standard",
            formModalFieldName: "",
            formModalFieldNotes: "",
            formModalFieldAttributes: [],

            showFormError: false,
            formErrorMessage: "",
        }

        this.handleProductActiveChange = this.handleProductActiveChange.bind(this);
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.adminProducts[this.state.language];

        window.scrollTo(0, 0);

        this.getProductsList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            isAdmin:  props.isAdmin,
            language: props.language
        });
        document.title = Globals.titles.adminProducts[props.language];
    }

    getProductsList() {
        // Reset state
        this.setState({
            productsList: null,
            productsListMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getProducts&access_token=' + accessToken;

        let type = this.state.formModalFieldType;

        let formdata = new FormData();

        formdata.append("type", type);

        fetch( url, {
            method: 'POST',
            body: formdata
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    productsList: [],
                    productsListMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    productsList: responseJson.products,
                    productsListMessage: "",
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                productsList: [],
                productsListMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    newProduct() {
        this.toggleFormModal("Nuovo prodotto", "newProduct");
    }

    editProduct(listPosition) {
        this.toggleFormModal("Modifica prodotto", "editProduct");

        let product = cloneDeep(this.state.productsList[listPosition]);

        let product_active = false;
        if (parseInt(product.active) === 1) {
            product_active = true;
        }

        this.setState({
            formModalFieldId: product.id,
            formModalFieldActive: product_active,
            formModalFieldType: product.type,
            formModalFieldName: product.name,
            formModalFieldNotes: product.notes,
            formModalFieldAttributes: product.attributes,
        });
    }

    deleteProduct(productId) {
        if (window.confirm('Confermi l\'eliminazione?')) {
            this.setState({
                loading: true,
            });

            // Fetch api for user's orders
            let accessToken = this.state.accessToken;

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=deleteProduct&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("productId", productId);

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.getProductsList();

                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    toggleFormModal(title, action) {
        let showFormModal = this.state.showFormModal;

        if (showFormModal === true) {
            showFormModal = false;
        } else {
            showFormModal = true;
        }

        this.setState({
            showFormModal: showFormModal,
            formModalTitle: title,
            formModalAction: action,

            formModalFieldId: null,
            formModalFieldActive: false,
            formModalFieldType: "standard",
            formModalFieldName: "",
            formModalFieldNotes: "",
            formModalFieldAttributes: [],

            showFormError: false,
            formErrorMessage: "",
        });

    }

    setInputErrorMessage( field, message ) {
        switch(field) {
            case 'stdError':
                this.setState({
                    showFormError: true,
                    formErrorMessage: message
                });
                break;
            default:
                break;
        }
    }

    confirmProductForm() {
        if (window.confirm('Confermi i dati forniti?')) {
            this.setState({
                loading: true,
            });

            // Fetch api
            let accessToken = this.state.accessToken;
            let formAction  = this.state.formModalAction;

            let productData = {
                id: this.state.formModalFieldId,
                active: this.state.formModalFieldActive,
                type: this.state.formModalFieldType,
                name: this.state.formModalFieldName,
                notes: this.state.formModalFieldNotes,
                attributes: this.state.formModalFieldAttributes,
            };

            if (productData.name === "") {
                this.setInputErrorMessage('stdError', Globals.alerts.mandatoryName[this.state.language]);
                this.setState({
                    loading: false,
                });
                return;
            }

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=' + formAction + '&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("productData", JSON.stringify(productData));

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.toggleFormModal();
                    this.getProductsList();

                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    handleProductActiveChange() {
        let isActive = this.state.formModalFieldActive;

        this.setState({
            formModalFieldActive: !isActive
        });
    }

    setAttributeValue(value, key) {
        let attributes = this.state.formModalFieldAttributes;

        attributes[key].description = value;

        this.setState({
            formModalFieldAttributes: attributes,
        });
    }

    addAttribute(type) {
        let attributes = this.state.formModalFieldAttributes;

        let newAttribute = {
            id: null,
            type: type,
            description: "",
        };

        attributes.push(newAttribute);

        this.setState({
            formModalFieldAttributes: attributes,
        });
    }

    removeAttribute(key) {
        let attributes = this.state.formModalFieldAttributes;

        delete attributes[key];

        this.setState({
            formModalFieldAttributes: attributes,
        });
    }

    render() {
        if ( this.state.isLogged !== true || this.state.isAdmin !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        if ( this.state.productsList === null ) {
            return(
                <Loading/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let productsList = this.state.productsList;
        let productsListMessage = this.state.productsListMessage;
        let productsRender  = "";
        if ( productsListMessage !== "" ) {
            productsRender = <div className="message-wrapper">
                {productsListMessage}
            </div>;
        } else {
            // Prepare data for the table;
            const columns = [
                {
                    Header: "Name",
                    accessor: 'name',
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Note",
                    accessor: 'notes',
                    style: {overflow: "visible", whiteSpace: "normal"},
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Attivo",
                    accessor: 'activeIcon',
                    headerStyle: { textAlign: "center" },
                    width: 100,
                    Cell: (row) => (
						<div style={{ textAlign: "center" }}>
							{" "}
							{row.value === "1" ? (
								<img src={img1} alt={"Attivo"} />
							) : (
								<img src={img0} alt={"Non Attivo"} />
							)}{" "}
						</div>),
                    filterable: true,
					filterMethod: (filter, row) => {
						if (filter.value === "Tutti") {
							return true;
						}
						if (filter.value === '1') {
							return row[filter.id] === '1';
						}
						if (filter.value === '0') {
							return row[filter.id] === '0';
						}
					},
					Filter: ({ filter, onChange }) => (
						<select
							onChange={(event) => onChange(event.target.value)}
							style={{ width: "100%", textAlign: "center" }}
							value={filter ? filter.value : "Tutti"}>
							<option value='Tutti'>Tutti</option>
							<option value='1'>Attivi</option>
							<option value='0'>Non Attivi</option>
						</select>
					),
                },
                {
                    Header: " ",
                    accessor: 'editIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
                {
                    Header: " ",
                    accessor: 'deleteIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
            ];
            const data = [];
            for( let c = 0; c < productsList.length; c++ ){
                // let activeSrc = img0;
                // if (parseInt(productsList[c].active) === 1) {
                //     activeSrc = img1;
                // }

                // Push data
                data.push( {
                    name: productsList[c].name,
                    notes: productsList[c].notes,
                    activeIcon: productsList[c].active,
                    editIcon: <i className="std-hoverable-cursor-pointer fa fa-edit fa-2x" onClick={() => {this.editProduct(c)}}></i>,
                    deleteIcon: <i className="std-hoverable-cursor-pointer fa fa-trash fa-2x" onClick={() => {this.deleteProduct(productsList[c].id)}}></i>
                } );
            }
            // Render Table
            productsRender = <ReactTable
                data={data}
                columns={columns}
                showPagination={false}
                pageSize={productsList.length}
            />;
        }

        let formModal;
        if (this.state.showFormModal === true) {
            let formErrorField;
            if ( this.state.showFormError === true ) {
                formErrorField = <div><div className="input-error-wrapper">{this.state.formErrorMessage}</div></div>;
            }

            let attributesRender = <div className="attributes">
                <div className="attribute-group-wrapper tipologia">
                    <div className="title">Tipologia<i className="fa fa-plus" onClick={() => this.addAttribute('tipologia')}></i></div>
                    {this.state.formModalFieldAttributes.length ? this.state.formModalFieldAttributes.map((item, key) => {
                        if (item.type === 'tipologia') {
                            return <div key={key} className="attribute-wrapper">
                                <span className="hidden attribute-id">{item.id}</span>
                                <input type="text" value={item.description} onChange={(e) => this.setAttributeValue(e.target.value, key)}/>
                                <i className="fa fa-times" onClick={() => (this.removeAttribute(key))}></i>
                            </div>;
                        }
                        return false;
                    }) : ""}
                </div>
                <div className="attribute-group-wrapper apertura">
                    <div className="title">Disp. apertura<i className="fa fa-plus" onClick={() => this.addAttribute('apertura')}></i></div>
                    {this.state.formModalFieldAttributes.length ? this.state.formModalFieldAttributes.map((item, key) => {
                        if (item.type === 'apertura') {
                            return <div key={key} className="attribute-wrapper">
                                <span className="hidden attribute-id">{item.id}</span>
                                <input type="text" value={item.description} onChange={(e) => this.setAttributeValue(e.target.value, key)}/>
                                <i className="fa fa-times" onClick={() => (this.removeAttribute(key))}></i>
                            </div>;
                        }
                        return false;
                    }) : ""}
                </div>
                <div className="attribute-group-wrapper sicurezza">
                    <div className="title">Disp. di sicurezza<i className="fa fa-plus" onClick={() => this.addAttribute('sicurezza')}></i></div>
                    {this.state.formModalFieldAttributes.length ? this.state.formModalFieldAttributes.map((item, key) => {
                        if (item.type === 'sicurezza') {
                            return <div key={key} className="attribute-wrapper">
                                <span className="hidden attribute-id">{item.id}</span>
                                <input type="text" value={item.description} onChange={(e) => this.setAttributeValue(e.target.value, key)}/>
                                <i className="fa fa-times" onClick={() => (this.removeAttribute(key))}></i>
                            </div>;
                        }
                        return false;
                    }) : ""}
                </div>
            </div>;

            formModal = <div className="modal">
                <div className="background"></div>
                <div className="body">
                    <div className="titleBar">
                        {this.state.formModalTitle}
                        <div className="close-button">
                            <i className="fa fa-times" onClick={() => this.toggleFormModal("", "")}></i>
                        </div>
                    </div>
                    <div className="content three-sides-form">
                        <div className="input-wrapper full-width centered">
                            <div className="label">Attivo</div>
                            <Switch
                                isOn={this.state.formModalFieldActive}
                                handleToggle={this.handleProductActiveChange}
                            />
                        </div>
                        <div className="input-wrapper full-width centered small">
                            <div className="label">Nome</div>
                            <input type="text" name="name" value={this.state.formModalFieldName} onChange={(e) => this.setState({formModalFieldName: e.target.value})} />
                            {formErrorField}
                        </div>
                        <div className="input-wrapper full-width centered small">
                            <div className="label">Note</div>
                            <textarea name="notes" value={this.state.formModalFieldNotes} onChange={(e) => this.setState({formModalFieldNotes: e.target.value})} />
                        </div>
                        {attributesRender}
                        <div className="input-wrapper full-width small">
                            <button type="button" onClick={() => this.confirmProductForm()}><span>Conferma</span></button>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return(
            <div className="users-page-wrapper">
                <div className="filters">
                    <div className="filters-wrapper">
                        <div className="filter-wrapper">
                            <div className="filter">
                                <button type="button" onClick={() => this.newProduct()}><span>Nuovo</span></button>
                                <span className="title-text"> GESTIONE PRODOTTI </span>
                            </div>
                        </div>
                    </div>
                </div>
                {productsRender}
                {loadingOnScreen}
                {formModal}
            </div>
        );
    }

}