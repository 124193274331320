import React, { Component } from "react";

import Globals from "./../globals/globals.js";

export default class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			language: props.language,
		};
	}

	componentWillReceiveProps(props) {
		this.setState({
			language: props.language,
		});
	}

	render() {
		let modifyCookie = this.props.modifyCookie;

		return (
			<footer>
				<div id='footer'>
					<br />
					<div className='company-text'>
						<span className='bigger'>OFFICINA GELSO</span>
						<br />
						Via Venezia, 12 - 37024 Arbizzano di Negrar (VR) - Italia
						<br />
						{Globals.footer.vatCode[this.state.language]} IT03473250235
						<br />
						{Globals.footer.phone[this.state.language]}: +39 045 7514579 -{" "}
						{Globals.footer.email[this.state.language]}: info@officinagelso.com
						<br />
						{this.props.activeCookieBar ? (
							<button
								style={{
									width: "5%",
									backgroundColor: "transparent",
									textTransform: "capitalize",
								}}
								onClick={() => {
									modifyCookie();
								}}>
								Cookie
							</button>
						) : (
							<span></span>
						)}
						<a href={Globals.domain + "privacy-policy-crmpro.html"} target='_blank' rel="noopener noreferrer">
							{" "}
							Privacy policy CRM{" "}
						</a>
					</div>
					<br />
				</div>
			</footer>
		);
	}
}
