import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Redirect } from "react-router-dom";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import moment from "moment/moment";

import logo from './../assets/img/logo.png';
import firma from './../assets/img/firma.png';

Font.register({
    family: "TitilliumWeb",
    src: "https://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fdMPmCKZRbrw.ttf",
});

Font.register({
    family: "TitilliumWebBold",
    src: "https://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffHjDGItzZpabuWI.ttf"
});

class QuotationPDF extends Component {
    constructor(props) {
        super(props);

        if (props.location !== undefined) {
            if (props.location.state !== undefined) {
                this.state = {
                    quotation: props.location.state.quotation,
                    isNew: false,
                }
            }
        }
    }

    componentDidMount(){
        document.title = "CRM Officina Gelso - PDF";
    }

    componentWillReceiveProps(props) {
        this.setState({
            quotation: props.location.state.quotation,
        });

        document.title = "CRM Officina Gelso - PDF";
    }

    render() {
        if (this.props.location === undefined) {
            return (
                <Redirect to={{ pathname: '/preventivi' }}/>
            );
        }

        if (this.props.location.state === undefined) {
            return (
                <Redirect to={{ pathname: '/preventivi' }}/>
            );
        }

        if (this.props.location.state.quotation === undefined) {
            return (
                <Redirect to={{ pathname: '/preventivi' }}/>
            );
        }

        let quotation = this.state.quotation;

        // text slice in case of 6000 or more text

        let introduction = quotation.introduction;
        let timingdescription = quotation.timing_description;
        let description = quotation.description;

        let n = introduction.length;
        let n1 = timingdescription.length;
        let n2 = description.length;

        let secondText = "";
        let VeryBigText = "";

        let thirdText = "";
        let forthText = "";

        if(this.state.quotation.is_list !== "0"){
            this.state.isNew = false;
        }else{
            this.state.isNew = true;
        }

        const reformattedArray = this.state.quotation.quotationList.map((item) => {

             console.log(item.value + item.description);

        });


        if (n > 6000 && n < 12000){
            secondText = introduction.slice(6000,n);
            introduction = introduction.slice(0,6000);
        }

        if (n > 12000){
            VeryBigText = introduction.slice(12001,n);
            secondText = introduction.slice(6000,12000);
            introduction = introduction.slice(0,6000);
        }

        if (n1 > 6000){
            thirdText = timingdescription.slice(6000,n1);
            timingdescription = timingdescription.slice(0,6000);
        }

        if (n2 > 6000){
            forthText = description.slice(6000,n2);
            description = description.slice(0,6000);
        }

        const styles = StyleSheet.create({
            pdfViewer: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
            },
            page: {
                backgroundColor: '#FFFFFF',
                padding: 40,
            },
            pageHeader: {
                flexDirection: 'row',
            },
            logoAndInfoWrapper: {
                flex: 1,
                paddingRight: 10,
                textAlign: 'center',
            },
            clientHeaderWrapper: {
                flex: 1,
                paddingLeft: 10,
                marginTop: 40,
            },
            logo: {
                width: 140,
                marginLeft: 50,
                marginBottom: 10,
            },
            xsText: {
                fontSize: 10,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
            },
            xsTextBold: {
                fontSize: 10,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWebBold',
            },
            sText: {
                fontSize: 12,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'right',
            },
            sTextBold: {
                fontSize: 14,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWebBold',
                textAlign: 'right',
                marginBottom: 10,
            },
            introductionWrapper: {
                marginTop: 30,
            },
            introduction: {
                fontSize: 12,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'justify',
            },
            offerDescriptionWrapper: {
                marginTop: 20,
                flexDirection: 'row',
            },
            offerDescription: {
                flex: 8,
                fontSize: 12,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWebBold',
                textAlign: 'left',
                marginLeft: 30,
            },
            offerDescriptionValues: {
                flex: 2,
                fontSize: 12,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWebBold',
                textAlign: 'right',
                marginRight: 30,
            },
            timingDescriptionWrapper: {
                marginTop: 20,
            },
            timingDescription: {
                fontSize: 12,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'justify',
                textDecoration: 'underline',
            },
            descriptionWrapper: {},
            description: {
                fontSize: 12,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'justify',
            },
            signWrapper: {
                marginTop: 20,
            },
            sign: {
                fontSize: 12,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                textAlign: 'right',
                marginRight: 20,
            },
            signImg: {
                width: 200,
                marginLeft: 300,
            },
        });

        return(
             <PDFViewer style={styles.pdfViewer}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.pageHeader}>
                                <View style={styles.logoAndInfoWrapper}>
                                    <Image style={styles.logo} src={logo}></Image>
                                    <Text style={styles.xsText}>di Gelso Fabio</Text>
                                    <Text style={styles.xsText}>37020 ARBIZZANO (Verona) - Via Venezia, 12</Text>
                                    <Text style={styles.xsText}>Tel. 045 7514579 - Cell. 328 9492439</Text>
                                    <Text style={styles.xsText}>www.officinagelso.com - info@officinagelso.com</Text>
                                    <Text style={styles.xsText}>Codice Fiscale GLSFBA72B27L781N</Text>
                                    <Text style={styles.xsText}>Partita IVA 03473250235 - REA 339499</Text>
                                    <Text style={styles.xsTextBold}>Montaggio e riparazione logistica industriale</Text>
                                    <Text style={styles.xsTextBold}>Riparazione carrelli elevatori</Text>
                                </View>
                                <View style={styles.clientHeaderWrapper}>
                                    <Text style={styles.sTextBold}>Spett. {quotation.user.company}</Text>
                                    <Text style={styles.sText}>Arbizzano {moment(quotation.date).format("DD/MM/YYYY")}</Text>
                                    <Text style={styles.sText}>Offerta n° {quotation.number}</Text>
                                </View>
                            </View>
                            <View style={styles.introductionWrapper}>
                                <Text style={styles.introduction}>{introduction}</Text>
                                <Text style={styles.introduction}>{secondText}</Text>
                                <Text style={styles.introduction}>{VeryBigText}</Text>

                            </View>


                            <View style={styles.offerDescriptionWrapper}>
                                {this.state.isNew? (
                                    <>
                                <Text style={styles.offerDescription}>{quotation.offer_description}</Text>
                                <Text style={styles.offerDescriptionValues}>{quotation.offer_values}</Text>
                                    </>
                                    ):(
                                        <>
                                <Text style={styles.offerDescriptionValues}> {reformattedArray} </Text>
                                        </>
                                )}
                            </View>
                            <View style={styles.timingDescriptionWrapper}>
                                <Text style={styles.timingDescription}>{timingdescription}</Text>
                                <Text style={styles.timingDescription}>{thirdText}</Text>
                            </View>
                            <View style={styles.descriptionWrapper}>
                                <Text style={styles.description}>{description}</Text>
                                <Text style={styles.description}>{forthText}</Text>
                            </View>
                            <View style={styles.signWrapper}>
                                {/*<Text style={styles.sign}>Fabio Gelso</Text>*/}
                                <Image style={styles.signImg} src={firma}></Image>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
        );
    }
}

export default withRouter(QuotationPDF);