import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import cloneDeep from 'lodash/cloneDeep';

import Loading from './../components/Loading.js';

import "react-datepicker/dist/react-datepicker.css";
import 'react-table/react-table.css';

import it from "date-fns/locale/it";
import img0 from "../assets/img/0.png";
import img1 from "../assets/img/1.png";
import moment  from "moment";

registerLocale("it", it);

const defaultUserSelectValue = { value: "", label: "Seleziona un utente" };
const defaultProductSelectValue = { value: "", label: "Seleziona un prodotto" };
const defaultProductTypeSelectValue = { value: "", label: "Seleziona una tipologia" };

export default class AdminInstallations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            isAdmin: props.isAdmin,
            language: props.language,
            installationsList: null,
            usersList: null,
            productsList: null,
            installationsListMessage: "",

            showFormModal: false,
            formModalTitle: "",
            formModalAction: "",

            formModalFieldId: null,
            formModalFieldUser: defaultUserSelectValue,
            formModalFieldProduct: defaultProductSelectValue,
            formModalFieldProductType: defaultProductTypeSelectValue,
            formModalFieldBrand: "",
            formModalFieldModel: "",
            formModalFieldSerialNumber: "",
            formModalFieldSite: "",
            formModalFieldLot: "",
            formModalFieldDuration: new Date(),
            //formModalFieldDuration: "",
            formModalFieldDateOfPurchase: new Date(),
            formModalFieldDateOfFirstUse: new Date(),
            formModalFieldInstallationUser: "",
            formModalFieldNotes: "",
            formModalFieldAttributes: [],

            showErrorMessage: false,
            formErrorMessage: "",

        }

        this.handleUserChange           = this.handleUserChange.bind(this);
        this.handleProductChange        = this.handleProductChange.bind(this);
        this.handleProductTypeChange    = this.handleProductTypeChange.bind(this);
        this.handleDateOfPurchaseChange = this.handleDateOfPurchaseChange.bind(this);
        this.handleDateOfDurationChange = this.handleDateOfDurationChange.bind(this);
        this.handleDateOfFirstUseChange = this.handleDateOfFirstUseChange.bind(this);
        this.handleCheckboxChange       = this.handleCheckboxChange.bind(this);
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.installations[this.state.language];

        window.scrollTo(0,0)

        this.getInstallationsList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            isAdmin:  props.isAdmin,
            language: props.language
        });
        document.title = Globals.titles.installations[props.language];
    }

/*    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        this.setState({
            theposition : scrolled
        })
    }*/

    getInstallationsList() {
        // Reset state
        this.setState({
            installationsList: null,
            usersList: null,
            productsList: null,
            installationsListMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getInstallations&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    installationsList: [],
                    usersList: [],
                    productsList: [],
                    installationsListMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    installationsList: responseJson.installations,
                    usersList: responseJson.users,
                    productsList: responseJson.products,
                    installationsListMessage: "",
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                installationsList: [],
                usersList: [],
                productsList: [],
                installationsListMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    newInstallation() {
        this.toggleFormModal("Nuova installazione", "newInstallation");
    }

    editInstallation(listPosition) {
        this.toggleFormModal("Modifica installazione", "editInstallation");

        let installation = this.state.installationsList[listPosition];

        let userSelected = { value: installation.user.id, label: installation.user.company };
        let productSelected = { value: installation.product.id, label: installation.product.name };
        let productTypeSelected = { value: installation.type.id, label: installation.type.description };

        let date_of_purchase = installation.date_of_purchase;
        if (date_of_purchase !== null && date_of_purchase !== undefined) {
            this.setState({
                formModalFieldDateOfPurchase: new Date(date_of_purchase),
            });
        }

        let date_of_first_use = installation.date_of_first_use;
        if (date_of_first_use !== null && date_of_first_use !== undefined) {
            this.setState({
                formModalFieldDateOfFirstUse: new Date(date_of_first_use),
            });
        }

        let date_of_duration = installation.duration;
        if (date_of_duration !== null && date_of_duration !== undefined) {
            this.setState({
                formModalFieldDuration: new Date(date_of_duration),
            });
        }

        this.setState({
            formModalFieldId: installation.id,
            formModalFieldUser: userSelected,
            formModalFieldProduct: productSelected,
            formModalFieldProductType: productTypeSelected,
            formModalFieldBrand: installation.brand,
            formModalFieldModel: installation.model,
            formModalFieldSerialNumber: installation.serial_number,
            formModalFieldSite: installation.site,
            formModalFieldLot: installation.lot,
            /*formModalFieldDuration: installation.duration,*/
            formModalFieldInstallationUser: installation.installationUser,
            formModalFieldNotes: installation.notes,
            formModalFieldAttributes: [],
        });

        // Set attributes as unchecked
        this.setAttributes(installation.product.id, installation.attributes);
    }

    toggleFormModal(title, action) {
        let showFormModal = this.state.showFormModal;

        if (showFormModal === true) {
            showFormModal = false;
        } else {
            showFormModal = true;
        }

        this.setState({
            showFormModal: showFormModal,
            formModalTitle: title,
            formModalAction: action,

            formModalFieldId: null,
            formModalFieldUser: defaultUserSelectValue,
            formModalFieldProduct: defaultProductSelectValue,
            formModalFieldProductType: defaultProductTypeSelectValue,
            formModalFieldBrand: "",
            formModalFieldModel: "",
            formModalFieldSerialNumber: "",
            formModalFieldSite: "",
            formModalFieldLot: "",
            //formModalFieldDuration: "",
            formModalFieldDuration: new Date(),
            formModalFieldDateOfPurchase: new Date(),
            formModalFieldDateOfFirstUse: new Date(),
            formModalFieldInstallationUser: "",
            formModalFieldNotes: "",
            formModalFieldAttributes: [],

            showErrorMessage: false,
            formErrorMessage: "",
        });

    }

    setInputErrorMessage( field, message ) {
        switch(field) {
            case 'general':
                this.setState({
                    showErrorMessage: true,
                    formErrorMessage: message
                });
                break;
            default:
                break;
        }
    }

    handleUserChange(element) {
        this.setState({
            formModalFieldUser: element
        });
    }

    handleProductChange(element) {
        this.setState({
            formModalFieldProduct: element
        });

        // Now set the attributes in the state
        this.setAttributes(element.value);
    }

    handleProductTypeChange(element) {
        this.setState({
            formModalFieldProductType: element
        });
    }

    handleDateOfPurchaseChange(date) {
        this.setState({
            formModalFieldDateOfPurchase: date
        });
    }

    handleDateOfDurationChange(date) {
        this.setState({
            formModalFieldDuration: date
        });
    }

    handleDateOfFirstUseChange(date) {
        this.setState({
            formModalFieldDateOfFirstUse: date
        });
    }

    handleCheckboxChange(key, attributeType) {
        let formModalFieldAttributes = this.state.formModalFieldAttributes;
        formModalFieldAttributes[attributeType][key].checked = !formModalFieldAttributes[attributeType][key].checked;

        this.setState({
            formModalFieldAttributes: formModalFieldAttributes,
        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    confirmInstallationForm() {
        if (window.confirm('Confermi i dati forniti?')) {
            this.setState({
                loading: true,
            });

            // Fetch api
            let accessToken = this.state.accessToken;
            let formAction  = this.state.formModalAction;

            let installationData = {
                id:             this.state.formModalFieldId,
                usersId:        this.state.formModalFieldUser.value,
                productsId:     this.state.formModalFieldProduct.value,
                typeId:         this.state.formModalFieldProductType.value,
                brand:          this.state.formModalFieldBrand,
                model:          this.state.formModalFieldModel,
                serialNumber:   this.state.formModalFieldSerialNumber,
                site:           this.state.formModalFieldSite,
                lot:            this.state.formModalFieldLot,
                duration:       this.formatDate( this.state.formModalFieldDuration),
                dateOfPurchase: this.formatDate( this.state.formModalFieldDateOfPurchase ),
                dateOfFirstUse: this.formatDate( this.state.formModalFieldDateOfFirstUse ),
                user:           this.state.formModalFieldInstallationUser,
                notes:          this.state.formModalFieldNotes,
                attributes:     this.state.formModalFieldAttributes,
            };

            if (installationData.usersId === "") {
                this.setInputErrorMessage('general', "Devi selezionare un utente");
                this.setState({
                    loading: false,
                });
                return;
            }

            if (installationData.productsId === "") {
                this.setInputErrorMessage('general', "Devi selezionare un prodotto");
                this.setState({
                    loading: false,
                });
                return;
            }

            if (installationData.typeId === "") {
                this.setInputErrorMessage('general', "Devi selezionare una tipologia");
                this.setState({
                    loading: false,
                });
                return;
            }

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=' + formAction + '&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("installationData", JSON.stringify(installationData));

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.toggleFormModal();
                    this.getInstallationsList();

                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    deleteInstallation(installationId) {
        if (window.confirm('Confermi l\'eliminazione?')) {
            this.setState({
                loading: true,
            });

            // Fetch api for user's orders
            let accessToken = this.state.accessToken;

            let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=deleteInstallation&access_token=' + accessToken;

            let formdata = new FormData();

            formdata.append("installationId", installationId);

            fetch( url, {
                method: 'POST',
                body: formdata
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if ( responseJson.error === true ) {
                    // Error during the call - handle message
                    toast(responseJson.message, {
                        autoClose: 8000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.setState({
                        loading: false,
                    });
                } else {
                    // Call is ok
                    toast("Operazione completata", {
                        autoClose: 4000,
                        className: 'std-black-toast',
                        bodyClassName: "std-black-toast-body",
                        progressClassName: 'std-black-toast-progress-bar'
                    });

                    this.getInstallationsList();

                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                // Communication error - handle message
                toast(Globals.errors.serverCall[this.state.language], {
                    autoClose: 8000,
                    className: 'std-black-toast',
                    bodyClassName: "std-black-toast-body",
                    progressClassName: 'std-black-toast-progress-bar'
                });

                this.setState({
                    loading: false,
                });
            });
        }
    }

    setAttributes(productId, defaults) {
        if (productId === "" || productId === null || productId === undefined) {
            this.setState({
                formModalFieldAttributes: [],
            });
            return;
        }

        let selectedProduct = this.state.productsList.find((element) => {return parseInt(element.id) === parseInt(productId)});
        let selectedProductAttributes = cloneDeep(selectedProduct.attributes);
        if (selectedProductAttributes.tipologia !== undefined) {
            delete selectedProductAttributes.tipologia;
        }
        if (selectedProductAttributes.apertura !== undefined) {
            for(let c = 0; c < selectedProductAttributes.apertura.length; c++) {
                selectedProductAttributes.apertura[c].checked = false;
            }
        }
        if (selectedProductAttributes.sicurezza !== undefined) {
            for(let c = 0; c < selectedProductAttributes.sicurezza.length; c++) {
                selectedProductAttributes.sicurezza[c].checked = false;
            }
        }
        if (selectedProductAttributes.difetti_riparabili !== undefined) {
            for(let c = 0; c < selectedProductAttributes.difetti_riparabili.length; c++) {
                selectedProductAttributes.difetti_riparabili[c].checked = false;
            }
        }
        if (selectedProductAttributes.difetti_revisione !== undefined) {
            for(let c = 0; c < selectedProductAttributes.difetti_revisione.length; c++) {
                selectedProductAttributes.difetti_revisione[c].checked = false;
            }
        }
        if (selectedProductAttributes.difetti_fuori_uso !== undefined) {
            for(let c = 0; c < selectedProductAttributes.difetti_fuori_uso.length; c++) {
                selectedProductAttributes.difetti_fuori_uso[c].checked = false;
            }
        }
        if (selectedProductAttributes.eventi_revisione !== undefined) {
            for(let c = 0; c < selectedProductAttributes.eventi_revisione.length; c++) {
                selectedProductAttributes.eventi_revisione[c].checked = false;
            }
        }
        if (selectedProductAttributes.eventi_fuori_uso !== undefined) {
            for(let c = 0; c < selectedProductAttributes.eventi_fuori_uso.length; c++) {
                selectedProductAttributes.eventi_fuori_uso[c].checked = false;
            }
        }

        if (defaults !== undefined) {
            for (let c = 0; c < defaults.length; c++) {
                let attributesInList = selectedProductAttributes[defaults[c].type];

                if (attributesInList !== undefined) {
                    for (let cc = 0; cc < attributesInList.length; cc++) {
                        if (parseInt(attributesInList[cc].id) === parseInt(defaults[c].id)) {
                            attributesInList[cc].checked = true;
                        }
                    }
                }

                selectedProductAttributes[defaults[c].type] = attributesInList;
            }
        }

        this.setState({
            formModalFieldAttributes: selectedProductAttributes,
        });
    }

    render() {
        if ( this.state.isLogged !== true || this.state.isAdmin !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        if ( this.state.installationsList === null ) {
            return(
                <Loading/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let installationsList = this.state.installationsList;
        let installationsListMessage = this.state.installationsListMessage;
        let installationsRender  = "";
        if ( installationsListMessage !== "" ) {
            installationsRender = <div className="message-wrapper">
                {installationsListMessage}
            </div>;
        } else {
            // Prepare data for the table;
            const columns = [
                {
                    Header: "Utente",
                    accessor: 'user',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Prodotto",
                    accessor: 'product',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Tipologia",
                    accessor: 'type',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Ubicazione",
                    accessor: 'site',
                    style: { overflow: "visible", whiteSpace: "normal"  },
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Durata massima",
                    accessor: 'duration',
                    width: 150,
                    /*filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    },*/
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
                {
                    Header: "Marca",
                    accessor: 'brand',
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Modello",
                    accessor: 'model',
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: "Matricola",
                    accessor: 'serialNumber',
                    filterable: true,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
                    }
                },
                {
                    Header: " ",
                    accessor: 'editIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
                {
                    Header: " ",
                    accessor: 'deleteIcon',
                    width: 50,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                },
            ];

            const data = [];
            let today = moment();

            for( let c = 0; c < installationsList.length; c++ ){
                    let durationSrc = img0;
                    if (this.formatDate(installationsList[c].duration) > this.formatDate(today) ) {

                        durationSrc = img1;
                    }
                //item.limit != null || item != ' ' && now.isAfter(dateLimit)

                // Push data
                data.push( {
                    user: installationsList[c].user.company,
                    product: installationsList[c].product.name,
                    type: installationsList[c].type.description,
                    site: installationsList[c].site,
                    duration: (installationsList[c].duration !== "" ? <img src={durationSrc} alt={installationsList[c].duration} />: <div></div>  ),
                    brand: installationsList[c].brand,
                    model: installationsList[c].model,
                    serialNumber: installationsList[c].serial_number,
                    editIcon: <i className="std-hoverable-cursor-pointer fa fa-edit fa-2x" onClick={() => {this.editInstallation(c)}}></i>,
                    deleteIcon: <i className="std-hoverable-cursor-pointer fa fa-trash fa-2x" onClick={() => {this.deleteInstallation(installationsList[c].id)}}></i>
                } );
            }
            // Render Table
            installationsRender = <ReactTable
                data={data}
                columns={columns}
                pageSizeOptions={[50,100,200]}
                page={this.state.page}
                onPageChange={page => this.setState({page})}
                defaultPageSize={50}
                previousText={Globals.reactTableText.previous[this.state.language]}
                nextText={Globals.reactTableText.next[this.state.language]}
                loadingText={Globals.reactTableText.loading[this.state.language]}
                noDataText={Globals.reactTableText.noData[this.state.language]}
                pageText={Globals.reactTableText.page[this.state.language]}
                ofText={Globals.reactTableText.of[this.state.language]}
                rowsText={Globals.reactTableText.rows[this.state.language]}
                pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
                rowsSelectorText={Globals.reactTableText.rowsSelectorText[this.state.language]}
            />;
        }

        let formModal;
        if (this.state.showFormModal === true) {
            // Select utenti
            let formModalFieldUserSelectOptions = [defaultUserSelectValue];
            let usersList = this.state.usersList;
            for( let c = 0; c < usersList.length; c++ ){
                let optionValue = { value: usersList[c].id, label: usersList[c].company };
                formModalFieldUserSelectOptions.push(optionValue);
            }

            // Select prodotti
            let formModalFieldProductSelectOptions = [defaultProductSelectValue];
            let productsList = this.state.productsList;
            for( let c = 0; c < productsList.length; c++ ){
                let optionValue = { value: productsList[c].id, label: productsList[c].name };
                formModalFieldProductSelectOptions.push(optionValue);
            }

            // Select tipologie e set tipologia prodotto selezionato
            let formModalFieldProductTypeSelectOptions = [defaultProductTypeSelectValue];
            let selectedProductType = "";
            if (this.state.formModalFieldProduct !== defaultProductSelectValue) {
                // Creo una lista tipologie basata su quelle disponibili per quel prodotto
                let selectedProduct = productsList.find((element) => {return parseInt(element.id) === parseInt(this.state.formModalFieldProduct.value)});
                let selectedProductTypeAttributes = selectedProduct.attributes.tipologia;

                if (selectedProductTypeAttributes !== undefined) {
                    for( let c = 0; c < selectedProductTypeAttributes.length; c++ ){
                        let optionValue = { value: selectedProductTypeAttributes[c].id, label: selectedProductTypeAttributes[c].description };
                        formModalFieldProductTypeSelectOptions.push(optionValue);
                    }
                }

                selectedProductType = selectedProduct.type;
            }

            let formErrorField;
            if ( this.state.showErrorMessage === true ) {
                formErrorField = <div className="input-wrapper full-width centered"><div className="input-error-wrapper">{this.state.formErrorMessage}</div></div>;
            }

            formModal = <div className="modal">
                <div className="background"></div>
                <div className="body">
                    <div className="titleBar">
                        {this.state.formModalTitle}
                        <div className="close-button">
                            <i className="fa fa-times" onClick={() => this.toggleFormModal("", "")}></i>
                        </div>
                    </div>
                    <div className="content three-sides-form">
                        {formErrorField}
                        <div className="input-wrapper">
                            <div className="label">Utente</div>
                            <Select
                                value={this.state.formModalFieldUser}
                                onChange={this.handleUserChange}
                                options={formModalFieldUserSelectOptions}
                            />
                        </div>
                        <div className="input-wrapper">
                            <div className="label">Prodotto</div>
                            <Select
                                value={this.state.formModalFieldProduct}
                                onChange={this.handleProductChange}
                                options={formModalFieldProductSelectOptions}
                            />
                        </div>
                        {this.state.formModalFieldProduct !== defaultProductSelectValue ? <div className="input-wrapper">
                            <div className="label">Tipologia</div>
                            <Select
                                value={this.state.formModalFieldProductType}
                                onChange={this.handleProductTypeChange}
                                options={formModalFieldProductTypeSelectOptions}
                            />
                        </div> : ""}
                        {this.state.formModalFieldProduct !== defaultProductSelectValue ? <div className="input-wrapper">
                            <div className="label">Marca</div>
                            <input type="text" name="marca" value={this.state.formModalFieldBrand} onChange={(e) => this.setState({formModalFieldBrand: e.target.value})} />
                        </div> : ""}
                        {this.state.formModalFieldProduct !== defaultProductSelectValue ? <div className="input-wrapper">
                            <div className="label">Modello</div>
                            <input type="text" name="modello" value={this.state.formModalFieldModel} onChange={(e) => this.setState({formModalFieldModel: e.target.value})} />
                        </div> : ""}
                        {this.state.formModalFieldProduct !== defaultProductSelectValue ? <div className="input-wrapper">
                            <div className="label">Matricola</div>
                            <input type="text" name="matricola" value={this.state.formModalFieldSerialNumber} onChange={(e) => this.setState({formModalFieldSerialNumber: e.target.value})} />
                        </div> : ""}
                        {selectedProductType === "standard" ? <div className="input-wrapper">
                            <div className="label">Ubicazione</div>
                            <input type="text" name="ubicazione" value={this.state.formModalFieldSite} onChange={(e) => this.setState({formModalFieldSite: e.target.value})} />
                        </div> : ""}
                        {selectedProductType === "camp" ? <div className="input-wrapper">
                            <div className="label">Lotto</div>
                            <input type="text" name="lotto" value={this.state.formModalFieldLot} onChange={(e) => this.setState({formModalFieldLot: e.target.value})} />
                        </div> : ""}
                        {selectedProductType === "camp" ? <div className="input-wrapper">
                            <div className="label">Durata massima</div>
                            <DatePicker
                                locale={this.state.language}
                                selected={this.state.formModalFieldDuration}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.handleDateOfDurationChange}
                            />
                        {/*
                          <input type="text" name="durataMassima" value={this.state.formModalFieldDuration} onChange={(e) => this.setState({formModalFieldDuration: e.target.value})} />
                        */}
                        </div> : ""}
                        {selectedProductType === "camp" ? <div className="input-wrapper">
                            <div className="label">Data di acquisto</div>
                            <DatePicker
                                locale={this.state.language}
                                selected={this.state.formModalFieldDateOfPurchase}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.handleDateOfPurchaseChange}
                            />
                        </div> : ""}
                        {selectedProductType === "camp" ? <div className="input-wrapper">
                            <div className="label">Data del primo utilizzo</div>
                            <DatePicker
                                locale={this.state.language}
                                selected={this.state.formModalFieldDateOfFirstUse}
                                dateFormat="dd/MM/yyyy"
                                onChange={this.handleDateOfFirstUseChange}
                            />
                        </div> : ""}
                        {selectedProductType === "camp" ? <div className="input-wrapper">
                            <div className="label">Utilizzatore</div>
                            <input type="text" name="utilizzatore" value={this.state.formModalFieldInstallationUser} onChange={(e) => this.setState({formModalFieldInstallationUser: e.target.value})} />
                        </div> : ""}
                        <div className="attributes">
                            {this.state.formModalFieldAttributes.apertura !== undefined ? <div className="attribute-group-wrapper apertura">
                                <div className="title">Disp. di apertura</div>
                                {this.state.formModalFieldAttributes.apertura.length ? this.state.formModalFieldAttributes.apertura.map((item, key) => {
                                    return <div key={key} className="attribute-wrapper">
                                        <input id={"cb-apertura-"+key} className="fa" type="checkbox" checked={this.state.formModalFieldAttributes.apertura[key].checked} onChange={() => this.handleCheckboxChange(key, 'apertura')} />
                                        <label htmlFor={"cb-apertura-"+key}>{item.description}</label>
                                    </div>;
                                }) : ""}
                            </div> : ""}
                            {this.state.formModalFieldAttributes.sicurezza !== undefined ? <div className="attribute-group-wrapper sicurezza">
                                <div className="title">Disp. di sicurezza</div>
                                {this.state.formModalFieldAttributes.sicurezza.length ? this.state.formModalFieldAttributes.sicurezza.map((item, key) => {
                                    return <div key={key} className="attribute-wrapper">
                                        <input id={"cb-sicurezza-"+key} className="fa" type="checkbox" checked={this.state.formModalFieldAttributes.sicurezza[key].checked} onChange={() => this.handleCheckboxChange(key, 'sicurezza')} />
                                        <label htmlFor={"cb-sicurezza-"+key}>{item.description}</label>
                                    </div>;
                                }) : ""}
                            </div> : ""}
                            {this.state.formModalFieldAttributes.difetti_riparabili !== undefined ? <div className="attribute-group-wrapper difetti_riparabili">
                                <div className="title">Difetti [Riparazione]</div>
                                {this.state.formModalFieldAttributes.difetti_riparabili.length ? this.state.formModalFieldAttributes.difetti_riparabili.map((item, key) => {
                                    return <div key={key} className="attribute-wrapper">
                                        <input id={"cb-difetti_riparabili-"+key} className="fa" type="checkbox" checked={this.state.formModalFieldAttributes.difetti_riparabili[key].checked} onChange={() => this.handleCheckboxChange(key, 'difetti_riparabili')} />
                                        <label htmlFor={"cb-difetti_riparabili-"+key}>{item.description}</label>
                                    </div>;
                                }) : ""}
                            </div> : ""}
                            {this.state.formModalFieldAttributes.difetti_revisione !== undefined ? <div className="attribute-group-wrapper difetti_revisione">
                                <div className="title">Difetti [Revisione]</div>
                                {this.state.formModalFieldAttributes.difetti_revisione.length ? this.state.formModalFieldAttributes.difetti_revisione.map((item, key) => {
                                    return <div key={key} className="attribute-wrapper">
                                        <input id={"cb-difetti_revisione-"+key} className="fa" type="checkbox" checked={this.state.formModalFieldAttributes.difetti_revisione[key].checked} onChange={() => this.handleCheckboxChange(key, 'difetti_revisione')} />
                                        <label htmlFor={"cb-difetti_revisione-"+key}>{item.description}</label>
                                    </div>;
                                }) : ""}
                            </div> : ""}
                            {this.state.formModalFieldAttributes.difetti_fuori_uso !== undefined ? <div className="attribute-group-wrapper difetti_fuori_uso">
                                <div className="title">Difetti [Fuori uso]</div>
                                {this.state.formModalFieldAttributes.difetti_fuori_uso.length ? this.state.formModalFieldAttributes.difetti_fuori_uso.map((item, key) => {
                                    return <div key={key} className="attribute-wrapper">
                                        <input id={"cb-difetti_fuori_uso-"+key} className="fa" type="checkbox" checked={this.state.formModalFieldAttributes.difetti_fuori_uso[key].checked} onChange={() => this.handleCheckboxChange(key, 'difetti_fuori_uso')} />
                                        <label htmlFor={"cb-difetti_fuori_uso-"+key}>{item.description}</label>
                                    </div>;
                                }) : ""}
                            </div> : ""}
                            {this.state.formModalFieldAttributes.eventi_revisione !== undefined ? <div className="attribute-group-wrapper eventi_revisione">
                                <div className="title">Eventi [Revisione]</div>
                                {this.state.formModalFieldAttributes.eventi_revisione.length ? this.state.formModalFieldAttributes.eventi_revisione.map((item, key) => {
                                    return <div key={key} className="attribute-wrapper">
                                        <input id={"cb-eventi_revisione-"+key} className="fa" type="checkbox" checked={this.state.formModalFieldAttributes.eventi_revisione[key].checked} onChange={() => this.handleCheckboxChange(key, 'eventi_revisione')} />
                                        <label htmlFor={"cb-eventi_revisione-"+key}>{item.description}</label>
                                    </div>;
                                }) : ""}
                            </div> : ""}
                            {this.state.formModalFieldAttributes.eventi_fuori_uso !== undefined ? <div className="attribute-group-wrapper eventi_fuori_uso">
                                <div className="title">Eventi [Fuori uso]</div>
                                {this.state.formModalFieldAttributes.eventi_fuori_uso.length ? this.state.formModalFieldAttributes.eventi_fuori_uso.map((item, key) => {
                                    return <div key={key} className="attribute-wrapper">
                                        <input id={"cb-eventi_fuori_uso-"+key} className="fa" type="checkbox" checked={this.state.formModalFieldAttributes.eventi_fuori_uso[key].checked} onChange={() => this.handleCheckboxChange(key, 'eventi_fuori_uso')} />
                                        <label htmlFor={"cb-eventi_fuori_uso-"+key}>{item.description}</label>
                                    </div>;
                                }) : ""}
                            </div> : ""}
                        </div>
                        <div className="input-wrapper full-width">
                            <div className="label">Note</div>
                            <textarea name="notes" value={this.state.formModalFieldNotes} onChange={(e) => this.setState({formModalFieldNotes: e.target.value})} />
                        </div>
                        <div className="input-wrapper full-width small">
                            <button type="button" onClick={() => this.confirmInstallationForm()}><span>Conferma</span></button>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return(
            <div className="users-page-wrapper">
                <div className="filters">
                    <div className="filters-wrapper">
                        <div className="filter-wrapper">
                            <div className="filter">
                                <button type="button" onClick={() => this.newInstallation()}><span>Nuovo</span></button>
                                <span className="title-text"> GESTIONE INSTALLAZIONI </span>
                            </div>
                        </div>
                    </div>
                </div>
                {installationsRender}
                {loadingOnScreen}
                {formModal}
            </div>
        );
    }

}