import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Redirect } from "react-router-dom";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import moment from "moment/moment";

Font.register({
    family: "TitilliumWeb",
    src: "https://fonts.gstatic.com/s/titilliumweb/v8/NaPecZTIAOhVxoMyOr9n_E7fdMPmCKZRbrw.ttf",
});

Font.register({
    family: "TitilliumWebBold",
    src: "https://fonts.gstatic.com/s/titilliumweb/v8/NaPDcZTIAOhVxoMyOr9n_E7ffHjDGItzZpabuWI.ttf"
});

class UserProductPDF extends Component {
    constructor(props) {
        super(props);

        if (props.location !== undefined) {
            if (props.location.state !== undefined) {
                this.state = {
                    installation: props.location.state.installation,
                }
            }
        }
    }

    componentDidMount(){
        document.title = "CRM Officina Gelso - PDF";
    }

    componentWillReceiveProps(props) {
        this.setState({
            installation: props.location.state.installation,
        });

        document.title = "CRM Officina Gelso - PDF";
    }

    render() {
        if (this.props.location === undefined) {
            return (
                <Redirect to={{ pathname: '/i-miei-prodotti' }}/>
            );
        }

        if (this.props.location.state === undefined) {
            return (
                <Redirect to={{ pathname: '/i-miei-prodotti' }}/>
            );
        }

        if (this.props.location.state.installation === undefined) {
            return (
                <Redirect to={{ pathname: '/i-miei-prodotti' }}/>
            );
        }

        let installation = this.state.installation;

        const styles = StyleSheet.create({
            pdfViewer: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
            },
            page: {
                backgroundColor: '#FFFFFF',
                padding: 40,
            },
            title: {
                color: '#7a0000',
                textTransform: 'uppercase',
                fontSize: 20,
                fontWeight: 'bold',
                fontFamily: 'TitilliumWebBold',
            },
            smallTitle: {
                color: '#7a0000',
                textTransform: 'uppercase',
                fontSize: 16,
                marginTop: 20,
                fontFamily: 'TitilliumWebBold',
            },
            voicesContainer: {
                flexDirection: 'row',
            },
            voice: {
                flex: 1,
                fontSize: 10,
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
            },
            boldVoice: {
                fontFamily: 'TitilliumWebBold',
            },
            paddedText: {
                paddingTop: 2,
                paddingLeft: 10,
            },
            operationWrapper: {
                marginTop: 5,
            },
            operationTitleWrapper: {
                color: '#5c5c5c',
                fontFamily: 'TitilliumWebBold',
                fontSize: 12,
            },
            operationDetailWrapper: {
                color: '#5c5c5c',
                fontFamily: 'TitilliumWeb',
                fontSize: 10,
                paddingLeft: 20,
            },
            noOperationsMessage: {
                color: '#5c5c5c',
                fontFamily: 'TitilliumWebBold',
                fontSize: 14,
            },
        });

        return(
            <PDFViewer style={styles.pdfViewer}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.title}>
                            <Text style={styles.titleText}>{installation.product.name}{installation.site !== "" ? " [" + installation.site + "]" : ""}</Text>
                        </View>
                        <View style={styles.voicesContainer}>
                            <View style={styles.voice}>
                                <Text><Text style={styles.boldVoice}>TIPOLOGIA:</Text> {installation.type.description}</Text>
                            </View>
                            <View style={styles.voice}>
                                <Text><Text style={styles.boldVoice}>MARCA:</Text> {installation.brand}</Text>
                            </View>
                        </View>
                        <View style={styles.voicesContainer}>
                            <View style={styles.voice}>
                                <Text><Text style={styles.boldVoice}>MODELLO:</Text> {installation.model}</Text>
                            </View>
                            <View style={styles.voice}>
                                <Text><Text style={styles.boldVoice}>MATRICOLA:</Text> {installation.serial_number}</Text>
                            </View>
                        </View>
                        <View style={styles.voicesContainer}>
                            {installation.lot !== "" ? <View style={styles.voice}><Text><Text style={styles.boldVoice}>LOTTO ED ANNO DI FABBRICAZIONE:</Text> {installation.lot}</Text></View> : <View></View>}
                            {installation.duration !== "" ? <View style={styles.voice}><Text><Text style={styles.boldVoice}>DURATA DI VITA MASSIMA PREVISTA:</Text> {installation.duration}</Text></View> : <View></View>}
                        </View>
                        <View style={styles.voicesContainer}>
                            {installation.date_of_purchase !== null ? <View style={styles.voice}><Text><Text style={styles.boldVoice}>DATA DI ACQUISTO:</Text> {moment(installation.date_of_purchase).format("DD/MM/YYYY")}</Text></View> : <View></View>}
                            {installation.date_of_first_use !== null ? <View style={styles.voice}><Text><Text style={styles.boldVoice}>DATA DEL PRIMO UTILIZZO:</Text> {moment(installation.date_of_first_use).format("DD/MM/YYYY")}</Text></View> : <View></View>}
                        </View>
                        {installation.installationUser !== "" ? <View style={styles.voicesContainer}><View style={styles.voice}><Text><Text style={styles.boldVoice}>UTILIZZATORE:</Text> {installation.installationUser}</Text></View></View> : <View></View>}
                        {installation.notes !== "" ? <View style={styles.voicesContainer}><View style={styles.voice}><Text><Text style={styles.boldVoice}>NOTE:</Text> {installation.notes}</Text></View></View> : <View></View>}
                        <View style={styles.voicesContainer}>
                            {installation.attributes.sicurezza.length > 0 ? <View style={styles.voice}>
                                <View><Text style={styles.boldVoice}>DISPOSITIVI DI SICUREZZA:</Text></View>
                                {installation.attributes.sicurezza.map((item, key) => {
                                    return <View key={key} style={styles.paddedText}><Text>- {item.description}</Text></View>
                                })}
                            </View> : <View></View>}
                            {installation.attributes.apertura.length > 0 ? <View style={styles.voice}>
                                <View><Text style={styles.boldVoice}>DISPOSITIVI DI APERTURA:</Text></View>
                                {installation.attributes.apertura.map((item, key) => {
                                    return <View key={key} style={styles.paddedText}><Text>- {item.description}</Text></View>
                                })}
                            </View> : <View></View>}
                        </View>
                        <View style={styles.voicesContainer}>
                            {installation.attributes.difetti_riparabili.length > 0 ? <View style={styles.voice}>
                                <View><Text style={styles.boldVoice}>DIFETTI RISCONTRATI PER CUI IL DISPOSITIVO PUÒ ESSERE RIPARATO:</Text></View>
                                {installation.attributes.difetti_riparabili.map((item, key) => {
                                    return <View key={key} style={styles.paddedText}><Text>- {item.description}</Text></View>
                                })}
                            </View> : <View></View>}
                        </View>
                        <View style={styles.voicesContainer}>
                            {installation.attributes.difetti_revisione.length > 0 ? <View style={styles.voice}>
                                <View><Text style={styles.boldVoice}>DIFETTI RISCONTRATI PER CUI IL DISPOSITIVO DEVE ESSERE SOTTOPOSTO A REVISIONE:</Text></View>
                                {installation.attributes.difetti_revisione.map((item, key) => {
                                    return <View key={key} style={styles.paddedText}><Text>- {item.description}</Text></View>
                                })}
                            </View> : <View></View>}
                        </View>
                        <View style={styles.voicesContainer}>
                            {installation.attributes.difetti_fuori_uso.length > 0 ? <View style={styles.voice}>
                                <View><Text style={styles.boldVoice}>DIFETTI RISCONTRATI PER CUI IL DISPOSITIVO DEVE ESSERE MESSO FUORI USO:</Text></View>
                                {installation.attributes.difetti_fuori_uso.map((item, key) => {
                                    return <View key={key} style={styles.paddedText}><Text>- {item.description}</Text></View>
                                })}
                            </View> : <View></View>}
                        </View>
                        <View style={styles.voicesContainer}>
                            {installation.attributes.eventi_revisione.length > 0 ? <View style={styles.voice}>
                                <View><Text style={styles.boldVoice}>EVENTI AVVENUTI PER CUI IL DISPOSITIVO DEVE ESSERE SOTTOPOSTO A REVISIONE:</Text></View>
                                {installation.attributes.eventi_revisione.map((item, key) => {
                                    return <View key={key} style={styles.paddedText}><Text>- {item.description}</Text></View>
                                })}
                            </View> : <View></View>}
                        </View>
                        <View style={styles.voicesContainer}>
                            {installation.attributes.eventi_fuori_uso.length > 0 ? <View style={styles.voice}>
                                <View><Text style={styles.boldVoice}>EVENTI AVVENUTI PER CUI IL DISPOSITIVO DEVE ESSERE MESSO FUORI USO:</Text></View>
                                {installation.attributes.eventi_fuori_uso.map((item, key) => {
                                    return <View key={key} style={styles.paddedText}><Text>- {item.description}</Text></View>
                                })}
                            </View> : <View></View>}
                        </View>
                        {installation.operations.length > 0 ? <View>
                            <View style={styles.smallTitle}>
                                <Text>INTERVENTI</Text>
                            </View>
                            {installation.operations.map((item, key) => {
                                return <View key={key} style={styles.operationWrapper}>
                                    <View style={styles.operationTitleWrapper}>
                                        <Text>{moment(item.date).format("DD/MM/YYYY")} - {item.type}</Text>
                                    </View>
                                    {item.nextOperationDate !== "" ? <View style={styles.operationDetailWrapper}><Text>Prossimo controllo: {moment(item.nextOperationDate).format("DD/MM/YYYY")}</Text></View> : <View></View>}
                                    {item.notes !== "" ? <View style={styles.operationDetailWrapper}><Text>{item.notes}</Text></View> : <View></View>}
                                </View>
                            })}
                        </View> : <View></View>}
                    </Page>
                </Document>
            </PDFViewer>
        );
    }
}

export default withRouter(UserProductPDF);