import React, { Component } from "react";
import cloneDeep from "lodash/cloneDeep";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import TableDemo from "./TableDemo";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Globals from "../globals/globals";

export default class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			language: props.language,
			formData: props.formData,
			lastFormData: props.formData,
			accessToken: props.accessToken,
			isNew: props.formData.fields[8].isListQuotation === "1" ? true : false,
			value: "",
			previewImage: "",
			lastOffer: "",
		};

		this.child = React.createRef();
	}
	componentWillReceiveProps(props) {
		this.setState({
			language: props.language,
			accessToken: props.accessToken,
			lastOffer: props.lastOffer,
		});
	}

	componentDidMount() {
		if (
			this.state.formData.formAction === "newQuotation" ||
			this.state.formData.formAction === "editQuotation"
		) {
			this.getLastQuotationOffer();
		}
	}

	componentWillUnmount() {
		document.body.classList.remove("form-page-wrapper");
	}

	getLastQuotationOffer() {
		// Fetch api for user's orders

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=getLastQuotationNumber";

		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					return undefined;
				} else {
					let offer = responseJson.lastOffer;
					this.setState({
						lastOffer: offer,
					});
				}
			})
			.catch((error) => {
				// Communication error - handle message
				return undefined;
			});
	}
	editFormData(
		field,
		value,
		isOffer = false,
		typeOfferValue = null,
		typeOfferName = null
	) {
		let newFormData = cloneDeep(this.state.formData);
		if (isOffer) {
			let index = newFormData.fields.findIndex((element) => {
				return element[typeOfferName] === field;
			});
			newFormData.fields[index][typeOfferValue] = value;
			this.setState({
				formData: newFormData,
			});
		} else {
			let index = newFormData.fields.findIndex((element) => {
				return element.name === field;
			});
			newFormData.fields[index].value = value;

			this.setState({
				formData: newFormData,
			});
		}
	}

	savelist() {
		if (this.state.isNew) {
			this.child.current.handleSave();

			setTimeout(() => {
				this.confirmForm();
			}, 100);
		} else {
			this.confirmForm();
		}
	}

	confirmForm() {
		let formData = this.state.formData;

		if (formData.fields[1].value === "") {
			formData.fields[1].errorMessage = "errore";
			return false;
		}

		this.props.confirmForm(formData);
	}

	closeForm() {
		this.props.closeForm();
	}

	onChange = (event, { newValue }) => {
		this.setState({
			value: newValue,
		});
	};

	onClick(image) {
		this.setState({
			isVisible: !this.state.isVisible,
			previewImage: image,
		});
	}
	//this.props.formData.fields[10].isList
	newMethod() {
		this.setState({ isNew: true });
		this.editFormData(
			"formModalList",
			"1",
			true,
			"isListQuotation",
			"nameisListQuotation"
		);
	}
	oldMethod() {
		this.setState({ isNew: false });
		this.editFormData(
			"formModalList",
			"0",
			true,
			"isListQuotation",
			"nameisListQuotation"
		);
	}

	onClose() {
		this.setState({ isVisible: !this.state.isVisible });
	}

	removeTempImage(id) {
		this.state.files.splice(id, 1);

		this.setState({
			files: [...this.state.files, this.state.files.splice(id, 1)],
		});
	}

	render() {
		return (
			<div className='modal'>
				<div className='background'></div>
				<div className='body '>
					<div className='titleBar'>
						{this.state.formData.title}
						<span className='close-button'>
							<i onClick={() => this.closeForm()} className='fa fa-times'></i>
						</span>
					</div>

					<div className='content three-sides-form'>
						{this.state.formData.fields.map((item, key) => {
							if (item.visible === false) {
								return false;
							}

							let errorMessage = <div></div>;
							if (item.errorMessage !== "") {
								errorMessage = <div className='error-message'>{item.errorMessage}</div>;
							}

							switch (item.type) {
								case "hidden":
									return (
										<div className='field-wrapper' key={key}>
											<input type='hidden' name={item.name} value={item.value} />
										</div>
									);

								case "select":
									return (
										<div className='input-wrapper' key={key}>
											<div className='label'>{item.label}</div>
											<Select
												value={item.value}
												onChange={(e) => {
													this.editFormData(item.name, e);
												}}
												options={item.options}
												placeholder={"Seleziona cliente "}
											/>
											{errorMessage}
										</div>
									);

								case "date":
									return (
										<div className='input-wrapper' key={key}>
											<div className='label'>{item.label}</div>
											<div className=''>
												<DatePicker
													locale={this.state.language}
													selected={item.value}
													dateFormat='dd/MM/yyyy'
													onChange={(e) => this.editFormData(item.name, e)}
												/>
											</div>
											{errorMessage}
										</div>
									);
								case "date-input":
									return (
										<div className='date-input' key={key}>
											<div className='label'>{item.label}</div>
											<div className=''>
												<DatePicker
													locale={this.state.language}
													selected={item.value}
													dateFormat='dd/MM/yyyy'
													onChange={(e) => this.editFormData(item.name, e)}
												/>
											</div>
											{errorMessage}
										</div>
									);

								case "multiselect":
									return (
										<div className='text-centered' key={key}>
											<div className='label'>{item.label}</div>
											<CreatableSelect
												isMulti
												onChange={(e) => this.editFormData(item.name, e)}
												value={item.value}
												options={item.options}
												allowCreateWhileLoading={false}
												placeholder={"Seleziona un sopralluogo"}
												isValidNewOption={(value) => false}
											/>{" "}
											{errorMessage}
										</div>
									);

								case "text":
									return (
										<div className='input-wrapper' key={key}>
											<div className='label'>{item.label}</div>
											<input
												type='text'
												name={item.name}
												value={item.value}
												onChange={(e) => this.editFormData(item.name, e.target.value)}
											/>
											{item.name === "formModalFieldNumber" ? (
												this.state.lastOffer !== "" ? (
													<span>
														Ultimo numero offerta registrato {this.state.lastOffer}{" "}
													</span>
												) : (
													<span> </span>
												)
											) : (
												<span></span>
											)}
											{errorMessage}
										</div>
									);
								case "text-title":
									return (
										<div className='input-text' key={key}>
											<div className='label'>{item.label}</div>
											<input
												type='text'
												name={item.name}
												value={item.value}
												onChange={(e) => this.editFormData(item.name, e.target.value)}
											/>
											{errorMessage}
										</div>
									);

								case "textarea":
									return (
										<div className='text-centered' key={key}>
											<div className='label' style={{ fontWeight: "600" }}>
												{item.label}
											</div>
											<textarea
												name={item.name}
												value={item.value}
												style={{
													resize: "vertical",
													minHeight: "300px",
													maxHeight: "800px",
												}}
												onChange={(e) => this.editFormData(item.name, e.target.value)}
											/>
											{errorMessage}
										</div>
									);
								case "offerInput":
									return (
										<>
											<div
												className='field-wrapper'
												style={{
													display: "flex",
													justifyContent: "center",
													flexFlow: "row wrap",
													width: "100%",
												}}
												key={key}>
												<div style={{ width: "100%", display: "inline-block" }}>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
															"& > *": {
																m: 1,
															},
														}}>
														<ButtonGroup
															variant='outlined'
															aria-label='outlined button group'>
															<Button
																style={
																	this.state.isNew
																		? { backgroundColor: "transparent" }
																		: { backgroundColor: "#7a0000", color: "#fff" }
																}
																onClick={() => this.oldMethod()}>
																Inserimento testuale
															</Button>
															<Button
																style={
																	this.state.isNew
																		? { backgroundColor: "#7a0000", color: "#fff" }
																		: { backgroundColor: "transparent" }
																}
																onClick={() => this.newMethod()}>
																Listino
															</Button>
														</ButtonGroup>
													</Box>
												</div>

												<div style={{ width: "100%" }}>
													{this.state.isNew ? (
														<div
															style={{
																display: "flex",
																justifyContent: "center",
																minHeight: 300,
																background: "#efefef",
															}}>
															<TableDemo
																ref={this.child}
																editFormData={this.editFormData.bind(this)}
																formData={this.state.formData}
																confirmForm={this.confirmForm.bind(this)}
															/>
														</div>
													) : (
														<div
															style={{
																display: "flex",
																justifyContent: "center",
																minHeight: 300,
															}}>
															<div className='input-wrapper half-width'>
																<div className='label'>{item.labelOfferDescription}</div>
																<textarea
																	name={item.nameOfferDescription}
																	className='large'
																	value={item.valueOfferDescription}
																	onChange={(e) =>
																		this.editFormData(
																			item.nameOfferDescription,
																			e.target.value,
																			true,
																			"valueOfferDescription",
																			"nameOfferDescription"
																		)
																	}
																/>
															</div>
															<div className='input-wrapper half-width'>
																<div className='label'>{item.labelOfferValue}</div>
																<textarea
																	name={item.nameOfferValue}
																	className='large'
																	value={item.valueOfferPrice}
																	onChange={(e) =>
																		this.editFormData(
																			item.nameOfferValue,
																			e.target.value,
																			true,
																			"valueOfferPrice",
																			"nameOfferValue"
																		)
																	}
																/>
															</div>
														</div>
													)}
												</div>
											</div>
										</>
									);

								default:
									return false;
							}
						})}
					</div>
					<div className='input-wrapper small'>
						<button
							type='button'
							value='Conferma'
							style={{
								display: "flex",
								width: "15em",
								justifyContent: "center",
								margin: "0 auto",
								textAlign: "center",
							}}
							onClick={() => this.savelist()}>
							<span> Conferma </span>
						</button>
					</div>
				</div>
			</div>
		);
	}
}
