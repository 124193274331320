import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import Dropzone from "react-dropzone";

import Loading from "./../components/Loading.js";

import "react-table/react-table.css";

export default class AdminStandardsAndDirectives extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			accessToken: props.accessToken,
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
			standardsAndDirectivesList: [],
			standardsAndDirectivesListMessage: "",

			showFormModal: false,
			formModalTitle: "",
			formModalAction: "",

			showFilesModal: false,
			filesModalTitle: "Gestione file",
			filesModalStandardOrDirective: {},
			filesToUpload: [],

			formModalFieldId: null,
			formModalFieldTitle: "",

			showFormError: false,
			formErrorMessage: "",
		};

		this.handleOnDrop = this.handleOnDrop.bind(this);
		this.removeStandardsAndDirectivesFile =
			this.removeStandardsAndDirectivesFile.bind(this);
	}

	componentWillUnmount() {
		document.body.classList.remove("no-background");
		document.body.classList.remove("page-with-filter-bar");
	}

	componentDidMount() {
		document.body.classList.add("no-background");
		document.body.classList.add("page-with-filter-bar");

		document.title =
			Globals.titles.adminStandardsAndDirectives[this.state.language];

		window.scrollTo(0, 0);

		this.getStandardsAndDirectivesList();
	}

	componentWillReceiveProps(props) {
		this.setState({
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
		});
		document.title = Globals.titles.adminStandardsAndDirectives[props.language];
	}

	getStandardsAndDirectivesList() {
		// Reset state
		this.setState({
			standardsAndDirectivesList: [],
			standardsAndDirectivesListMessage: "",
		});

		// Fetch api for user's orders
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=getStandardsAndDirectives&access_token=" +
			accessToken;

		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					this.setState({
						standardsAndDirectivesList: [],
						standardsAndDirectivesListMessage: responseJson.message,
					});
				} else {
					// Call is ok, store ordersData in state
					this.setState({
						standardsAndDirectivesList: responseJson.standardsAndDirectives,
						standardsAndDirectivesListMessage: "",
					});
				}
			})
			.catch((error) => {
				// Communication error - handle message
				this.setState({
					standardsAndDirectivesList: [],
					standardsAndDirectivesListMessage:
						Globals.errors.serverCall[this.state.language],
				});
			});
	}

	removeStandardsAndDirectivesFile(fileId) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteStandardsAndDirectivesFile&access_token=" +
				accessToken;

			const formdata = new FormData();

			formdata.append("fileId", fileId);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.toggleFilesHandler();

						this.setState({
							loading: false,
						});

						this.getStandardsAndDirectivesList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	openFilesHandler(listPosition) {
		this.toggleFilesHandler();

		let standardOrDirective = cloneDeep(
			this.state.standardsAndDirectivesList[listPosition]
		);

		this.setState({
			filesModalStandardOrDirective: standardOrDirective,
		});
	}

	toggleFilesHandler() {
		let showFilesModal = !this.state.showFilesModal;

		this.setState({
			showFilesModal: showFilesModal,
			filesModalStandardOrDirective: {},
			filesToUpload: [],
		});

		window.scrollTo(0, 0);
	}

	newStandardOrDirective() {
		this.toggleFormModal("Nuova norma o direttiva", "newStandardOrDirective");
	}

	editStandardOrDirective(listPosition) {
		this.toggleFormModal("Modifica norma o direttiva", "editStandardOrDirective");

		let standardOrDirective = cloneDeep(
			this.state.standardsAndDirectivesList[listPosition]
		);

		this.setState({
			formModalFieldId: standardOrDirective.id,
			formModalFieldTitle: standardOrDirective.title,
		});
	}

	deleteStandardOrDirective(standardOrDirectiveId) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteStandardOrDirective&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("standardOrDirectiveId", standardOrDirectiveId);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getStandardsAndDirectivesList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	toggleFormModal(title, action) {
		let showFormModal = !this.state.showFormModal;

		this.setState({
			showFormModal: showFormModal,
			formModalTitle: title,
			formModalAction: action,

			formModalFieldId: null,
			formModalFieldTitle: "",

			showFormError: false,
			formErrorMessage: "",
		});

		window.scrollTo(0, 0);
	}

	setInputErrorMessage(field, message) {
		switch (field) {
			case "stdError":
				this.setState({
					showFormError: true,
					formErrorMessage: message,
				});
				break;
			default:
				break;
		}
	}

	formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	handleOnDrop(files) {
		this.setState({
			loading: true,
		});

		// Fetch api
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=uploadStandardOrDirectiveFile&access_token=" +
			accessToken;

		const formdata = new FormData();

		formdata.append(
			"standardOrDirectiveId",
			this.state.filesModalStandardOrDirective.id
		);

		files.forEach((file) => {
			formdata.append("uploadedFiles[]", file, file.name);
		});

		fetch(url, {
			method: "POST",
			body: formdata,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					toast(responseJson.message, {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				} else {
					// Call is ok
					toast("Operazione completata", {
						autoClose: 4000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.toggleFilesHandler();

					this.setState({
						loading: false,
					});

					this.getStandardsAndDirectivesList();
				}
			})
			.catch((error) => {
				// Communication error - handle message
				toast(Globals.errors.serverCall[this.state.language], {
					autoClose: 8000,
					className: "std-black-toast",
					bodyClassName: "std-black-toast-body",
					progressClassName: "std-black-toast-progress-bar",
				});

				this.setState({
					loading: false,
				});
			});
	}

	confirmStandardOrDirectiveForm() {
		if (window.confirm("Confermi i dati forniti?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;
			let formAction = this.state.formModalAction;

			let standardOrDirectiveData = {
				id: this.state.formModalFieldId,
				title: this.state.formModalFieldTitle,
			};

			if (standardOrDirectiveData.title === "") {
				this.setInputErrorMessage("stdError", "Devi indicare il titolo");
				this.setState({
					loading: false,
				});
				return;
			}

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=" +
				formAction +
				"&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append(
				"standardOrDirectiveData",
				JSON.stringify(standardOrDirectiveData)
			);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.toggleFormModal();

						this.setState({
							loading: false,
						});

						this.getStandardsAndDirectivesList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	render() {
		if (this.state.isLogged !== true || this.state.isAdmin !== true) {
			return <Redirect to={{ pathname: "/login" }} />;
		}

		if (this.state.productsList === null) {
			return <Loading />;
		}

		let loadingOnScreen;
		if (this.state.loading === true) {
			loadingOnScreen = <Loading />;
		}

		let standardsAndDirectivesList = this.state.standardsAndDirectivesList;
		let standardsAndDirectivesListMessage =
			this.state.standardsAndDirectivesListMessage;
		let standardsAndDirectivesRender = "";
		if (standardsAndDirectivesListMessage !== "") {
			standardsAndDirectivesRender = (
				<div className='message-wrapper'>{standardsAndDirectivesListMessage}</div>
			);
		} else {
			// Prepare data for the table;
			const columns = [
				{
					Header: "Titolo",
					accessor: "title",
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: " ",
					accessor: "filesIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "editIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "deleteIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
			];
			const data = [];
			for (let c = 0; c < standardsAndDirectivesList.length; c++) {
				// Push data
				data.push({
					title: standardsAndDirectivesList[c].title,
					filesIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-file-pdf-o fa-2x'
							onClick={() => {
								this.openFilesHandler(c);
							}}></i>
					),
					editIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-edit fa-2x'
							onClick={() => {
								this.editStandardOrDirective(c);
							}}></i>
					),
					deleteIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-trash fa-2x'
							onClick={() => {
								this.deleteStandardOrDirective(standardsAndDirectivesList[c].id);
							}}></i>
					),
				});
			}

			let tableRows = 5;
			if (standardsAndDirectivesList.length > 0) {
				tableRows = standardsAndDirectivesList.length;
			}

			// Render Table
			standardsAndDirectivesRender = (
				<ReactTable
					data={data}
					columns={columns}
					showPagination={false}
					pageSize={tableRows}
					previousText={Globals.reactTableText.previous[this.state.language]}
					nextText={Globals.reactTableText.next[this.state.language]}
					loadingText={Globals.reactTableText.loading[this.state.language]}
					noDataText={Globals.reactTableText.noData[this.state.language]}
					pageText={Globals.reactTableText.page[this.state.language]}
					ofText={Globals.reactTableText.of[this.state.language]}
					rowsText={Globals.reactTableText.rows[this.state.language]}
					pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
					rowsSelectorText={
						Globals.reactTableText.rowsSelectorText[this.state.language]
					}
				/>
			);
		}

		let filesModal;
		if (this.state.showFilesModal === true) {
			let apiBaseGetUploadedFileUrl =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUploadedFile&access_token=" +
				this.state.accessToken +
				"&type=standards_and_directives&fileId=";

			filesModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.filesModalTitle}{" "}
							<span className='modalTitleBarAndClient'>
								{" "}
								{this.state.filesModalStandardOrDirective.title || ""}{" "}
							</span>
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFilesHandler()}></i>
							</div>
						</div>
						<div className='content'>
							<Dropzone onDrop={this.handleOnDrop} accept='application/pdf'>
								{({ getRootProps, getInputProps, isDragActive }) => (
									<section>
										<div
											{...getRootProps()}
											className={
												"drag-and-drop-wrapper " + (isDragActive ? "active" : "")
											}>
											<input {...getInputProps()} />
											<p>
												Trascina qui i tuoi file o fai click <span>qui</span> per
												selezionarli
												<span className='small'>(File accettati: .pdf)</span>
											</p>
										</div>
									</section>
								)}
							</Dropzone>
							{this.state.filesModalStandardOrDirective.files !== undefined ? (
								<div className='uploaded-files-wrapper'>
									{this.state.filesModalStandardOrDirective.files.map((item, key) => {
										return (
											<div key={key} className='file'>
												<div className='icon'>
													<div className='pdfIcon'>
														<i className='fa fa-file-pdf-o'></i>{" "}
													</div>
												</div>
												<div className='name'>{item.title}</div>
												<div className='buttons'>
													<a
														href={apiBaseGetUploadedFileUrl + item.id}
														target='_blank'
														rel='noopener noreferrer'>
														<button type='button'>
															<i className='fa fa-eye'></i>
														</button>
													</a>
													<button
														type='button'
														onClick={() => this.removeStandardsAndDirectivesFile(item.id)}>
														<i className='fa fa-trash'></i>
													</button>
												</div>
											</div>
										);
									})}
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			);
		}

		let formModal;
		if (this.state.showFormModal === true) {
			let formErrorField;
			if (this.state.showFormError === true) {
				formErrorField = (
					<div className='input-wrapper full-width centered'>
						<div className='input-error-wrapper'>{this.state.formErrorMessage}</div>
					</div>
				);
			}

			formModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body small'>
						<div className='titleBar'>
							{this.state.formModalTitle}
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFormModal("", "")}></i>
							</div>
						</div>
						<div className='content'>
							{formErrorField}
							<div className='input-wrapper'>
								<div className='label'>Titolo</div>
								<input
									type='text'
									name='title'
									value={this.state.formModalFieldTitle}
									onChange={(e) =>
										this.setState({ formModalFieldTitle: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper full-width small'>
								<button
									type='button'
									onClick={() => this.confirmStandardOrDirectiveForm()}>
									<span>Conferma</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='users-page-wrapper'>
				<div className='filters'>
					<div className='filters-wrapper'>
						<div className='filter-wrapper'>
							<div className='filter'>
								<button type='button' onClick={() => this.newStandardOrDirective()}>
									<span>Nuovo</span>
								</button>
								<span className='title-text'>GESTIONE NORME E DIRETTIVE</span>
							</div>
						</div>
					</div>
				</div>
				{standardsAndDirectivesRender}
				{loadingOnScreen}
				{formModal}
				{filesModal}
			</div>
		);
	}
}
