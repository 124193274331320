import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import Dropzone from "react-dropzone";
import Loading from "./../components/Loading.js";
import Switch from "./../components/Switch";

import img0 from "./../assets/img/0.png";
import img1 from "./../assets/img/1.png";

import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import it from "date-fns/locale/it";

registerLocale("it", it);

export default class ConfirmedQuotations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			accessToken: props.accessToken,
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			isOperator: props.isOperator,
			language: props.language,
			list: [],
			listMessage: "",

			showInspectionsModal: false,
			inspectionsModalTitle: "Sopralluoghi correlati",
			modalInspectionsList: [],

			showFilesModal: false,
			filesModalTitle: "File caricati",
			filesModalQuotation: {},
			filesToUpload: [],

			showFormModal: false,
			formModalTitle: "",
			formModalAction: "",

			formModalFieldHandlingEquipment: false,
			formModalFieldId: null,
			formModalFieldAppointees: "",
			formModalFieldOperationDate: null,
			formModalFieldJobSheetNumber: "",
			formModalFieldNotes: "",

			showFormError: false,
			formErrorMessage: "",
		};

		this.handleOperationDateChange = this.handleOperationDateChange.bind(this);
		this.handleOnDrop = this.handleOnDrop.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.handleEquipmentActiveChange =
			this.handleEquipmentActiveChange.bind(this);
	}

	componentWillUnmount() {
		document.body.classList.remove("no-background");
		document.body.classList.remove("page-with-filter-bar");
	}

	componentDidMount() {
		document.body.classList.add("no-background");
		document.body.classList.add("page-with-filter-bar");

		document.title = Globals.titles.confirmedQuotations[this.state.language];

		window.scrollTo(0, 0);

		this.getList();
	}

	componentWillReceiveProps(props) {
		this.setState({
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			isOperator: props.isOperator,
			language: props.language,
		});
		document.title = Globals.titles.confirmedQuotations[props.language];
	}

	getList(specialAction) {
		// Reset state
		this.setState({
			list: [],
			listMessage: "",
			loading: true,
		});

		// Fetch api for user's orders
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=getConfirmedQuotations&access_token=" +
			accessToken;

		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					this.setState({
						list: [],
						listMessage: responseJson.message,
						loading: false,
					});
				} else {
					// Call is ok, store ordersData in state
					this.setState({
						list: responseJson.quotations,
						listMessage: "",
						loading: false,
					});

					if (undefined !== specialAction) {
						switch (specialAction) {
							case "reloadFiles":
								let actualFilesElement = this.state.filesModalQuotation;

								let refreshedElement = cloneDeep(
									this.state.list.find((element) => {
										return parseInt(element.id) === parseInt(actualFilesElement.id);
									})
								);

								this.setState({
									filesModalQuotation: refreshedElement,
									loading: false,
								});
								break;
							default:
								break;
						}
					}
				}
			})
			.catch((error) => {
				// Communication error - handle message
				this.setState({
					list: [],
					listMessage: Globals.errors.serverCall[this.state.language],
					loading: false,
				});
			});
	}

	handleEquipmentActiveChange() {
		let isActive = this.state.formModalFieldHandlingEquipment;
		this.setState({
			formModalFieldHandlingEquipment: !isActive,
		});
	}

	handleOnDrop(files) {
		this.setState({
			loading: true,
		});

		// Fetch api
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=uploadQuotationFile&access_token=" +
			accessToken;

		const formdata = new FormData();

		formdata.append("id", this.state.filesModalQuotation.id);

		files.forEach((file) => {
			formdata.append("uploadedFiles[]", file, file.name);
		});

		fetch(url, {
			method: "POST",
			body: formdata,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					toast(responseJson.message, {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				} else {
					// Call is ok
					toast("Operazione completata", {
						autoClose: 4000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});

					//this.toggleFilesHandler();
					this.getList("reloadFiles");
				}
			})
			.catch((error) => {
				// Communication error - handle message
				toast(Globals.errors.serverCall[this.state.language], {
					autoClose: 8000,
					className: "std-black-toast",
					bodyClassName: "std-black-toast-body",
					progressClassName: "std-black-toast-progress-bar",
				});

				this.setState({
					loading: false,
				});
			});
	}

	removeFile(fileId) {
		if (window.confirm("Confermi l'eliminazione?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteQuotationFile&access_token=" +
				accessToken;

			const formdata = new FormData();

			formdata.append("fileId", fileId);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						//this.toggleFilesHandler();
						this.getList("reloadFiles");
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	openFilesHandler(listPosition) {
		this.toggleFilesHandler();

		let quotation = cloneDeep(this.state.list[listPosition]);

		this.setState({
			filesModalQuotation: quotation,
		});
	}

	toggleFilesHandler() {
		let showFilesModal = !this.state.showFilesModal;

		this.setState({
			showFilesModal: showFilesModal,
			filesModalQuotation: {},
		});
	}

	openQuotation(id) {
		if (window.confirm("Confermi di voler riaprire il preventivo?")) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=openQuotation&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	openInspectionsViewer(listPosition) {
		this.toggleInspectionsHandler();

		let quotation = cloneDeep(this.state.list[listPosition]);

		this.setState({
			modalInspectionsList: quotation.inspections,
		});
	}

	toggleInspectionsHandler() {
		let showInspectionsModal = !this.state.showInspectionsModal;

		this.setState({
			showInspectionsModal: showInspectionsModal,
			modalInspectionsList: [],
		});
	}

	doQuotation(id) {
		if (
			window.confirm(
				"Confermi di voler modificare lo stato di esecuzione del preventivo?"
			)
		) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=doQuotation&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	toggleOperationDateConfirmed(id) {
		if (
			window.confirm(
				"Confermi di voler modificare lo stato della data intervento?"
			)
		) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=toggleOperationDataConfirmed&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}
	toggleHandlingEquipment(id) {
		if (
			window.confirm(
				"Confermi di voler modificare lo stato dei mezzi di elevazione per questo preventivo confermato?"
			)
		) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=toggleOperationHandlingEquipment&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("id", id);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	editConfirmedQuotation(listPosition) {
		this.toggleFormModal(
			"Modifica preventivo confermato",
			"editConfirmedQuotation"
		);

		let quotation = cloneDeep(this.state.list[listPosition]);

		let report_active_equipment = false;
		if (parseInt(quotation.handling_equipment) === 1) {
			report_active_equipment = true;
		}

		this.setState({
			formModalFieldId: quotation.id,
			formModalFieldAppointees: quotation.appointees,
			formModalFieldOperationDate:
				quotation.operation_date !== null
					? new Date(quotation.operation_date)
					: null,
			formModalFieldJobSheetNumber: quotation.job_sheet_number,
			formModalFieldNotes: quotation.notes,
			formModalFieldHandlingEquipment:
				parseInt(quotation.handling_equipment) === 0 ? false : true,
		});
	}

	toggleFormModal(title, action) {
		let showFormModal = this.state.showFormModal;

		if (showFormModal === true) {
			showFormModal = false;
		} else {
			showFormModal = true;
		}

		this.setState({
			showFormModal: showFormModal,
			formModalTitle: title,
			formModalAction: action,

			formModalFieldId: null,
			formModalFieldAppointees: "",
			formModalFieldOperationDate: null,
			formModalFieldJobSheetNumber: "",
			formModalFieldNotes: "",
			formModalFieldHandlingEquipment: false,

			showFormError: false,
			formErrorMessage: "",
		});
	}

	handleOperationDateChange(date) {
		this.setState({
			formModalFieldOperationDate: date,
		});
	}

	formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	confirmForm() {
		if (window.confirm("Confermi i dati forniti?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;
			let formAction = this.state.formModalAction;

			let formData = {
				id: this.state.formModalFieldId,
				appointees: this.state.formModalFieldAppointees,
				operation_date:
					this.state.formModalFieldOperationDate !== null
						? this.formatDate(this.state.formModalFieldOperationDate)
						: null,
				job_sheet_number: this.state.formModalFieldJobSheetNumber,
				notes: this.state.formModalFieldNotes,
				handling_equipment: this.state.formModalFieldHandlingEquipment,
			};

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=" +
				formAction +
				"&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("formData", JSON.stringify(formData));

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});

						this.toggleFormModal();
						this.getList();
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	render() {
		if (
			this.state.isLogged !== true ||
			(this.state.isAdmin !== true && this.state.isOperator !== true)
		) {
			return <Redirect to={{ pathname: "/login" }} />;
		}

		let loadingOnScreen;
		if (this.state.loading === true) {
			loadingOnScreen = <Loading />;
		}

		let list = this.state.list;
		let listMessage = this.state.listMessage;
		let bodyRender = "";
		if (listMessage !== "") {
			bodyRender = <div className='message-wrapper'>{listMessage}</div>;
		} else {
			// Prepare data for the table;
			const columns = [
				{
					Header: "Id",
					accessor: "id",
					show: false,
				},
				{
					Header: "Eseguito",
					accessor: "done",
					headerStyle: { textAlign: "center" },
					width: 100,
					Cell: (row) => (
						<div style={{ textAlign: "center" }}>
							{" "}
							{row.value === "1" ? (
								<img
									src={img1}
									className='std-hoverable-cursor-pointer'
									alt={"Attivo"}
									onClick={() => {
										this.doQuotation(row.row["id"]);
									}}
								/>
							) : (
								<img
									src={img0}
									className='std-hoverable-cursor-pointer'
									alt={"Non Attivo"}
									onClick={() => {
										this.doQuotation(row.row["id"]);
									}}
								/>
							)}{" "}
						</div>
					),
					filterable: true,
					filterMethod: (filter, row) => {
						if (filter.value === "Tutti") {
							return true;
						}
						if (filter.value === "1") {
							return row[filter.id] === "1";
						}
						if (filter.value === "0") {
							return row[filter.id] === "0";
						}
					},
					Filter: ({ filter, onChange }) => (
						<select
							onChange={(event) => onChange(event.target.value)}
							style={{ width: "100%", textAlign: "center" }}
							value={filter ? filter.value : "Tutti"}>
							<option value='Tutti'>Tutti</option>
							<option value='1'>Eseguiti</option>
							<option value='0'>Non Eseguiti</option>
						</select>
					),
				},
				{
					Header: "Data",
					accessor: "date",
					width: 100,
					Cell: (c) => <span>{moment(c.original.date).format("DD/MM/YYYY")}</span>,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Accettazione",
					accessor: "acceptance_date",
					width: 150,
					Cell: (c) =>
						c.original.acceptance_date !== null ? (
							<span>{moment(c.original.acceptance_date).format("DD/MM/YYYY")}</span>
						) : (
							<span></span>
						),
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Utente",
					accessor: "user",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Num. off.",
					accessor: "number",
					style: { overflow: "visible", whiteSpace: "normal" },
					width: 100,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Titolo",
					accessor: "title",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Incar.",
					accessor: "appointees",
					width: 70,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Data int.",
					accessor: "operation_date",
					width: 100,
					Cell: (c) =>
						c.original.operation_date !== null ? (
							<span>{moment(c.original.operation_date).format("DD/MM/YYYY")} </span>
						) : (
							false
						),
					filterable: true,
					filterMethod: (filter, row) => {
						if (row[filter.id] !== null) {
							return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
						}
					},
				},
				{
					Header: "D.c.",
					accessor: "operation_date_confirmed",
					headerStyle: { textAlign: "center" },
					width: 100,
					Cell: (row) => (
						<div style={{ textAlign: "center" }}>
							{" "}
							{row.value === "1" ? (
								<img
									src={img1}
									className='std-hoverable-cursor-pointer'
									alt={"Attivo"}
									onClick={() => {
										this.toggleOperationDateConfirmed(row.row["id"]);
									}}
								/>
							) : (
								<img
									src={img0}
									className='std-hoverable-cursor-pointer'
									alt={"Non Attivo"}
									onClick={() => {
										this.toggleOperationDateConfirmed(row.row["id"]);
									}}
								/>
							)}{" "}
						</div>
					),
					filterable: true,
					filterMethod: (filter, row) => {
						if (filter.value === "Tutti") {
							return true;
						}
						if (filter.value === "1") {
							return row[filter.id] === "1";
						}
						if (filter.value === "0") {
							return row[filter.id] === "0";
						}
					},
					Filter: ({ filter, onChange }) => (
						<select
							onChange={(event) => onChange(event.target.value)}
							style={{ width: "100%", textAlign: "center" }}
							value={filter ? filter.value : "Tutti"}>
							<option value='Tutti'>Tutti</option>
							<option value='1'>Confermati</option>
							<option value='0'>Non Confermati</option>
						</select>
					),
				},
				{
					Header: "M. E.",
					accessor: "handling_equipment",
					headerStyle: { textAlign: "center" },
					width: 100,
					Cell: (row) => (
						<div style={{ textAlign: "center" }}>
							{" "}
							{row.value === "1" ? (
								<img
									src={img1}
									className='std-hoverable-cursor-pointer'
									alt={"Attivo"}
									onClick={() => {
										this.toggleHandlingEquipment(row.row["id"]);
									}}
								/>
							) : (
								<img
									src={img0}
									className='std-hoverable-cursor-pointer'
									alt={"Non Attivo"}
									onClick={() => {
										this.toggleHandlingEquipment(row.row["id"]);
									}}
								/>
							)}{" "}
						</div>
					),
					filterable: true,
					filterMethod: (filter, row) => {
						if (filter.value === "Tutti") {
							return true;
						}
						if (filter.value === "1") {
							return row[filter.id] === "1";
						}
						if (filter.value === "0") {
							return row[filter.id] === "0";
						}
					},
					Filter: ({ filter, onChange }) => (
						<select
							onChange={(event) => onChange(event.target.value)}
							style={{ width: "100%", textAlign: "center" }}
							value={filter ? filter.value : "Tutti"}>
							<option value='Tutti'>Tutti</option>
							<option value='1'>Mezzi Elevazione</option>
							<option value='0'>Nessun Mezzo</option>
						</select>
					),
				},
				{
					Header: "N.s.l.",
					accessor: "job_sheet_number",
					width: 70,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Note",
					accessor: "notes",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "n°allegati",
					accessor: "attachments",
					width: 120,
					Cell: (row) => (
						<div style={{ textAlign: "center", fontSize: 15 }}>{row.value}</div>
					),
				},
				{
					Header: " ",
					accessor: "inspectionsIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "filesIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "editIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "openIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
			];
			const data = [];
			for (let c = 0; c < list.length; c++) {
				data.push({
					id: list[c].id,
					done: list[c].done,
					date: list[c].date,
					acceptance_date: list[c].acceptance_date,
					user: list[c].user.company,
					number: list[c].number,
					title: list[c].title,
					appointees: list[c].appointees,
					operation_date: list[c].operation_date,
					operation_date_confirmed: list[c].operation_date_confirmed,
					handling_equipment: list[c].handling_equipment,
					job_sheet_number: list[c].job_sheet_number,
					notes: list[c].notes,
					attachments: list[c].files.length,
					inspectionsIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-eye fa-2x'
							onClick={() => {
								this.openInspectionsViewer(c);
							}}></i>
					),
					filesIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-file-pdf-o fa-2x'
							onClick={() => {
								this.openFilesHandler(c);
							}}></i>
					),
					editIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-edit fa-2x'
							onClick={() => {
								this.editConfirmedQuotation(c);
							}}></i>
					),
					openIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-undo fa-2x'
							onClick={() => {
								this.openQuotation(list[c].id);
							}}></i>
					),
				});
			}
			// Render Table
			bodyRender = (
				<ReactTable
					data={data}
					columns={columns}
					pageSizeOptions={[50, 100, 200]}
					page={this.state.page}
					onPageChange={(page) => this.setState({ page })}
					defaultPageSize={100}
					previousText={Globals.reactTableText.previous[this.state.language]}
					nextText={Globals.reactTableText.next[this.state.language]}
					loadingText={Globals.reactTableText.loading[this.state.language]}
					noDataText={Globals.reactTableText.noData[this.state.language]}
					pageText={Globals.reactTableText.page[this.state.language]}
					ofText={Globals.reactTableText.of[this.state.language]}
					rowsText={Globals.reactTableText.rows[this.state.language]}
					pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
					rowsSelectorText={
						Globals.reactTableText.rowsSelectorText[this.state.language]
					}
				/>
			);
		}

		let inspectionsModal;
		if (this.state.showInspectionsModal === true) {
			let apiBaseGetUploadedFileUrl =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUploadedFile&access_token=" +
				this.state.accessToken +
				"&type=inspections&fileId=";

			inspectionsModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.inspectionsModalTitle}
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleInspectionsHandler()}></i>
							</div>
						</div>
						<div className='content'>
							{this.state.modalInspectionsList.length > 0 ? (
								<div className='inspections-list'>
									{this.state.modalInspectionsList.map((item, key) => {
										return (
											<div key={key} className='inspection'>
												<div className='title'>
													{moment(item.date).format("DD/MM/YYYY")} - {item.user.company} -{" "}
													{item.inspection_code || ""} {item.title}
												</div>
												<div className='description'>{item.description}</div>
												{item.files !== undefined ? (
													<div className='uploaded-files-wrapper'>
														{item.files.map((item2, key2) => {
															return (
																<div key={key2} className='file'>
																	<div className='icon'>
																		{item2.url.split(".").pop() === "jpg" ||
																		item2.url.split(".").pop() === "png" ||
																		item2.url.split(".").pop() === "jpeg" ? (
																			<img
																				src={item2.url}
																				className='previewImage'
																				alt='preventivo-confermato'
																			/>
																		) : (
																			<div className='pdfIcon'>
																				<i className='fa fa-file-pdf-o' />
																			</div>
																		)}
																	</div>
																	<div className='name'>{item2.title}</div>
																	<div className='buttons'>
																		<a
																			href={apiBaseGetUploadedFileUrl + item2.id}
																			target='_blank'
																			rel='noopener noreferrer'>
																			<button className='full-size' type='button'>
																				<i className='fa fa-eye'></i>
																			</button>
																		</a>
																	</div>
																</div>
															);
														})}
													</div>
												) : (
													<div></div>
												)}
											</div>
										);
									})}
								</div>
							) : (
								<div className='modal-message'>Nessun sopralluogo correlato</div>
							)}
						</div>
					</div>
				</div>
			);
		}

		let filesModal;
		if (this.state.showFilesModal === true) {
			let apiBaseGetUploadedFileUrl =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUploadedFile&access_token=" +
				this.state.accessToken +
				"&type=quotations&fileId=";

			filesModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.filesModalTitle}{" "}
							<span className='modalTitleBarAndClient'>
								{" "}
								{this.state.filesModalQuotation.title || ""} --{" "}
								{this.state.filesModalQuotation.user.company || ""}{" "}
							</span>
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFilesHandler()}></i>
							</div>
						</div>
						<div className='content'>
							<Dropzone onDrop={this.handleOnDrop} accept='application/pdf, image/*'>
								{({ getRootProps, getInputProps, isDragActive }) => (
									<section>
										<div
											{...getRootProps()}
											className={
												"drag-and-drop-wrapper " + (isDragActive ? "active" : "")
											}>
											<input {...getInputProps()} />
											<p>
												Trascina qui i tuoi file o fai click <span>qui</span> per
												selezionarli
												<span className='small'>
													(File accettati: .pdf, .jpeg, .jpg, .png, .gif)
												</span>
											</p>
										</div>
									</section>
								)}
							</Dropzone>
							{this.state.filesModalQuotation.files !== undefined ? (
								<div className='uploaded-files-wrapper'>
									{this.state.filesModalQuotation.files.map((item, key) => {
										return (
											<div key={key} className='file'>
												<div className='icon'>
													{item.url.split(".").pop() === "jpg" ||
													item.url.split(".").pop() === "png" ||
													item.url.split(".").pop() === "jpeg" ? (
														<img
															src={item.url}
															className='previewImage'
															alt='preventivo-confermato'
														/>
													) : (
														<div className='pdfIcon'>
															<i className='fa fa-file-pdf-o' />
														</div>
													)}
												</div>
												<div className='name'>{item.title}</div>
												<div className='buttons'>
													<a
														href={apiBaseGetUploadedFileUrl + item.id}
														target='_blank'
														rel='noopener noreferrer'>
														<button type='button'>
															<i className='fa fa-eye'></i>
														</button>
													</a>
													<button type='button' onClick={() => this.removeFile(item.id)}>
														<i className='fa fa-trash'></i>
													</button>
												</div>
											</div>
										);
									})}
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			);
		}

		let formModal;
		if (this.state.showFormModal === true) {
			let formErrorField;
			if (this.state.showFormError === true) {
				formErrorField = (
					<div className='input-wrapper full-width centered'>
						<div className='input-error-wrapper'>{this.state.formErrorMessage}</div>
					</div>
				);
			}

			formModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body small'>
						<div className='titleBar'>
							{this.state.formModalTitle}
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFormModal("", "")}></i>
							</div>
						</div>
						<div className='content'>
							{formErrorField}
							<div className='input-wrapper full-width centered'>
								<div className='label'>Mezzi di elevazione</div>
								<Switch
									isOn={this.state.formModalFieldHandlingEquipment}
									handleToggle={this.handleEquipmentActiveChange}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Incaricati</div>
								<input
									type='text'
									name='appointees'
									value={this.state.formModalFieldAppointees}
									onChange={(e) =>
										this.setState({ formModalFieldAppointees: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Data intervento</div>
								<DatePicker
									locale={this.state.language}
									selected={this.state.formModalFieldOperationDate}
									dateFormat='dd/MM/yyyy'
									onChange={this.handleOperationDateChange}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Numero scheda lavoro</div>
								<input
									type='text'
									name='job_sheet_number'
									value={this.state.formModalFieldJobSheetNumber}
									onChange={(e) =>
										this.setState({ formModalFieldJobSheetNumber: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Note</div>
								<textarea
									name='notes'
									className='medium'
									value={this.state.formModalFieldNotes}
									onChange={(e) =>
										this.setState({ formModalFieldNotes: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper full-width small'>
								<button type='button' onClick={() => this.confirmForm()}>
									<span>Conferma</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='users-page-wrapper'>
				<div className='filters'>
					<div className='filters-wrapper'>
						<div className='filter-wrapper'>
							<div className='filter'>
								<span className='title-text'>GESTIONE PREVENTIVI CONFERMATI</span>
							</div>
						</div>
					</div>
				</div>
				{bodyRender}
				{loadingOnScreen}
				{formModal}
				{filesModal}
				{inspectionsModal}
			</div>
		);
	}
}
