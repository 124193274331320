import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import CreateIcon from "@material-ui/icons/Create";
import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableFooter from '@mui/material/TableFooter';
// Creating styles
const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    table: {
        minWidth: 500,
    },
    tableRow: {
        height: 50,
        minWidth: 500,
    },
    snackbar: {
        bottom: "75px",
    },
});

function TableDemo(props , ref) {
    // Creating style object
    const classes = useStyles();

    // Defining a state named rows
    // which we can update by calling on setRows function


    const [rows, setRows] = useState([]);

    useEffect(() => {
        (async () => {
        if(props.formData.fields[8].valueOfferList !== ""){

            props.formData.fields[8].valueOfferList.map((item, key) => {

                setRows( rows => [
                    ...rows,
                    {
                        id: key,
                        quotationId : item.quotation_id,
                        description: item.description,
                        value: item.value,
                        position: item.position
                    }
                ]);

            });
            if(props.formData.fields[8].valueTotalPrice !== "") {
                setTotal(
                        props.formData.fields[8].valueTotalPrice,
                );
            }
        }
        })();

        } ,[]);

    // Initial states
    const [open, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(true);
    const [disable, setDisable] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const [total, setTotal] = useState('')

    useEffect(() => {
        props.editFormData("formModalTotal", total, true, 'valueTotalPrice', 'nameTotalPrice' );
    }, [total])
    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };



    // Function For adding new row object
    const handleAdd = () => {
        setRows([
            ...rows,
            {
                id: rows.length + 1, description: "",
                value: ""
            },
        ]);
        setEdit(true);
    };

    // Function to handle edit
    const handleEdit = (i) => {
        // If edit mode is true setEdit will
        // set it to false and vice versa
        setEdit(!isEdit);
    };

    // Function to handle save
    const handleSave = () => {
        setEdit(!isEdit);
        setRows(rows);
        // console.log("saved : ", rows);
        setDisable(true);
        setOpen(true);
        props.editFormData("formModalOffer", rows, true, "valueOfferList", "nameList" );
    };



    useImperativeHandle(ref, () => ({
         handleSave () {
            setEdit(!isEdit);
            setRows(rows);
            setDisable(true);
            setOpen(true);
            props.editFormData("formModalOffer", rows, true, "valueOfferList", "nameList" );
        }
    }));



    // The handleInputChange handler can be set up to handle
    // many different inputs in the form, listen for changes
    // to input elements and record their values in state
    const handleInputChange = (e, index) => {
        setDisable(false);
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        // console.log(list)
        setRows(list);

    };

    // Showing delete confirmation to users
    const handleConfirm = () => {
        setShowConfirm(true);
    };

    // Handle the case of delete confirmation where
    // user click yes delete a specific row of id:i

    const handleRemoveClick = (id) => {
        if(window.confirm("Confermi la rimozione?")) {
            const list = [...rows];
            list.splice(id, 1);
            setRows(list);
            setShowConfirm(false);

            props.editFormData("formModalOffer", list, true, "valueOfferList", "nameList");
        }
    };

    // Handle the case of delete confirmation
    // where user click no
    const handleNo = () => {
        setShowConfirm(false);
    };

    return (
        <div >
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                className={classes.snackbar}
            >
                <Alert onClose={handleClose} severity="success">
                    Elemento aggiunto correttamente!
                </Alert>
            </Snackbar>
            <Box margin={1}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                        {isEdit ? (
                            <div style={{display:"flex"}}>
                                <Button onClick={handleAdd}>
                                    <AddBoxIcon onClick={handleAdd} />
                                    Aggiungi
                                </Button>
                                {rows.length !== 0 && (
                                    <div>
                                        {disable ? (
                                            <Button disabled align="right" onClick={handleSave}>
                                                <DoneIcon />
                                                Salva
                                            </Button>
                                        ) : (
                                            <Button align="right" onClick={handleSave}>
                                                <DoneIcon />
                                                Salva
                                            </Button>

                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{display:"flex"}}>
                                <Button onClick={handleAdd}>
                                    <AddBoxIcon onClick={handleAdd} />
                                    Aggiungi riga
                                </Button>
                                <Button align="right" onClick={handleEdit}>
                                    <CreateIcon />
                                    Modifica
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <TableRow align="center"> </TableRow>
                <Table
                    className={classes.table}
                    size="large"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <div>
                            <TableCell width="50%">Descrizione</TableCell>
                            <TableCell width="40%">Prezzo</TableCell>
                            <TableCell width="10%">Elimina</TableCell>
                            </div>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {rows.map((row, i ) => {
                            return (
                                <div>
                                    <TableRow
                                        key={row.position}
                                    >
                                        {isEdit ? (
                                            <div>
                                                <TableCell padding="none"  style={{minWidth:400}}>
                                                    <input
                                                        style={{width:350}}
                                                        value={row.description}
                                                        className='large'
                                                        name="description"
                                                        onChange={(e) => handleInputChange(e, i)}

                                                    />
                                                </TableCell>
                                                <TableCell padding="none" style={{minWidth:275}}>
                                                    <input
                                                        style={{width:150}}
                                                        value={row.value}
                                                        name="value"
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />

                                                </TableCell>
                                                <TableCell padding="1" style={{minWidth:130}}>
                                                    <Button
                                                        onClick={() => handleRemoveClick(i) }
                                                        color="primary"
                                                    >
                                                        <ClearIcon />
                                                    </Button>

                                                </TableCell>
                                            </div>
                                        ) : (
                                            <div>
                                                <TableCell padding="none" style={{minWidth:400}}>
                                                    <div style={{marginLeft:"10px", maxWidth:300, overflow: "hidden", textOverflow:"ellipse"}}>
                                                    {row.description}
                                                    </div>
                                                </TableCell>
                                                <TableCell padding="none" style={{minWidth:275}} >
                                                    <div style={{maxWidth:90, overflow: "hidden", textOverflow:"ellipse"}}>
                                                    {row.value}
                                                </div>
                                                </TableCell>
                                                <TableCell padding="none" style={{minWidth:130}}>
                                                {/*<Button className="mr10" padding="none" style={{width:360}} onClick={handleConfirm}>*/}
                                                {/*    <DeleteOutlineIcon />*/}
                                                {/*</Button>*/}
                                                        <Button
                                                            onClick={() => handleRemoveClick(i) }
                                                            color="primary"

                                                        >
                                                            <DeleteOutlineIcon />
                                                        </Button>
                                                </TableCell>

                                            </div>
                                        )}
                                        {showConfirm && (
                                            <div>
                                                <Dialog
                                                    style={{zIndex:"9999"}}
                                                    open={showConfirm}
                                                    onClose={handleNo}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"Conferma rimozione"}
                                                    </DialogTitle>
                                                    <DialogContent style={{zIndex:"9999!important"}}>
                                                        <DialogContentText id="alert-dialog-description">
                                                            Confermi la rimozione dell'elemento?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={() => handleRemoveClick(row.id)}
                                                            color="primary"
                                                        >
                                                            Conferma
                                                        </Button>
                                                        <Button
                                                            onClick={handleNo}
                                                            color="primary"

                                                        >
                                                            Annulla
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        )}
                                    </TableRow>
                                </div>
                            );
                        })}

                    </TableBody>
                    <TableFooter>
                        <div style={{display:"flex", justifyContent:"end", width: "800px"}}>
                        <TableRow>
                            <TableCell rowSpan={2} />
                            <TableCell colSpan={1} placeholder="test">Totale complessivo: </TableCell>
                            <TableCell align="right">
                                <input
                                    name={props.formData.fields[8].nameTotalPrice}

                                    value={props.formData.fields[8].valueTotalPrice}

                                    onChange={ (event) => setTotal(event.target.value) }
                                />
                            </TableCell>
                        </TableRow>
                        </div>
                    </TableFooter>
                </Table>
            </Box>
        </div>
    );
}

export default forwardRef(TableDemo);