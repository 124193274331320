import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";

import Loading from './../components/Loading.js';

export default class StandardsAndDirectives extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            language: props.language,
            list: [],
            listMessage: "",
        }
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        //document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        //document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.standardsAndDirectives[this.state.language];

        window.scrollTo(0, 0);

        this.getList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            language: props.language
        });
        document.title = Globals.titles.standardsAndDirectives[props.language];
    }

    getList() {
        // Reset state
        this.setState({
            loading: true,
            list: [],
            listMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getStandardsAndDirectives&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    loading: false,
                    list: [],
                    listMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    loading: false,
                    list: responseJson.standardsAndDirectives,
                    listMessage: "",
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                loading: false,
                list: [],
                listMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    render() {
        if ( this.state.isLogged !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let apiBaseGetUploadedFileUrl = Globals.baseUrl + '?lang=' + this.state.language + '&a=getUploadedFile&access_token=' + this.state.accessToken + '&type=standards_and_directives&fileId=';

        let listMessage = this.state.listMessage;
        let render  = "";
        if ( listMessage !== "" ) {
            render = <div className="message-wrapper">
                {listMessage}
            </div>;
        } else {
            render = <div className="standards-and-directives-list-wrapper">
                {this.state.list.map((item, key) => {
                    return <div key={key} className="standards-and-directives-wrapper">
                        <div className="name">{item.title}</div>
                        <div className="files">
                            {item.files.map((item2, key2) => {
                                return <a key={key2} href={apiBaseGetUploadedFileUrl + item2.id} target="_blank" rel="noopener noreferrer">
                                    <div className="file">
                                        <div className="icon">
                                            <i className="fa fa-file-pdf-o"></i>
                                        </div>
                                        <div className="filename">
                                            {item2.title}
                                        </div>
                                    </div>
                                </a>
                            })}
                        </div>
                    </div>;
                })}
            </div>;
        }

        return(
            <div className="standards-and-directives-wrapper">
                {render}
                {loadingOnScreen}
            </div>
        );
    }

}