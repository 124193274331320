import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Globals from "./globals/globals.js";

import "./assets/scss/main.css";

import Menu from "./components/Menu.js";
import TopBar from "./components/TopBar.js";
import Footer from "./components/Footer.js";
import Loading from "./components/Loading.js";
import NotFound from "./components/NotFound.js";

import Login from "./pages/Login.js";
import Home from "./pages/Home.js";
import Users from "./pages/Users.js";
import AdminProducts from "./pages/AdminProducts.js";
import AdminProductsCampSafety from "./pages/AdminProductsCampSafety.js";
import AdminInstallations from "./pages/AdminInstallations.js";
import AdminReports from "./pages/AdminReports.js";
import AdminOperations from "./pages/AdminOperations.js";
import ConfirmedOperations from "./pages/ConfirmedOperations.js";
import AdminStandardsAndDirectives from "./pages/AdminStandardsAndDirectives.js";
import AdminMaintenanceDescription from "./pages/AdminMaintenanceDescription.js";

import Inspections from "./pages/Inspections.js";
import Quotations from "./pages/Quotations.js";
import ConfirmedQuotations from "./pages/ConfirmedQuotations.js";

import UserProducts from "./pages/UserProducts.js";
import UserReports from "./pages/UserReports.js";
import UserOperations from "./pages/UserOperations.js";
import StandardsAndDirectives from "./pages/StandardsAndDirectives.js";
import MaintenanceDescription from "./pages/MaintenanceDescription.js";

import UserProductPDF from "./pages/UserProductPDF.js";
import QuotationPDF from "./pages/QuotationPDF.js";
import InspectionPDF from "./pages/InspectionPDF.js";

import EditProfile from "./pages/EditProfile.js";
import EditPassword from "./pages/EditPassword.js";
import Cookiebar from "./pages/Cookiebar";

export default class App extends Component {
	constructor(props) {
		super(props);

		// Get language if stored in localStorage
		let storedLang = localStorage.getItem("language");

		if (storedLang === "" || storedLang === null || storedLang === undefined) {
			storedLang = "it";
		}

		this.state = {
			language: storedLang,
			accessToken: "",
			isAdmin: false,
			isOperator: false,
			isLogged: undefined,
			activeCookieBar: null,
		};
	}

	componentDidMount(props) {
		this.checkLogin();
        this.getActiveCookiebar();
	}

	getActiveCookiebar() {
		let url = Globals.baseUrl + "?lang=it&a=getPreferencesCookie";
		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					return "done";
				} else {
					this.setState({
                        activeCookieBar: responseJson.active_crm
                    });
				}
			});
	}

	modifyCookie() {
		document.getElementById("container").style.display = "block";
	}
	checkLogin() {
		// Get access token if stored
		let accessToken = localStorage.getItem("accessToken");

		if (accessToken !== undefined && accessToken !== null && accessToken !== "") {
			// Try to contact the server to see if it's still viable
			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=is_logged&access_token=" +
				accessToken;

			fetch(url)
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error received, reset accessToken so you go back to login
						localStorage.setItem("accessToken", "");
						this.setState({
							accessToken: "",
							isLogged: false,
						});
					} else {
						if (responseJson.is_logged === true) {
							// User is logged
							this.setState({
								accessToken: accessToken,
								isLogged: true,
								isAdmin: responseJson.is_admin,
								isOperator: responseJson.is_operator,
							});
						} else {
							// Not logged, reset accessToken and go back to login
							localStorage.setItem("accessToken", "");
							this.setState({
								accessToken: "",
								isLogged: false,
							});
						}
					}
				})
				.catch((error) => {
					// Error received, reset accessToken so you go back to login
					localStorage.setItem("accessToken", "");
					this.setState({
						accessToken: "",
						isLogged: false,
					});
				});
		} else {
			// Go to the login page because you're certainly not logged
			localStorage.setItem("accessToken", "");
			this.setState({
				accessToken: "",
				isLogged: false,
			});
		}
	}

	saveAccessTokenAfterLogin(accessToken, isAdmin, isOperator) {
		localStorage.setItem("accessToken", accessToken);
		this.setState({
			accessToken: accessToken,
			isLogged: true,
			isAdmin: isAdmin,
			isOperator: isOperator,
		});
	}

	doLogout() {
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=logout&access_token=" +
			accessToken;

		fetch(url)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					console.log(responseJson.message);
				}
			})
			.catch((error) => {
				console.error(error);
			});
		localStorage.setItem("accessToken", "");
		this.setState({
			accessToken: "",
			isLogged: false,
		});
	}

	render() {
		let redirect;
		if (this.state.isLogged === false) {
			redirect = <Redirect to={{ pathname: "/login" }} />;
		}

		let pages;
		if (this.state.isLogged === undefined) {
			pages = <Loading />;
		} else {
			pages = (
				<Switch>
					<Route
						exact
						path='/'
						render={(props) => (
							<Home
								isAdmin={this.state.isAdmin}
								isOperator={this.state.isOperator}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/login'
						render={(props) => (
							<Login
								saveAccessTokenAfterLogin={this.saveAccessTokenAfterLogin.bind(this)}
								isLogged={this.state.isLogged}
								language={this.state.language}
							/>
						)}
					/>
					<Route
						path='/utenti'
						render={(props) => (
							<Users
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-prodotti'
						render={(props) => (
							<AdminProducts
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-prodotti-camp-safety'
						render={(props) => (
							<AdminProductsCampSafety
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-installazioni'
						render={(props) => (
							<AdminInstallations
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-relazioni'
						render={(props) => (
							<AdminReports
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-interventi'
						render={(props) => (
							<AdminOperations
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-interventi-confermati'
						render={(props) => (
							<ConfirmedOperations
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-norme-e-direttive'
						render={(props) => (
							<AdminStandardsAndDirectives
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/gestione-manutenzioni'
						render={(props) => (
							<AdminMaintenanceDescription
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>

					<Route
						path='/sopralluoghi'
						render={(props) => (
							<Inspections
								isAdmin={this.state.isAdmin}
								isOperator={this.state.isOperator}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/preventivi'
						render={(props) => (
							<Quotations
								isAdmin={this.state.isAdmin}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
								{...props}
							/>
						)}
					/>
					<Route
						path='/preventivi-confermati'
						render={(props) => (
							<ConfirmedQuotations
								isAdmin={this.state.isAdmin}
								isOperator={this.state.isOperator}
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>

					<Route
						path='/i-miei-prodotti'
						render={(props) => (
							<UserProducts
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/le-mie-relazioni'
						render={(props) => (
							<UserReports
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/i-miei-interventi'
						render={(props) => (
							<UserOperations
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/norme-e-direttive'
						render={(props) => (
							<StandardsAndDirectives
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route
						path='/descrizione-manutenzioni'
						render={(props) => (
							<MaintenanceDescription
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>

					<Route path='/prodotti/pdf' render={(props) => <UserProductPDF />} />
					<Route path='/preventivo/pdf' render={(props) => <QuotationPDF />} />
					<Route path='/sopralluogo/pdf' render={(props) => <InspectionPDF />} />

					<Route
						path='/modifica-profilo'
						render={(props) => (
							<EditProfile
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
								doLogout={this.doLogout.bind(this)}
							/>
						)}
					/>
					<Route
						path='/modifica-password'
						render={(props) => (
							<EditPassword
								isLogged={this.state.isLogged}
								language={this.state.language}
								accessToken={this.state.accessToken}
							/>
						)}
					/>
					<Route render={() => <NotFound language={this.state.language} />} />
				</Switch>
			);
		}

		let topComponent = (
			<Menu
				doLogout={this.doLogout.bind(this)}
				language={this.state.language}
				isAdmin={this.state.isAdmin}
				isOperator={this.state.isOperator}
				isLogged={this.state.isLogged}
			/>
		);
		if (this.state.isLogged === false) {
			topComponent = <TopBar />;
		}

		return (
			<Router>
				<div className='page-wrapper'>
					{topComponent}
					<div className='site-content'>
						{pages}
						{redirect}
					</div>
					<Footer
						modifyCookie={this.modifyCookie.bind(this)}
						language={this.state.language}
                        activeCookieBar={this.state.activeCookieBar}
					/>
				</div>
				<ToastContainer />
				<Cookiebar language={this.state.language} activeCookiebar={this.state.activeCookieBar} />
			</Router>
		);
	}
}
