import React, { Component } from "react";
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import Select from "react-select";

import img0 from "./../assets/img/0.png";
import img1 from "./../assets/img/1.png";

import Loading from "./../components/Loading.js";
import Switch from "./../components/Switch";

import "react-table/react-table.css";

const defaultMonthsBeforeNextCheckSelectValue = {
	value: "",
	label: "Nessun controllo periodico",
};

const formModalFieldMonthsBeforeNextCheckSelectOptions = [
	defaultMonthsBeforeNextCheckSelectValue,
	{ value: "6", label: "Ogni 6 mesi" },
	{ value: "12", label: "Ogni anno" },
];

export default class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			accessToken: props.accessToken,
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
			usersList: null,
			usersListMessage: "",

			showFormModal: false,
			formModalTitle: "",
			formModalAction: "",

			formModalFieldId: null,
			formModalFieldActive: false,
			formModalFieldType: { value: "client", label: "Cliente" },
			formModalFieldCompany: "",
			formModalFieldVatCode: "",
			formModalFieldName: "",
			formModalFieldSurname: "",
			formModalFieldEmail: "",
			formModalFieldPassword: "",
			formModalFieldPhoneNumber: "",
			formModalFieldMobileNumber: "",
			formModalFieldAddress: "",
			formModalFieldZipcode: "",
			formModalFieldCity: "",
			formModalFieldProvince: "",
			formModalFieldKmDistance: "",
			formModalFieldMonthsBeforeNextCheck: defaultMonthsBeforeNextCheckSelectValue,

			showEmailError: false,
			emailErrorMessage: "",
			isCheck: false,
		};

		this.handleUserTypeChange = this.handleUserTypeChange.bind(this);
		this.handleMonthsBeforeNextCheckChange =
			this.handleMonthsBeforeNextCheckChange.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.handleUserActiveChange =
			this.handleUserActiveChange.bind(this);
	}

	handleCheck() {
		this.setState(
			{
				isCheck: !this.state.isCheck,
			},
			this.getUsersList(!this.state.isCheck)
		);
	}

	componentWillUnmount() {
		document.body.classList.remove("no-background");
		document.body.classList.remove("page-with-filter-bar");
	}

	componentDidMount() {
		document.body.classList.add("no-background");
		document.body.classList.add("page-with-filter-bar");

		document.title = Globals.titles.users[this.state.language];

		window.scrollTo(0, 0);

		this.getUsersList();
	}

	componentWillReceiveProps(props) {
		this.setState({
			isLogged: props.isLogged,
			isAdmin: props.isAdmin,
			language: props.language,
		});
		document.title = Globals.titles.users[props.language];
	}

	getUsersList(deleted = null) {
		// Reset state
		this.setState({
			usersList: null,
			usersListMessage: "",
		});

		// Fetch api for user's orders
		let accessToken = this.state.accessToken;

		let url = "";

		if (deleted === true) {
			url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUsers&access_token=" +
				accessToken +
				"&deleted=true";
		} else {
			url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=getUsers&access_token=" +
				accessToken;
		}

		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					this.setState({
						usersList: [],
						usersListMessage: responseJson.message,
					});
				} else {
					// Call is ok, store ordersData in state
					this.setState({
						usersList: responseJson.users,
						usersListMessage: "",
					});
				}
			})
			.catch((error) => {
				// Communication error - handle message
				this.setState({
					usersList: [],
					usersListMessage: Globals.errors.serverCall[this.state.language],
				});
			});
	}

	newUser() {
		this.toggleFormModal("Crea utente", "newUser");
	}

	editUser(listPosition) {
		this.toggleFormModal("Modifica utente", "editUser");

		let user = this.state.usersList[listPosition];

		let user_subgroup = { value: "client", label: "Cliente" };
		if (user.user_subgroup === "admin") {
			user_subgroup = { value: "admin", label: "Amministratore" };
		} else if (user.user_subgroup === "operator") {
			user_subgroup = { value: "operator", label: "Operatore" };
		}

		let user_active = false;
		if (parseInt(user.active) === 1) {
			user_active = true;
		}

		let formModalFieldMonthsBeforeNextCheck =
			defaultMonthsBeforeNextCheckSelectValue;
		for (
			let c = 0;
			c < formModalFieldMonthsBeforeNextCheckSelectOptions.length;
			c++
		) {
			if (
				user.months_before_next_check ===
				formModalFieldMonthsBeforeNextCheckSelectOptions[c].value
			) {
				formModalFieldMonthsBeforeNextCheck =
					formModalFieldMonthsBeforeNextCheckSelectOptions[c];
			}
		}

		this.setState({
			formModalFieldId: user.id,
			formModalFieldActive: user_active,
			formModalFieldType: user_subgroup,
			formModalFieldCompany: user.company,
			formModalFieldVatCode: user.vat_code,
			formModalFieldName: user.name,
			formModalFieldSurname: user.surname,
			formModalFieldEmail: user.email,
			formModalFieldPhoneNumber: user.phone_number,
			formModalFieldMobileNumber: user.mobile_number,
			formModalFieldAddress: user.address,
			formModalFieldZipcode: user.zipcode,
			formModalFieldCity: user.city,
			formModalFieldProvince: user.province,
			formModalFieldKmDistance: user.km_distance,
			formModalFieldMonthsBeforeNextCheck: formModalFieldMonthsBeforeNextCheck,
		});
	}

	deleteUser(userId) {
		if (
			window.confirm(
				"ATTENZIONE!!! Eliminando l'utente verrano eliminate tutte le installazioni ed interventi correlati. Confermi l'eliminazione? "
			)
		) {
			this.setState({
				loading: true,
			});

			// Fetch api for user's orders
			let accessToken = this.state.accessToken;

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=deleteUser&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("userId", userId);

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.getUsersList();

						this.setState({
							loading: false,
						});
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	toggleFormModal(title, action) {
		let showFormModal = this.state.showFormModal;

		if (showFormModal === true) {
			showFormModal = false;
		} else {
			showFormModal = true;
		}

		this.setState({
			showFormModal: showFormModal,
			formModalTitle: title,
			formModalAction: action,

			formModalFieldId: null,
			formModalFieldActive: false,
			formModalFieldType: { value: "client", label: "Cliente" },
			formModalFieldCompany: "",
			formModalFieldVatCode: "",
			formModalFieldName: "",
			formModalFieldSurname: "",
			formModalFieldEmail: "",
			formModalFieldPassword: "",
			formModalFieldPhoneNumber: "",
			formModalFieldMobileNumber: "",
			formModalFieldAddress: "",
			formModalFieldZipcode: "",
			formModalFieldCity: "",
			formModalFieldProvince: "",
			formModalFieldKmDistance: "",
			formModalFieldMonthsBeforeNextCheck: defaultMonthsBeforeNextCheckSelectValue,

			showEmailError: false,
			emailErrorMessage: "",
		});

		window.scrollTo(0, 0);
	}

	setInputErrorMessage(field, message) {
		switch (field) {
			case "email":
				this.setState({
					showEmailError: true,
					emailErrorMessage: message,
				});
				break;
			default:
				break;
		}
	}

	confirmUserForm() {
		if (window.confirm("Confermi i dati forniti?")) {
			this.setState({
				loading: true,
			});

			// Fetch api
			let accessToken = this.state.accessToken;
			let formAction = this.state.formModalAction;

			let userData = {
				id: this.state.formModalFieldId,
				active: this.state.formModalFieldActive,
				type: this.state.formModalFieldType.value,
				company: this.state.formModalFieldCompany,
				vat_code: this.state.formModalFieldVatCode,
				name: this.state.formModalFieldName,
				surname: this.state.formModalFieldSurname,
				email: this.state.formModalFieldEmail,
				password: this.state.formModalFieldPassword,
				phone_number: this.state.formModalFieldPhoneNumber,
				mobile_number: this.state.formModalFieldMobileNumber,
				address: this.state.formModalFieldAddress,
				zipcode: this.state.formModalFieldZipcode,
				city: this.state.formModalFieldCity,
				province: this.state.formModalFieldProvince,
				kmDistance: this.state.formModalFieldKmDistance,
				monthsBeforeNextCheck: this.state.formModalFieldMonthsBeforeNextCheck.value,
			};

			if (userData.email === "") {
				this.setInputErrorMessage(
					"email",
					Globals.alerts.mandatoryEmail[this.state.language]
				);
				this.setState({
					loading: false,
				});
				return;
			}

			let url =
				Globals.baseUrl +
				"?lang=" +
				this.state.language +
				"&a=" +
				formAction +
				"&access_token=" +
				accessToken;

			let formdata = new FormData();

			formdata.append("userData", JSON.stringify(userData));

			fetch(url, {
				method: "POST",
				body: formdata,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						// Error during the call - handle message
						toast(responseJson.message, {
							autoClose: 8000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.setState({
							loading: false,
						});
					} else {
						// Call is ok
						toast("Operazione completata", {
							autoClose: 4000,
							className: "std-black-toast",
							bodyClassName: "std-black-toast-body",
							progressClassName: "std-black-toast-progress-bar",
						});

						this.toggleFormModal();
						this.getUsersList();

						this.setState({
							loading: false,
						});
					}
				})
				.catch((error) => {
					// Communication error - handle message
					toast(Globals.errors.serverCall[this.state.language], {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				});
		}
	}

	handleUserActiveChange() {

		let isActive = this.state.formModalFieldActive;

		this.setState({
			formModalFieldActive: !isActive,
		});
	}

	handleUserTypeChange(element) {
		this.setState({
			formModalFieldType: element,
		});
	}

	handleMonthsBeforeNextCheckChange(element) {
		this.setState({
			formModalFieldMonthsBeforeNextCheck: element,
		});
	}

	createPdf(id) {
		// Fetch api for user's orders
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=usersCreatePdf&access_token=" +
			accessToken +
			"&id=" +
			id;

		window.open(url, "name", "titlebar=no").focus();
	}

	render() {
		if (this.state.isLogged !== true || this.state.isAdmin !== true) {
			return <Redirect to={{ pathname: "/login" }} />;
		}

		if (this.state.usersList === null) {
			return <Loading />;
		}

		let loadingOnScreen;
		if (this.state.loading === true) {
			loadingOnScreen = <Loading />;
		}

		let usersList = this.state.usersList;
		let usersListMessage = this.state.usersListMessage;
		let usersRender = "";
		if (usersListMessage !== "") {
			usersRender = <div className='message-wrapper'>{usersListMessage}</div>;
		} else {
			// Prepare data for the table;
			const columns = [
				{
					Header: "Ragione sociale",
					accessor: "company",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "P. IVA",
					accessor: "vatCode",
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Cognome e nome",
					accessor: "surname",
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Indirizzo",
					accessor: "address",
					style: { overflow: "visible", whiteSpace: "normal" },
					maxWidth: 200,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Email",
					accessor: "email",
					style: { overflow: "visible", whiteSpace: "normal" },
					width: 300,
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Telefono",
					accessor: "phoneNumber",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Cellulare",
					accessor: "mobileNumber",
					style: { overflow: "visible", whiteSpace: "normal" },
					filterable: true,
					filterMethod: (filter, row) => {
						return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
					},
				},
				{
					Header: "Distanza (Km)",
					accessor: "distance",
					width: 140,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: "Attivo",
					accessor: "activeIcon",
					width: 100,
					headerStyle: { textAlign: "center" },
					Cell: (row) => (
						<div style={{ textAlign: "center" }}>
							{" "}
							{row.value === "1" ? (
								<img src={img1} alt={"Attivo"} />
							) : (
								<img src={img0} alt={"Non Attivo"} />
							)}{" "}
						</div>
					),
					filterable: true,
					filterMethod: (filter, row) => {
						if (filter.value === "Tutti") {
							return true;
						}
						if (filter.value === "1") {
							return row[filter.id] === "1";
						}
						if (filter.value === "0") {
							return row[filter.id] === "0";
						}
					},
					Filter: ({ filter, onChange }) => (
						<select
							onChange={(event) => onChange(event.target.value)}
							style={{ width: "100%", textAlign: "center" }}
							value={filter ? filter.value : "Tutti"}>
							<option value='Tutti'>Tutti</option>
							<option value='1'>Attivi</option>
							<option value='0'>Non Attivi</option>
						</select>
					),
				},
                {
					Header: " ",
					accessor: "printIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "editIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
				{
					Header: " ",
					accessor: "deleteIcon",
					width: 50,
					Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
				},
			];
			const data = [];
			for (let c = 0; c < usersList.length; c++) {
				// Push data
				data.push({
					company: usersList[c].company,
					vatCode: usersList[c].vat_code,
					surname: usersList[c].surname + " " + usersList[c].name,
					address:
						usersList[c].address +
						" " +
						usersList[c].zipcode +
						" " +
						usersList[c].city +
						" " +
						usersList[c].province,
					email: usersList[c].email,
					phoneNumber: usersList[c].phone_number,
					mobileNumber: usersList[c].mobile_number,
					distance: usersList[c].km_distance,
					activeIcon: usersList[c].active,
					printIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-print fa-2x'
							onClick={() => {
								this.createPdf(usersList[c].id);
							}}></i>
					),
					editIcon:
						usersList[c].is_auto_deleted !== "1" ? (
							<i
								className='std-hoverable-cursor-pointer fa fa-edit fa-2x'
								onClick={() => {
									this.editUser(c);
								}}></i>
						) : (
							<i className='std-hoverable-cursor-pointer fa fa-lock fa-2x'></i>
						),
					deleteIcon: (
						<i
							className='std-hoverable-cursor-pointer fa fa-trash fa-2x'
							onClick={() => {
								this.deleteUser(usersList[c].id);
							}}></i>
					),
				});
			}
			// Render Table
			usersRender = (
				<ReactTable
					data={data}
					columns={columns}
					pageSizeOptions={[50, 100, 200]}
					defaultPageSize={50}
					previousText={Globals.reactTableText.previous[this.state.language]}
					nextText={Globals.reactTableText.next[this.state.language]}
					loadingText={Globals.reactTableText.loading[this.state.language]}
					noDataText={Globals.reactTableText.noData[this.state.language]}
					pageText={Globals.reactTableText.page[this.state.language]}
					ofText={Globals.reactTableText.of[this.state.language]}
					rowsText={Globals.reactTableText.rows[this.state.language]}
					pageJumpText={Globals.reactTableText.pageJumpText[this.state.language]}
					rowsSelectorText={
						Globals.reactTableText.rowsSelectorText[this.state.language]
					}
				/>
			);
		}

		let formModal;
		if (this.state.showFormModal === true) {
			let formModalFieldTypeSelectOptions = [
				{ value: "admin", label: "Amministratore" },
				{ value: "operator", label: "Operatore" },
				{ value: "client", label: "Cliente" },
			];

			let emailErrorField;
			if (this.state.showEmailError === true) {
				emailErrorField = (
					<div className='input-error-wrapper'>{this.state.emailErrorMessage}</div>
				);
			}

			formModal = (
				<div className='modal'>
					<div className='background'></div>
					<div className='body large'>
						<div className='titleBar'>
							{this.state.formModalTitle}
							<div className='close-button'>
								<i
									className='fa fa-times'
									onClick={() => this.toggleFormModal("", "")}></i>
							</div>
						</div>
						<div className='content three-sides-form'>
							<div className='input-wrapper full-width centered'>
								<div className='label'>Attivo</div>
								<Switch
									isOn={this.state.formModalFieldActive}
									handleToggle={this.handleUserActiveChange}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Tipologia</div>
								<Select
									value={this.state.formModalFieldType}
									onChange={this.handleUserTypeChange}
									options={formModalFieldTypeSelectOptions}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Controlli</div>
								<Select
									value={this.state.formModalFieldMonthsBeforeNextCheck}
									onChange={this.handleMonthsBeforeNextCheckChange}
									options={formModalFieldMonthsBeforeNextCheckSelectOptions}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Ragione sociale</div>
								<input
									type='text'
									name='company'
									value={this.state.formModalFieldCompany}
									onChange={(e) =>
										this.setState({ formModalFieldCompany: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Partita IVA</div>
								<input
									type='text'
									name='vat_code'
									value={this.state.formModalFieldVatCode}
									onChange={(e) =>
										this.setState({ formModalFieldVatCode: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Nome</div>
								<input
									type='text'
									name='name'
									value={this.state.formModalFieldName}
									onChange={(e) => this.setState({ formModalFieldName: e.target.value })}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Cognome</div>
								<input
									type='text'
									name='surname'
									value={this.state.formModalFieldSurname}
									onChange={(e) =>
										this.setState({ formModalFieldSurname: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Email</div>
								<input
									type='text'
									name='email'
									value={this.state.formModalFieldEmail}
									onChange={(e) =>
										this.setState({ formModalFieldEmail: e.target.value })
									}
								/>
								{emailErrorField}
							</div>
							<div className='input-wrapper'>
								<div className='label'>Password</div>
								<input
									type='text'
									name='phone_number'
									value={this.state.formModalFieldPassword}
									onChange={(e) =>
										this.setState({ formModalFieldPassword: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Telefono</div>
								<input
									type='text'
									name='phone_number'
									value={this.state.formModalFieldPhoneNumber}
									onChange={(e) =>
										this.setState({ formModalFieldPhoneNumber: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Cellulare</div>
								<input
									type='text'
									name='mobile_number'
									value={this.state.formModalFieldMobileNumber}
									onChange={(e) =>
										this.setState({ formModalFieldMobileNumber: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Indirizzo</div>
								<input
									type='text'
									name='address'
									value={this.state.formModalFieldAddress}
									onChange={(e) =>
										this.setState({ formModalFieldAddress: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>CAP</div>
								<input
									type='text'
									name='zipcode'
									value={this.state.formModalFieldZipcode}
									onChange={(e) =>
										this.setState({ formModalFieldZipcode: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Città</div>
								<input
									type='text'
									name='city'
									value={this.state.formModalFieldCity}
									onChange={(e) => this.setState({ formModalFieldCity: e.target.value })}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Provincia</div>
								<input
									type='text'
									name='province'
									value={this.state.formModalFieldProvince}
									onChange={(e) =>
										this.setState({ formModalFieldProvince: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper'>
								<div className='label'>Distanza (Km)</div>
								<input
									type='text'
									name='km_distance'
									value={this.state.formModalFieldKmDistance}
									onChange={(e) =>
										this.setState({ formModalFieldKmDistance: e.target.value })
									}
								/>
							</div>
							<div className='input-wrapper full-width'>
								<button
									type='button'
									style={{
										display: "flex",
										width: "15em",
										justifyContent: "center",
										margin: "0 auto",
										textAlign: "center",
									}}
									onClick={() => this.confirmUserForm()}>
									<span>Conferma</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='users-page-wrapper'>
				<div className='filters'>
					<div className='filters-wrapper'>
						<div className='filter-wrapper'>
							<div className='filter'>
								<button type='button' onClick={() => this.newUser()}>
									<span>Nuovo</span>
								</button>
								<input
									type='checkbox'
									style={{ marginLeft: 10 }}
									checked={this.state.isCheck}
									onChange={() => this.handleCheck()}
								/>
								<span> utenti autoeliminati</span>

								<span className='title-text'> GESTIONE UTENTI </span>
							</div>
						</div>
					</div>
				</div>
				{usersRender}
				{loadingOnScreen}
				{formModal}
			</div>
		);
	}
}
