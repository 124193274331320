import React, { Component } from "react";
import Globals from "./../globals/globals.js";
import Loading from "./../components/Loading.js";
import { toast } from "react-toastify";
import "font-awesome/css/font-awesome.min.css";

import { Redirect, Link } from "react-router-dom";

export default class EditProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirectToHome: false,

			loading: true,

			isLogged: props.isLogged,
			language: props.language,
			accessToken: props.accessToken,

			company: "",
			vatCode: "",
			name: "",
			surname: "",
			email: "",
			phoneNumber: "",
			mobileNumber: "",
			address: "",
			zipcode: "",
			city: "",
			province: "",

			showEmailError: false,
			emailErrorMessage: "",
		};
	}

	componentDidMount() {
		document.title = Globals.titles.editProfile[this.state.language];

		window.scrollTo(0, 0);

		// Get user data
		let accessToken = this.state.accessToken;

		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=getUserData&access_token=" +
			accessToken;

		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					toast(responseJson.message, {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						redirectToHome: true,
					});
				} else {
					let user = responseJson.user;

					// Call is ok, store ordersData in state
					this.setState({
						loading: false,
						company: user.company,
						vatCode: user.vat_code,
						name: user.name,
						surname: user.surname,
						email: user.email,
						phoneNumber: user.phone_number,
						mobileNumber: user.mobile_number,
						address: user.address,
						zipcode: user.zipcode,
						city: user.city,
						province: user.province,
					});
				}
			})
			.catch((error) => {
				// Communication error - handle message
				toast(Globals.errors.serverCall[this.state.language], {
					autoClose: 8000,
					className: "std-black-toast",
					bodyClassName: "std-black-toast-body",
					progressClassName: "std-black-toast-progress-bar",
				});

				this.setState({
					redirectToHome: true,
				});
			});
	}

	componentWillReceiveProps(props) {
		this.setState({
			language: props.language,
			isLogged: props.isLogged,
		});
		document.title = Globals.titles.editProfile[props.language];
	}

	setInputErrorMessage(field, message) {
		switch (field) {
			case "email":
				this.setState({
					showEmailError: true,
					emailErrorMessage: message,
				});
				break;
			default:
				break;
		}
	}

	editProfileInfo() {
		// Reset previous input error
		this.setState({
			showEmailError: false,
			emailErrorMessage: "",
		});

		let userData = {
			company: this.state.company,
			vat_code: this.state.vatCode,
			name: this.state.name,
			surname: this.state.surname,
			email: this.state.email,
			phone_number: this.state.phoneNumber,
			mobile_number: this.state.mobileNumber,
			address: this.state.address,
			zipcode: this.state.zipcode,
			city: this.state.city,
			province: this.state.province,
		};

		// Check email (mandatory field)
		if (
			userData.email === "" ||
			userData.email === null ||
			userData.email === undefined
		) {
			this.setInputErrorMessage(
				"email",
				Globals.alerts.mandatoryField[this.state.language]
			);
			return;
		}

		// Set the loading state (it's an async action)
		this.setState({
			loading: true,
		});

		let accessToken = this.state.accessToken;

		// Try to login
		let url =
			Globals.baseUrl +
			"?lang=" +
			this.state.language +
			"&a=editUserInfo&access_token=" +
			accessToken;

		let formdata = new FormData();
		formdata.append("userData", JSON.stringify(userData));

		fetch(url, {
			method: "POST",
			body: formdata,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					// Error during the call - handle message
					toast(responseJson.message, {
						autoClose: 8000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						loading: false,
					});
				} else {
					// Call is ok
					toast("Operazione completata", {
						autoClose: 4000,
						className: "std-black-toast",
						bodyClassName: "std-black-toast-body",
						progressClassName: "std-black-toast-progress-bar",
					});

					this.setState({
						redirectToHome: true,
					});
				}
			})
			.catch((error) => {
				// Communication error - handle message
				toast(Globals.errors.serverCall[this.state.language], {
					autoClose: 8000,
					className: "std-black-toast",
					bodyClassName: "std-black-toast-body",
					progressClassName: "std-black-toast-progress-bar",
				});

				this.setState({
					loading: false,
				});
			});
	}

	deleteProfile() {
		if (
			window.confirm(
				"Attenzione, Confermi di voler eliminare il profilo e i dati ad esso collegati? L'operazione sarà irreversibile. Una volta confermato, riceverà una mail di avvenuta cancellazione del profilo."
			) === true
		) {
            this.setState({
                loading: true,
            });
    
                // Try to login
                let url =
                    Globals.baseUrl +
                    "?lang=" +
                    this.state.language +
                    "&a=deleteProfile&access_token=" +
                    this.state.accessToken;
    
                fetch(url, {
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.error === true) {
                            this.setState({
                                loading: false,
                            });
    
                            toast(responseJson.message, {
                                autoClose: 8000,
                                className: "std-black-toast",
                                bodyClassName: "std-black-toast-body",
                                progressClassName: "std-black-toast-progress-bar",
                            });
    
                        } else {
                            
                            toast(Globals.alerts.accountIsDelete[this.state.language], {
                                autoClose: 8000,
                                className: "std-black-toast",
                                bodyClassName: "std-black-toast-body",
                                progressClassName: "std-black-toast-progress-bar",
                            });
                            
                            this.props.doLogout();
    
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                        });
    
                        toast(Globals.errors.serverCall[this.state.language], {
                            autoClose: 8000,
                            className: "std-black-toast",
                            bodyClassName: "std-black-toast-body",
                            progressClassName: "std-black-toast-progress-bar",
                        });
                    });

		}
	}

	render() {
		if (!this.state.isLogged) {
			return <Redirect to='/' />;
		}

		if (this.state.redirectToHome === true) {
			return <Redirect to='/' />;
		}

		let loading;
		if (this.state.loading === true) {
			loading = <Loading />;
		}

		let emailErrorField;
		if (this.state.showEmailError === true) {
			emailErrorField = (
				<div className='input-error-wrapper'>{this.state.emailErrorMessage}</div>
			);
		}

		return (
			<div className='edit-profile-box'>
				<div className='fields-wrapper'>
					<div className='input-wrapper'>
						<div className='label'>Ragione sociale</div>
						<input
							type='text'
							name='company'
							value={this.state.company}
							onChange={(e) => this.setState({ company: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Partita IVA</div>
						<input
							type='text'
							name='vat_code'
							value={this.state.vatCode}
							onChange={(e) => this.setState({ vatCode: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Nome</div>
						<input
							type='text'
							name='name'
							value={this.state.name}
							onChange={(e) => this.setState({ name: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Cognome</div>
						<input
							type='text'
							name='surname'
							value={this.state.surname}
							onChange={(e) => this.setState({ surname: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Email</div>
						<input
							type='text'
							name='email'
							value={this.state.email}
							onChange={(e) => this.setState({ email: e.target.value })}
						/>
					</div>
					{emailErrorField}
					<div className='input-wrapper'>
						<div className='label'>Telefono</div>
						<input
							type='text'
							name='phone_number'
							value={this.state.phoneNumber}
							onChange={(e) => this.setState({ phoneNumber: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Cellulare</div>
						<input
							type='text'
							name='mobile_number'
							value={this.state.mobileNumber}
							onChange={(e) => this.setState({ mobileNumber: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Indirizzo</div>
						<input
							type='text'
							name='address'
							value={this.state.address}
							onChange={(e) => this.setState({ address: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>CAP</div>
						<input
							type='text'
							name='zipcode'
							value={this.state.zipcode}
							onChange={(e) => this.setState({ zipcode: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Città</div>
						<input
							type='text'
							name='city'
							value={this.state.city}
							onChange={(e) => this.setState({ city: e.target.value })}
						/>
					</div>
					<div className='input-wrapper'>
						<div className='label'>Provincia</div>
						<input
							type='text'
							name='province'
							value={this.state.province}
							onChange={(e) => this.setState({ province: e.target.value })}
						/>
					</div>
					<div>
						<div className='input-wrapper bigger'>
							<button type='button' onClick={this.editProfileInfo.bind(this)}>
								<i className='fa fa-user'></i>
								<span>Modifica il profilo</span>
							</button>
						</div>
						<div className='input-wrapper bigger'>
							<button type='button' onClick={() => this.deleteProfile()}>
								<i className='fa fa-user-times'></i>
								<span>Elimina profilo</span>
							</button>
						</div>
						<Link to='/'>
							<div className={"input-wrapper bigger"}>
								<button type='button'>
									<i className='fa fa-arrow-left'></i>
									<span>Annulla</span>
								</button>
							</div>
						</Link>
					</div>
					{loading}
				</div>
			</div>
		);
	}
}
