import React from "react";
 

 let baseUrl =  "https://www.officinagelso.com/webservices/1.0/";
 let domain = "https://www.officinagelso.com/"

 
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	 baseUrl = "https://officinagelso.readynet.it/webservices/1.0/";
	 domain = "https://officinagelso.readynet.it/";
 }

const Globals = {
	baseUrl: baseUrl,
    domain : domain, 

    
	menuVoices: {
		home: {
			it: "Home",
		},
		users: {
			it: "Utenti",
		},
		adminProducts: {
			it: "Prodotti",
		},
		adminProductsCampSafety: {
			it: "CAMP Safety",
		},
		adminInstallations: {
			it: "Installazioni",
		},
		adminReports: {
			it: "Relazioni",
		},
		adminOperations: {
			it: "Storico interventi",
		},
		adminConfirmedOperations: {
			it: "Interventi confermati",
		},
		adminStandardsAndDirectives: {
			it: "Norme e direttive",
		},
		adminMaintenanceDescription: {
			it: "Manutenzioni",
		},
		logout: {
			it: "Logout",
		},
	},

	titles: {
		home: {
			it: "CRM Officina Gelso - Home",
		},
		login: {
			it: "CRM Officina Gelso - Login",
		},
		editProfile: {
			it: "CRM Officina Gelso - Modifica profilo",
		},
		editPassword: {
			it: "CRM Officina Gelso - Modifica password",
		},
		users: {
			it: "CRM Officina Gelso - Utenti",
		},
		adminProducts: {
			it: "CRM Officina Gelso - Gestione prodotti",
		},
		adminReports: {
			it: "CRM Officina Gelso - Gestione relazioni",
		},
		adminProductsCampSafety: {
			it: "CRM Officina Gelso - Gestione prodotti CAMP SAFETY",
		},
		installations: {
			it: "CRM Officina Gelso - Gestione installazioni",
		},
		adminOperations: {
			it: "CRM Officina Gelso - Gestione interventi",
		},
		adminStandardsAndDirectives: {
			it: "CRM Officina Gelso - Gestione norme e direttive",
		},
		adminMaintenanceDescription: {
			it: "CRM Officina Gelso - Gestione manutenzioni",
		},
		inspections: {
			it: "CRM Officina Gelso - Sopralluoghi",
		},
		quotations: {
			it: "CRM Officina Gelso - Preventivi",
		},
		confirmedQuotations: {
			it: "CRM Officina Gelso - Preventivi confermati",
		},
		confirmedOperations: {
			it: "CRM Officina Gelso - Interventi confermati",
		},
		userProducts: {
			it: "CRM Officina Gelso - I miei prodotti",
		},
		userReports: {
			it: "CRM Officina Gelso - Le mie relazioni",
		},
		userOperations: {
			it: "CRM Officina Gelso - I miei interventi",
		},
		standardsAndDirectives: {
			it: "CRM Officina Gelso - Norme e direttive",
		},
		maintenanceDescription: {
			it: "CRM Officina Gelso - Manutenzioni",
		},
		notFound: {
			it: "CRM Officina Gelso - Contenuto non trovato",
		},
	},

	confirms: {
		logout: {
			it: "Confermi il logout dall'applicazione?",
		},
	},

	errors: {
		serverCall: {
			it: "Si è verificato un errore durante la comunicazione con il server",
		},
		nonSequentialDates: {
			it: "La data di inizio è successiva alla data di fine",
		},
	},

	alerts: {
		mandatoryEmail: {
			it: "Email è un campo obbligatorio",
		},
		mandatoryPassword: {
			it: "Password è un campo obbligatorio",
		},
		mandatoryField: {
			it: "Campo obbligatorio",
		},
		mandatoryName: {
			it: "Nome è un campo obbligatorio",
		},
		accountIsDelete: {
			it: "Operazione completata. L'account è stato eliminato con successo",
		},
	},

	pages: {
		notFound: {
			it: (
				<div>
					Ops,
					<br />
					il contenuto che stai cercando non esiste
					<br />
					oppure non è mai esistito
				</div>
			),
		},
	},

	messages: {
		welcome: {
			it: "Benvenuto nella tua area personale",
		},
		myOrders: {
			it: "I miei ordini",
		},
	},

	footer: {
		vatCode: {
			it: "P.IVA",
		},
		socialCapital: {
			it: "Capitale sociale",
		},
		phone: {
			it: "Telefono",
		},
		fax: {
			it: "Fax",
		},
		email: {
			it: "Email",
		},
		legalNotes: {
			it: "Note legali",
		},
		legalNotesLink: {
			it: "",
		},
		termsOfUse: {
			it: "Condizioni d'utilizzo",
		},
		termsOfUseLink: {
			it: "",
		},
		credits: {
			it: "Credits",
		},
		creditsLink: {
			it: "",
		},
	},

	placeholders: {
		email: {
			it: "Email",
		},
		password: {
			it: "Password",
		},
		login: {
			it: "Login",
		},
	},

	labels: {
		users: {
			it: "Utenti",
		},
		adminProducts: {
			it: "Prodotti",
		},
		adminProductsCampSafety: {
			it: "CAMP Safety",
		},
		adminInstallations: {
			it: "Installazioni",
		},
		adminReports: {
			it: "Relazioni",
		},
		adminOperations: {
			it: "Storico interventi",
		},
		adminConfirmedOperations: {
			it: "Interventi Confermati",
		},
		adminStandardsAndDirectives: {
			it: "Norme e direttive",
		},
		adminMaintenanceDescription: {
			it: "Manutenzioni",
		},
	},

	reactTableText: {
		previous: {
			it: "Precedente",
			en: "Previous",
			de: "Bisherige",
		},
		next: {
			it: "Successivo",
			en: "Next",
			de: "Nächster",
		},
		loading: {
			it: "Caricamento...",
			en: "Loading...",
			de: "Wird geladen...",
		},
		noData: {
			it: "Nessun dato trovato",
			en: "No data found",
			de: "Keine Daten gefunden",
		},
		page: {
			it: "Pagina",
			en: "Page",
			de: "Seite",
		},
		of: {
			it: "di",
			en: "of",
			de: "von",
		},
		rows: {
			it: "righe",
			en: "rows",
			de: "reihen",
		},
		pageJumpText: {
			it: "salta alla pagina",
			en: "jump to page",
			de: "zur seite springen",
		},
		rowsSelectorText: {
			it: "righe per pagina",
			en: "rows per page",
			de: "zeilen pro seite",
		},
	},
};

export default Globals;
