import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import "font-awesome/css/font-awesome.min.css";
import Globals from "../globals/globals";

export default function Cookiebar(props) {
	const [FlagAnalitici, setAnalitici] = useState(0);

	const [FlagProfilazione, setProfilazione] = useState(0);

	// const [active, setActive] = useState(false);

	const [data, setData] = useState({
		title: "",
		descrizione: "",
		fraseCookie: "",
		titleCookie: "",
		linkInfo: "",
	});

	useEffect(() => {
		const getPreferencesCookies = () => {
			let url = Globals.baseUrl + "?lang=it&a=getPreferencesCookie";
			fetch(url, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson.error === true) {
						return "done";
					} else {
						selectedCookie(
							parseInt(responseJson.cookie[0].cookie_analitici_app),
							parseInt(responseJson.cookie[0].cookie_profilazione_app)
						);
						setData({
							...data,
							title: responseJson.cookie[0].text_info_cookie_app_it,
							descrizione: responseJson.cookie[0].desc_preferenze_app_it,
							fraseCookie: responseJson.cookie[0].frase_cookiebar_app_it,
							titleCookie: responseJson.cookie[0].title_preferenze_app_it,
							linkInfo: responseJson.cookie[0].link_info_cookie_app_it,
						});
					}
				});
		};

		const checkCookie = () => {
			let user = getCookieFunction("cookie_consent");
			let cookiebar = document.getElementById("container") || false;

			if (cookiebar) {
				if (user !== "") {
					document.getElementById("container").style.display = "none";
				} else {
					if (user !== "" && user !== null) {
						document.getElementById("container").style.display = "block";
					}
				}
			}
		};

		getPreferencesCookies();
		checkCookie();
	}, [props.activeCookiebar]);

	const sendCookiePreferences = (necessari, analitici, profilazione) => {
		let url = Globals.baseUrl + "?lang=it&a=saveCookiePreferences";
		let formdata = new FormData();

		FlagAnalitici === 1
			? formdata.append("analitici", analitici)
			: formdata.append("analitici", 0);
		FlagProfilazione === 1
			? formdata.append("profilazione", profilazione)
			: formdata.append("profilazione", 0);
		formdata.append("necessari", necessari);

		fetch(url, {
			method: "POST",
			body: formdata,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.error === true) {
					return "error";
				}
			});
	};

	const selectedCookie = (analitici, profilazione) => {
		//verifico che gli elementi esistano nel document.getElementById
		let cookieProfilazione =
			document.getElementById("cookie_profilazione") || false;
		let cookieAnalitici = document.getElementById("cookie_analitici") || false;

		if (cookieProfilazione && cookieAnalitici) {
			if (profilazione === 1) {
				document.getElementById("cookie_profilazione").style.display = "block";
				setProfilazione(1);
			} else {
				document.getElementById("cookie_profilazione").style.display = "none";
			}
			if (analitici === 1) {
				document.getElementById("cookie_analitici").style.display = "block";
				setAnalitici(1);
			} else {
				document.getElementById("cookie_analitici").style.display = "none";
			}
		}
	};

	function getCookieFunction(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}

			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function acceptAllCookie() {
		let date = new Date();
		date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
		let expires = "; expires=" + date.toGMTString();
		document.cookie = "cookie_consent= tecnici" + expires;
		document.getElementById("container").style.display = "none";
		sendCookiePreferences(1, 1, 1);
	}
	function acceptNecessaryCookie() {
		let date = new Date();
		date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
		let expires = "; expires=" + date.toGMTString();
		document.cookie = "cookie_consent= tecnici" + expires;
		document.getElementById("container").style.display = "none";
		sendCookiePreferences(1, 0, 0);
	}
	function preferenzeCookie() {
		document.getElementById("theModal").style.display = "block";
		document.getElementById("overlay").style.display = "block";
	}
	function closeModal() {
		document.getElementById("theModal").style.display = "none";
		document.getElementById("overlay").style.display = "none";
	}
	function closeCookie() {
		if (
			window.confirm(
				"Chiudendo la barra dei cookie verranno accettati solo i cookie necessari. Confermare?"
			) === true
		) {
			document.getElementById("container").style.display = "none";
			acceptNecessaryCookie();
		} else {
			document.getElementById("container").style.display = "block";
		}
	}

	function confirmPreferences() {
		if (
			document.querySelector("#analitici").checked &&
			document.querySelector("#profilazione").checked
		) {
			let date = new Date();
			date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
			let expires = "; expires=" + date.toGMTString();
			document.cookie =
				"cookie_consent= tecnici| analitici | profilazione" + expires;
			document.getElementById("container").style.display = "none";
			document.getElementById("theModal").style.display = "none";
			document.getElementById("overlay").style.display = "none";
			sendCookiePreferences(1, 1, 1);
		} else if (document.querySelector("#analitici").checked) {
			let date = new Date();
			date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
			let expires = "; expires=" + date.toGMTString();
			document.cookie = "cookie_consent= tecnici | analitici" + expires;
			document.getElementById("container").style.display = "none";
			document.getElementById("theModal").style.display = "none";
			document.getElementById("overlay").style.display = "none";
			sendCookiePreferences(1, 1, 0);
		} else if (document.querySelector("#profilazione").checked) {
			let date = new Date();
			date.setTime(date.getTime() + 190 * 24 * 60 * 60 * 1000);
			let expires = "; expires=" + date.toGMTString();
			document.cookie = "cookie_consent= tecnici| profilazione" + expires;
			document.getElementById("container").style.display = "none";
			document.getElementById("theModal").style.display = "none";
			document.getElementById("overlay").style.display = "none";
			sendCookiePreferences(1, 0, 1);
		} else {
			acceptNecessaryCookie();
		}
	}

	if (props.activeCookiebar) {
		return (
			<div>
				<div id='container'>
					{/* overlay */}
					<div id='overlay' className='overlay'></div>
					{/* componente cookiebar */}
					<CookieConsent
						location='bottom'
						buttonStyle={{ display: "none" }}
						className='cookiebar'
						style={{
							background: "#eeeeee",
							color: "#000000",
							width: "100%",
						}}
						expires={125}>
						<div
							onClick={() => closeCookie()}
							className='closeModal pull-right'></div>
						<strong style={{ fontSize: "20px" }}>{data.title}</strong>
						<br></br>

						{data.fraseCookie}
						<div className='wrapperButton'>
							<button onClick={() => acceptNecessaryCookie()} className='btnNecessary'>
								Necessari
							</button>

							<button onClick={() => acceptAllCookie()} className='btnAcceptAll'>
								Accetta tutto
							</button>
						</div>
						<div className='preferences'>
							<button
								className='pull-right btnPreferenze'
								onClick={() => preferenzeCookie()}>
								Preferenze Cookie
							</button>
							<a
								className='pull-right'
								style={{
									color: "rgb(0, 102, 204)",
									fontSize: "18px",
									marginRight: "10px",
								}}
								href={data.linkInfo}
								target='_blank'
								rel='noopener noreferrer'>
								Privacy Policy
							</a>
						</div>
					</CookieConsent>
					{/* finestra modale */}
					<div className='modal' id='theModal' style={{ display: "none" }}>
						<article className='modal-container'>
							<header className='modal-container-header'>
								<h1 className='modal-container-title'>{data.titleCookie}</h1>
								<div onClick={() => closeModal()} className='closeModal'></div>
							</header>
							<section className='modal-container-body rtf'>
								<h2>{data.descrizione}</h2>

								<div className='row'>
									<div className='col-md-10'>
										<h4>
											Cookie Necessari <br /> (Obbligatori)
										</h4>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
										}}>
										<label className='switch pull-right'>
											{/* checked */}
											<input type='checkbox' id='necessari' />
											<span htmlFor='necessari' className='slider-disable round'></span>
										</label>
									</div>
								</div>
								<div className='row' id='cookie_analitici'>
									<div className='col-md-10'>
										<h4>Cookie analitici</h4>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
										}}>
										<label className='switch pull-right'>
											<input type='checkbox' id='analitici' />
											<span htmlFor='analitici' className='slider round'></span>
										</label>
									</div>
								</div>
								<div className='row' id='cookie_profilazione'>
									<div className='col-md-10'>
										<h4>Cookie profilazione</h4>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
										}}>
										<label className='switch pull-right'>
											<input type='checkbox' id='profilazione' />
											<span htmlFor='profilazione' className='slider round'></span>
										</label>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
									}}>
									<p className='pull-right nobottommargin link-ext'>
										<i className='fa fa-fw fa-external-link'></i>Scopri di più su{" "}
										<a href={data.linkInfo} target='_blank' rel='noopener noreferrer'>
											<span className='link-policy'>Privacy Policy</span>
										</a>
									</p>
								</div>
							</section>
							<footer className='modal-container-footer'>
								<button
									className='button is-primary'
									onClick={() => confirmPreferences()}>
									Conferma
								</button>
							</footer>
						</article>
					</div>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
}
