import React, { Component } from 'react';
import Globals from "../globals/globals";
import { Redirect } from "react-router-dom";

import Loading from './../components/Loading.js';

export default class MaintenanceDescription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            accessToken: props.accessToken,
            isLogged: props.isLogged,
            language: props.language,
            list: [],
            listMessage: "",
        }
    }

    componentWillUnmount() {
        document.body.classList.remove( "no-background" );
        //document.body.classList.remove( "page-with-filter-bar" );
    }

    componentDidMount(){
        document.body.classList.add( "no-background" );
        //document.body.classList.add( "page-with-filter-bar" );

        document.title = Globals.titles.maintenanceDescription[this.state.language];

        window.scrollTo(0, 0);

        this.getList();
    }

    componentWillReceiveProps(props) {
        this.setState({
            isLogged: props.isLogged,
            language: props.language
        });
        document.title = Globals.titles.maintenanceDescription[props.language];
    }

    getList() {
        // Reset state
        this.setState({
            loading: true,
            list: [],
            listMessage: "",
        });

        // Fetch api for user's orders
        let accessToken = this.state.accessToken;

        let url = Globals.baseUrl + '?lang=' + this.state.language + '&a=getMaintenanceDescription&access_token=' + accessToken;

        fetch( url, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if ( responseJson.error === true ) {
                // Error during the call - handle message
                this.setState({
                    loading: false,
                    list: [],
                    listMessage: responseJson.message,
                });
            } else {
                // Call is ok, store ordersData in state
                this.setState({
                    loading: false,
                    list: responseJson.maintenanceDescription,
                    listMessage: "",
                });
            }
        })
        .catch((error) => {
            // Communication error - handle message
            this.setState({
                loading: false,
                list: [],
                listMessage: Globals.errors.serverCall[this.state.language],
            });
        });
    }

    render() {
        if ( this.state.isLogged !== true ) {
            return (
                <Redirect to={{ pathname: '/login' }}/>
            );
        }

        let loadingOnScreen;
        if ( this.state.loading === true ) {
            loadingOnScreen = <Loading/>;
        }

        let listMessage = this.state.listMessage;
        let render  = "";
        if ( listMessage !== "" ) {
            render = <div className="message-wrapper">
                {listMessage}
            </div>;
        } else {
            render = <div className="maintenance-description-list-wrapper">
                {this.state.list.map((item, key) => {
                    return <div key={key} className="maintenance-description-wrapper">
                        <div className="name">{item.title}</div>
                        <div className="field pre-line">{item.description}</div>
                    </div>;
                })}
            </div>;
        }

        return(
            <div className="maintenance-description-wrapper">
                {render}
                {loadingOnScreen}
            </div>
        );
    }

}