import React, { Component } from 'react';
import Globals from "../globals/globals";
import logo from './../assets/img/logo.png';
import { Link } from "react-router-dom";

import 'font-awesome/css/font-awesome.min.css';

class Menu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            language:   props.language,
            menuState:  0,
            isLogged:   props.isLogged,
            isAdmin:    props.isAdmin,
            isOperator: props.isOperator,
        }
    }

    toggleMenu() {
        this.setState({
            menuState: 1 - this.state.menuState
        });
    }

    componentWillReceiveProps(props) {
        this.setState({
            language:   props.language,
            isLogged:   props.isLogged,
            isAdmin:    props.isAdmin,
            isOperator: props.isOperator,
        });
    }

    handleClickWithMenuOpened( event ) {
        if ( event.target.closest( '.menu-sidebar' ) === null ) {
            this.toggleMenu();
        }
    }

    render() {
        var doLogout = this.props.doLogout;

        let adminMenuVoices;
        if ( this.state.isAdmin === true ) {
            adminMenuVoices = <div>
                <Link to="/utenti" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-users"></i><span>{Globals.menuVoices.users[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/gestione-prodotti" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-archive"></i><span>{Globals.menuVoices.adminProducts[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/gestione-prodotti-camp-safety" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-archive"></i><span>{Globals.menuVoices.adminProductsCampSafety[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/gestione-installazioni" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-cogs"></i><span>{Globals.menuVoices.adminInstallations[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/gestione-relazioni" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-list"></i><span>{Globals.menuVoices.adminReports[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/gestione-interventi" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-briefcase"></i><span>{Globals.menuVoices.adminOperations[this.state.language]}</span>
                    </div>
                </Link>
                {/* <Link to="/gestione-interventi-confermati" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-calendar-check-o"></i><span>{Globals.menuVoices.adminConfirmedOperations[this.state.language]}</span>
                    </div>
                </Link> */}
                <Link to="/gestione-norme-e-direttive" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-graduation-cap"></i><span>{Globals.menuVoices.adminStandardsAndDirectives[this.state.language]}</span>
                    </div>
                </Link>
                <Link to="/gestione-manutenzioni" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-wrench"></i><span>{Globals.menuVoices.adminMaintenanceDescription[this.state.language]}</span>
                    </div>
                </Link>
            </div>;
        }

        if (this.state.isAdmin === false && this.state.isOperator === false) {
            adminMenuVoices = <div>
                <Link to="/i-miei-prodotti" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-archive"></i><span>Prodotti</span>
                    </div>
                </Link>
                <Link to="/le-mie-relazioni" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-list"></i><span>Relazioni</span>
                    </div>
                </Link>
                <Link to="/i-miei-interventi" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-briefcase"></i><span>Storico interventi</span>
                    </div>
                </Link>
                <Link to="/norme-e-direttive" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-graduation-cap"></i><span>Norme e direttive</span>
                    </div>
                </Link>
                <Link to="/descrizione-manutenzioni" onClick={this.toggleMenu.bind(this)}>
                    <div className="page">
                        <i className="fa fa-wrench"></i><span>Manutenzioni</span>
                    </div>
                </Link>
            </div>;
        }

        return(
            <header>
                <div className="menu-bar">
                    <div className="top-bar">
                        <div className="icon open" onClick={this.toggleMenu.bind( this )}></div>
                        <div className="logo-wrapper">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        <div className="language-selection-wrapper"></div>
                    </div>
                    <div className={this.state.menuState === 1 ? "menu-body opened" : "menu-body closed"} onClick={(event) => this.handleClickWithMenuOpened(event)}>
                        <div className="menu-sidebar">
                            <div className="sidebar-content">
                                <div className="sidebar-top-bar">
                                    <div className="icon close" onClick={this.toggleMenu.bind( this, 1 )}></div>
                                    <div className="logo-wrapper">
                                        <img src={logo} alt="logo" />
                                    </div>
                                </div>
                                <div className="pages-links">
                                    <Link to="/" onClick={this.toggleMenu.bind(this)}>
                                        <div className="page">
                                            <i className="fa fa-home"></i><span>{Globals.menuVoices.home[this.state.language]}</span>
                                        </div>
                                    </Link>
                                    {adminMenuVoices}
                                    {this.state.isAdmin === true || this.state.isOperator === true
                                        ? <Link to="/sopralluoghi" onClick={this.toggleMenu.bind(this)}>
                                            <div className="page">
                                                <i className="fa fa-eye"></i><span>Sopralluoghi</span>
                                            </div>
                                        </Link>
                                        : <span></span>
                                    }
                                    {this.state.isAdmin === true || this.state.isOperator === true
                                        ? <Link to="/gestione-interventi-confermati" onClick={this.toggleMenu.bind(this)}>
                                            <div className="page">
                                                <i className="fa fa-calendar-check-o"></i><span>{Globals.menuVoices.adminConfirmedOperations[this.state.language]}</span>
                                            </div>
                                        </Link>
                                        : <span></span>
                                    }
                                    {this.state.isAdmin === true
                                        ? <Link to="/preventivi" onClick={this.toggleMenu.bind(this)}>
                                            <div className="page">
                                                <i className="fa fa-envelope-open-o"></i><span>Preventivi</span>
                                            </div>
                                        </Link>
                                        : <span></span>
                                    }
                                    {this.state.isAdmin === true || this.state.isOperator === true
                                        ? <Link to="/preventivi-confermati" onClick={this.toggleMenu.bind(this)}>
                                            <div className="page">
                                                <i className="fa fa-envelope-o"></i><span>Preventivi confermati</span>
                                            </div>
                                        </Link>
                                        : <span></span>
                                    }
                                    <Link to="/modifica-profilo" onClick={this.toggleMenu.bind(this)}>
                                        <div className="page">
                                            <i className="fa fa-user"></i><span>Modifica profilo</span>
                                        </div>
                                    </Link>
                                    <Link to="/modifica-password" onClick={this.toggleMenu.bind(this)}>
                                        <div className="page">
                                            <i className="fa fa-key"></i><span>Modifica password</span>
                                        </div>
                                    </Link>
                                    <div className="page" onClick={() => {if ( window.confirm( Globals.confirms.logout[this.state.language] ) ) {this.toggleMenu();doLogout();}}}>
                                        <i className="fa fa-sign-out"></i><span>{Globals.menuVoices.logout[this.state.language]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

export default Menu;