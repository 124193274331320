import React, { useState } from "react";
import Globals from "./../globals/globals.js";
import { Redirect } from "react-router-dom";

export default function PrivacyModal(props) {
  const [show, setShow] = useState(
    props.isPrivacyAccepted === false ? true : false
  );

  const [logged, setIsLogged] = useState(true);
  const [accepted, setAccepted] = useState(false);

const doLogout = () => {
    setShow(false);
    let accessToken = props.accessToken;
    let url =
      Globals.baseUrl +
      "?lang=it&a=logout&access_token=" +
      accessToken;

    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === true) {
          console.log(responseJson.message);
        }
        setIsLogged(false);

      })
      .catch((error) => {
        console.error(error);
      });
    localStorage.setItem("accessToken", "");
    setIsLogged(false);
  };


  const acceptCondition = () => {
    setShow(false);
    setAccepted(true);

    let accessToken = props.accessToken;


    let url =
      Globals.baseUrl +
      "?lang=it&a=acceptPrivacy&access_token=" +
      accessToken;

    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('chiamata eseguita');
      })
      .catch((error) => {
        console.error(error);
      });
  };




  if (logged === false) {
    window.location.reload();
  }

  if (accepted === true) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  if (show !== false) {
    return (
      <>
        <div className="modal">
          <div className="background_privacy"></div>
          <div className="body small">
            <div className="titleBarPrivacy">Attenzione</div>
            <div className="content">
              Dichiaro di aver preso visione
              <a
                href={
                  "https://www.officinagelso.com/privacy-policy-crmpro.html"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-policy"> dell’informativa privacy </span>
              </a>{" "}
              e presto il consenso al trattamento dei miei dati, per le finalità
              e nei limiti di cui all’informativa.
            </div>

            <div className="input-wrapper half-width small">
              <button type="button" style={{width : '15em', display: 'flex', justifyContent: 'center', margin: '0 auto', marginBottom: '5px', backgroundColor: '#000'}}  onClick={() => doLogout()}>
                <span>Annulla</span>
              </button>
              <button type="button" style={{width : '15em', display: 'flex', justifyContent: 'center', margin: '0 auto',  marginBottom: '5px',}} onClick={() => acceptCondition()}>
                <span>Confermo</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return false;
  }
}
